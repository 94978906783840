import { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firebaseStorage } from '../../../services/firebaseStorage';
import { Toast } from '../../common/Toast';
import { LearningVideo } from '../../../types/learning';
import { Dialog } from '@headlessui/react';
import { XMarkIcon, PlayIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { auth } from '../../../services/firebase';

export function VideoManagement() {
  const [uploading, setUploading] = useState(false);
  const [videos, setVideos] = useState<LearningVideo[]>([]);
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [editingVideo, setEditingVideo] = useState<LearningVideo | null>(null);
  const storage = getStorage();

  // Form states
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [title, setTitle] = useState('');
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);
  const [thumbnailPreview, setThumbnailPreview] = useState<string>('');
  const [expertName, setExpertName] = useState('');
  const [expertTitle, setExpertTitle] = useState('');

  useEffect(() => {
    const user = auth.currentUser;
    if (!user || user.email !== 'taiwojolaade@gmail.com') {
      setToast({ message: 'Admin access required', type: 'error' });
      return;
    }
  }, []);

  useEffect(() => {
    loadVideos();
  }, []);

  const loadVideos = async () => {
    try {
      const loadedVideos = await firebaseStorage.getVideos();
      setVideos(loadedVideos);
    } catch (error) {
      console.error('Error loading videos:', error);
      setToast({ message: 'Failed to load videos', type: 'error' });
    }
  };

  const handleVideoSelect = (file: File) => {
    setVideoFile(file);
    setTitle(file.name.replace(/\.[^/.]+$/, "")); // Set default title as filename without extension
  };

  const handleThumbnailSelect = (file: File) => {
    setThumbnailFile(file);
    // Create preview URL
    const reader = new FileReader();
    reader.onloadend = () => {
      setThumbnailPreview(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleEdit = (video: LearningVideo) => {
    setEditingVideo(video);
    setTitle(video.title);
    setThumbnailPreview(video.thumbnailUrl);
    setExpertName(video.expert.name);
    setExpertTitle(video.expert.title);
    setShowForm(true);
  };

  const resetForm = () => {
    setVideoFile(null);
    setTitle('');
    setThumbnailFile(null);
    setThumbnailPreview('');
    setExpertName('');
    setExpertTitle('');
    setShowForm(false);
    setEditingVideo(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!videoFile && !editingVideo) return;

    setUploading(true);
    try {
      let videoUrl = editingVideo?.videoUrl || '';
      let thumbnailUrl = editingVideo?.thumbnailUrl || '/default-thumbnail.jpg';

      // Only upload new thumbnail if one was selected
      if (thumbnailFile) {
        const thumbnailPath = `learning/thumbnails/${Date.now()}_${thumbnailFile.name.replace(/[^a-zA-Z0-9.-]/g, '_')}`;
        const thumbnailRef = ref(storage, thumbnailPath);
        await uploadBytes(thumbnailRef, thumbnailFile);
        thumbnailUrl = await getDownloadURL(thumbnailRef);
      }

      // Only upload new video if one was selected
      if (videoFile) {
        const videoPath = `learning/videos/${Date.now()}_${videoFile.name.replace(/[^a-zA-Z0-9.-]/g, '_')}`;
        const videoRef = ref(storage, videoPath);
        await uploadBytes(videoRef, videoFile);
        videoUrl = await getDownloadURL(videoRef);
      }

      const videoData = {
        title,
        videoUrl,
        thumbnailUrl,
        description: '',
        date: Date.now(),
        creator: {
          name: expertName,
          profession: expertTitle,
          avatar: '/default-avatar.png'
        },
        expert: {
          name: expertName,
          title: expertTitle,
          avatarUrl: '/default-avatar.png'
        },
        duration: 0,
        isActive: true,
        uploadedAt: Date.now(),
        isDaily: true
      };

      if (editingVideo) {
        // Update existing video
        await firebaseStorage.updateVideo({
          ...editingVideo,
          ...videoData
        });
        setToast({ message: 'Video updated successfully!', type: 'success' });
      } else {
        // Create new video
        await firebaseStorage.addVideo(videoData);
        setToast({ message: 'Video uploaded successfully!', type: 'success' });
      }

      resetForm();
      await loadVideos();
    } catch (error) {
      console.error('Upload error:', error);
      if (error instanceof Error) {
        setToast({ message: `Failed to save video: ${error.message}`, type: 'error' });
      } else {
        setToast({ message: 'Failed to save video', type: 'error' });
      }
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async (video: LearningVideo) => {
    if (window.confirm('Are you sure you want to delete this video?')) {
      try {
        await firebaseStorage.deleteVideo(video.id);
        setToast({ message: 'Video deleted successfully', type: 'success' });
        await loadVideos();
      } catch (error) {
        console.error('Error deleting video:', error);
        setToast({ message: 'Failed to delete video', type: 'error' });
      }
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-white">Video Management</h2>
        <button
          onClick={() => setShowForm(true)}
          className="bg-accent-lavender/20 text-accent-lavender px-4 py-2 rounded-lg
                   hover:bg-accent-lavender/30 transition-colors"
        >
          Upload New Video
        </button>
      </div>

      {/* Video List */}
      <div className="grid gap-4">
        {videos.map(video => (
          <div key={video.id} className="bg-dark-card p-4 rounded-lg border border-gray-800">
            <div className="flex items-start space-x-4">
              <img
                src={video.thumbnailUrl}
                alt={video.title}
                className="w-24 h-24 object-cover rounded-lg"
              />
              <div className="flex-1">
                <h3 className="text-white font-medium">{video.title}</h3>
                <p className="text-xs text-gray-400 mt-1">
                  {new Date(video.date).toLocaleDateString()}
                </p>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleEdit(video)}
                  className="p-2 text-gray-400 hover:text-accent-mint rounded-lg hover:bg-accent-mint/10"
                >
                  <PencilIcon className="h-5 w-5" />
                </button>
                <button
                  onClick={() => handleDelete(video)}
                  className="p-2 text-gray-400 hover:text-accent-rose rounded-lg hover:bg-accent-rose/10"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Upload/Edit Form Dialog */}
      <Dialog
        open={showForm}
        onClose={() => !uploading && resetForm()}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="bg-dark-card rounded-xl p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <Dialog.Title className="text-lg font-medium text-white">
                {editingVideo ? 'Edit Video' : 'Upload New Video'}
              </Dialog.Title>
              <button
                onClick={() => !uploading && resetForm()}
                className="text-gray-400 hover:text-white"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              {!editingVideo && (
                <div>
                  <label className="block text-sm text-gray-400 mb-1">Video File</label>
                  <input
                    type="file"
                    accept="video/*"
                    onChange={(e) => e.target.files?.[0] && setVideoFile(e.target.files[0])}
                    className="w-full text-sm text-gray-400"
                    required={!editingVideo}
                  />
                </div>
              )}

              <div>
                <label className="block text-sm text-gray-400 mb-1">Video Title</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full p-2 bg-dark-bg text-white border border-gray-800 rounded-lg"
                  required
                />
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-1">Expert Name</label>
                <input
                  type="text"
                  value={expertName}
                  onChange={(e) => setExpertName(e.target.value)}
                  placeholder="Name of person in video"
                  className="w-full p-2 bg-dark-bg text-white border border-gray-800 rounded-lg"
                  required
                />
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-1">Expert Title</label>
                <input
                  type="text"
                  value={expertTitle}
                  onChange={(e) => setExpertTitle(e.target.value)}
                  placeholder="Title/Profession"
                  className="w-full p-2 bg-dark-bg text-white border border-gray-800 rounded-lg"
                  required
                />
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-1">Thumbnail</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => e.target.files?.[0] && handleThumbnailSelect(e.target.files[0])}
                  className="w-full text-sm text-gray-400"
                />
                {thumbnailPreview && (
                  <img
                    src={thumbnailPreview}
                    alt="Thumbnail preview"
                    className="mt-2 h-32 w-full object-cover rounded-lg"
                  />
                )}
              </div>

              <button
                type="submit"
                disabled={uploading || (!videoFile && !editingVideo)}
                className="w-full bg-accent-lavender/20 text-accent-lavender p-3 rounded-lg
                         border border-accent-lavender/30 hover:bg-accent-lavender/30
                         transition-colors disabled:opacity-50"
              >
                {uploading ? 'Saving...' : editingVideo ? 'Update Video' : 'Upload Video'}
              </button>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>

      <Toast 
        message={toast?.message || ''} 
        type={toast?.type || 'success'} 
        isVisible={!!toast} 
      />
    </div>
  );
} 