import React, { useState, useEffect, useCallback } from 'react';
import { PlusIcon, ClockIcon, MoonIcon, HeartIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { TrackingForm } from './tracking/TrackingForm';
import { storage } from '../services/storage';
import { TrackingEntry } from '../types/tracking';
import { Timer } from './tracking/Timer';
import { TrackingStats } from './tracking/TrackingStats';
import { TabTransition } from './wellness/TabTransition';
import { Toast } from './common/Toast';
import { haptics } from '../utils/haptics';
import { EntriesSection } from './tracking/EntriesSection';
import { TabButton } from './common/TabButton/TabButton';
import { useAuth } from '../contexts/AuthContext';

type TrackingType = 'feeding' | 'sleep' | 'diaper';

function BabyTracking() {
  const { currentUser } = useAuth();
  const [activeTab, setActiveTab] = useState<TrackingType>('feeding');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [entries, setEntries] = useState<TrackingEntry[]>([]);
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
  const [timerDuration, setTimerDuration] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedEntry, setSelectedEntry] = useState<TrackingEntry | null>(null);

  const loadEntries = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await storage.getEntries(activeTab);
      setEntries(data);
    } catch (error) {
      console.error('Error loading entries:', error);
      if ((error as Error).message === 'User not authenticated') {
        setToast({ message: 'Please sign in to view entries', type: 'error' });
      } else {
        setToast({ message: 'Failed to load entries', type: 'error' });
      }
    } finally {
      setIsLoading(false);
    }
  }, [activeTab]);

  useEffect(() => {
    loadEntries();
  }, [loadEntries]);

  const handleAddEntry = () => {
    setIsFormOpen(true);
  };

  const handleEdit = (entry: TrackingEntry) => {
    setSelectedEntry(entry);
    setTimerDuration(entry.duration || null);
    setIsFormOpen(true);
  };

  const handleFormClose = (success = false) => {
    setIsFormOpen(false);
    setSelectedEntry(null);
    if (success) {
      haptics.success();
      setToast({ message: 'Entry saved successfully! 🎉', type: 'success' });
      loadEntries();
      setTimeout(() => setToast(null), 3000);
    }
    setTimerDuration(null);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-pulse text-accent-lavender">Loading...</div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Header - Updated to show baby's name */}
      <div className="bg-dark-card rounded-lg p-6 border border-gray-800">
        <h2 className="text-2xl font-bold text-accent-lavender">
          {currentUser?.babyName 
            ? `${currentUser.babyName}'s Tracking`
            : "Baby Tracking"}
        </h2>
        <p className="text-gray-400 mt-2">
          {currentUser?.babyName 
            ? `Record ${currentUser.babyName}'s daily activities`
            : "Record your baby's daily activities"}
        </p>
        {currentUser?.babyAge && (
          <div className="mt-2 text-sm text-accent-mint">
            Age: {' '}
            {currentUser.babyAge.years ? `${currentUser.babyAge.years}y ` : ''}
            {currentUser.babyAge.months ? `${currentUser.babyAge.months}m ` : ''}
            {currentUser.babyAge.days ? `${currentUser.babyAge.days}d` : ''}
          </div>
        )}
      </div>

      {/* Quick Action Buttons */}
      <div className="grid grid-cols-3 gap-3">
        <TabButton
          icon={<HeartIcon className="h-6 w-6" />}
          label="Feeding"
          isActive={activeTab === 'feeding'}
          onClick={() => setActiveTab('feeding')}
          iconColor="text-accent-peach"
        />
        <TabButton
          icon={<MoonIcon className="h-6 w-6" />}
          label="Sleep"
          isActive={activeTab === 'sleep'}
          onClick={() => setActiveTab('sleep')}
          iconColor="text-accent-mint"
        />
        <TabButton
          icon={<ClockIcon className="h-6 w-6" />}
          label="Diaper"
          isActive={activeTab === 'diaper'}
          onClick={() => setActiveTab('diaper')}
          iconColor="text-accent-rose"
        />
      </div>

      {/* Content Section */}
      <TabTransition tab={activeTab}>
        <div className="space-y-4">
          {/* Timer */}
          {(activeTab === 'feeding' || activeTab === 'sleep') && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
            >
              <Timer 
                type={activeTab}
                onComplete={(duration) => {
                  setTimerDuration(duration);
                  setIsFormOpen(true);
                }} 
              />
            </motion.div>
          )}

          {/* Add New Entry Button */}
          <motion.button 
            onClick={handleAddEntry}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="w-full bg-accent-lavender/20 text-accent-lavender p-4 rounded-lg border border-accent-lavender/30 flex items-center justify-center space-x-2 hover:bg-accent-lavender/30 transition-colors"
          >
            <PlusIcon className="h-5 w-5" />
            <span>Add New Entry</span>
          </motion.button>

          {/* Stats Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <TrackingStats entries={entries} type={activeTab} />
          </motion.div>

          {/* Entries Section */}
          <EntriesSection
            entries={entries}
            activeTab={activeTab}
            onEdit={handleEdit}
            onDelete={async (entry: TrackingEntry) => {
              await storage.deleteEntry(entry.id);
              loadEntries();
              setToast({ message: 'Entry deleted successfully', type: 'success' });
              setTimeout(() => setToast(null), 3000);
            }}
            onEntriesUpdate={loadEntries}
          />
        </div>
      </TabTransition>

      {/* Tracking Form Dialog */}
      <TrackingForm 
        isOpen={isFormOpen}
        onClose={handleFormClose}
        type={activeTab}
        initialDuration={timerDuration || undefined}
        entry={selectedEntry || undefined}
        setToast={setToast}
      />
      <Toast 
        message={toast?.message || ''} 
        type={toast?.type || 'success'} 
        isVisible={!!toast} 
      />
    </div>
  );
}

export default BabyTracking;
