import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { TrackingEntry, DiaperType, TrackingType } from '../../types/tracking';
import { storage } from '../../services/storage';
import { auth } from '../../services/firebase';

// Import or define the TrackingEntryWithUser interface
interface TrackingEntryWithUser extends TrackingEntry {
  userId: string;
}

interface Props {
  isOpen: boolean;
  onClose: (success?: boolean) => void;
  type: TrackingType;
  initialDuration?: number;
  entry?: TrackingEntry;
  setToast: (toast: { message: string; type: 'success' | 'error' } | null) => void;
}

export function TrackingForm({ isOpen, onClose, type, initialDuration, entry, setToast }: Props) {
  const [duration, setDuration] = useState({
    hours: '',
    minutes: '',
    seconds: ''
  });
  const [notes, setNotes] = useState(entry?.notes || '');
  const [specificType, setSpecificType] = useState(
    entry?.feedingType || entry?.sleepType || ''
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [date, setDate] = useState(
    entry?.date ? new Date(entry.date).toISOString().split('T')[0] : 
    new Date().toISOString().split('T')[0]
  );
  const [time, setTime] = useState(
    entry?.time || new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
  );
  const [diaperType, setDiaperType] = useState<DiaperType>(
    entry?.diaperType || 'wet'
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (entry) {
        if (entry.duration) {
          const hours = Math.floor(entry.duration / 3600);
          const minutes = Math.floor((entry.duration % 3600) / 60);
          const seconds = entry.duration % 60;
          
          setDuration({
            hours: hours > 0 ? String(hours) : '',
            minutes: minutes > 0 ? String(minutes) : '',
            seconds: seconds > 0 ? String(seconds) : ''
          });
        }
        setNotes(entry.notes || '');
        setSpecificType(entry.feedingType || entry.sleepType || '');
        if (entry.diaperType) {
          setDiaperType(entry.diaperType);
        }
      } else if (initialDuration) {
        const hours = Math.floor(initialDuration / 3600);
        const minutes = Math.floor((initialDuration % 3600) / 60);
        const seconds = initialDuration % 60;
        
        setDuration({
          hours: hours > 0 ? String(hours) : '',
          minutes: minutes > 0 ? String(minutes) : '',
          seconds: seconds > 0 ? String(seconds) : ''
        });
      } else {
        setDuration({ hours: '', minutes: '', seconds: '' });
        setNotes('');
        setSpecificType('');
        setDiaperType('wet');
      }
    } else {
      setDuration({ hours: '', minutes: '', seconds: '' });
      setNotes('');
      setSpecificType('');
      setDiaperType('wet');
      setShowConfirm(false);
    }
  }, [entry, initialDuration, isOpen]);

  const handleClose = () => {
    if (initialDuration && !showConfirm && !entry) {
      setShowConfirm(true);
      return;
    }
    setShowConfirm(false);
    onClose(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const userId = auth.currentUser?.uid;
    if (!userId) {
      setToast({ message: 'Please sign in to save entries', type: 'error' });
      return;
    }
    
    if (type === 'diaper') {
      if (!date || !time) return;
  
      const timestamp = new Date(`${date}T${time}`).getTime();
      const entryData: Omit<TrackingEntryWithUser, 'id'> = {
        type: 'diaper' as TrackingType,
        timestamp,
        diaperType,
        notes: notes || '',
        date,
        time,
        userId: userId
      };
  
      try {
        await storage.addEntry(entryData);
        onClose(true);
      } catch (error) {
        console.error('Error saving entry:', error);
        setToast({ message: 'Failed to save entry', type: 'error' });
      }
      return;
    }
    
    const totalSeconds = 
      (parseInt(duration.hours || '0') * 3600) +
      (parseInt(duration.minutes || '0') * 60) +
      parseInt(duration.seconds || '0');

    const baseEntryData = {
      type,
      timestamp: Date.now(),
      duration: totalSeconds,
      notes: notes || '',
      userId: userId
    };

    let entryData: Omit<TrackingEntryWithUser, 'id'>;

    if (type === 'feeding') {
      entryData = {
        ...baseEntryData,
        type: 'feeding',
        feedingType: specificType as TrackingEntry['feedingType']
      };
    } else if (type === 'sleep') {
      entryData = {
        ...baseEntryData,
        type: 'sleep',
        sleepType: specificType as TrackingEntry['sleepType']
      };
    } else {
      entryData = baseEntryData as Omit<TrackingEntryWithUser, 'id'>;
    }

    try {
      setIsSubmitting(true);
      await storage.addEntry(entryData);
      onClose(true);
    } catch (error) {
      console.error('Error saving entry:', error);
      if ((error as Error).message === 'User not authenticated') {
        setToast({ message: 'Please sign in to save entries', type: 'error' });
      } else {
        setToast({ message: 'Failed to save entry', type: 'error' });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const formatDisplayDuration = () => {
    const h = parseInt(duration.hours) || 0;
    const m = parseInt(duration.minutes) || 0;
    const s = parseInt(duration.seconds) || 0;
    
    const parts = [];
    if (h > 0) parts.push(`${h}h`);
    if (m > 0) parts.push(`${m}m`);
    if (s > 0) parts.push(`${s}s`);
    
    return parts.join(' ') || '0s';
  };

  return (
    <Dialog open={isOpen} onClose={() => !isSubmitting && handleClose()}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-sm rounded-lg bg-dark-card p-6">
          {showConfirm ? (
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-accent-rose">Discard Timer?</h3>
              <p className="text-gray-400">The timer duration will be lost. Continue?</p>
              <div className="flex space-x-3">
                <button
                  onClick={() => onClose(false)}
                  className="flex-1 p-2 rounded bg-accent-rose/20 text-accent-rose"
                >
                  Discard
                </button>
                <button
                  onClick={() => setShowConfirm(false)}
                  className="flex-1 p-2 rounded bg-accent-mint/20 text-accent-mint"
                >
                  Keep Editing
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="flex justify-between items-center mb-4">
                <Dialog.Title className="text-lg font-medium text-accent-lavender">
                  Add {type.charAt(0).toUpperCase() + type.slice(1)} Entry
                </Dialog.Title>
                <button onClick={handleClose} className="text-gray-400">
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>

              <form onSubmit={handleSubmit} className="space-y-4">
                {type !== 'diaper' && (
                  <>
                    <div className="space-y-2">
                      <label className="text-sm text-gray-400">Duration</label>
                      <div className="grid grid-cols-3 gap-2">
                        {/* Duration inputs (hours, minutes, seconds) */}
                        <div className="space-y-1">
                          <input
                            type="number"
                            min="0"
                            value={duration.hours}
                            onChange={e => setDuration(prev => ({ ...prev, hours: e.target.value }))}
                            className="w-full bg-dark-bg text-dark-text p-2 rounded border border-gray-800"
                            placeholder="0"
                          />
                          <span className="text-xs text-gray-400 block text-center">Hours</span>
                        </div>
                        <div className="space-y-1">
                          <input
                            type="number"
                            min="0"
                            max="59"
                            value={duration.minutes}
                            onChange={e => setDuration(prev => ({ ...prev, minutes: e.target.value }))}
                            className="w-full bg-dark-bg text-dark-text p-2 rounded border border-gray-800"
                            placeholder="0"
                          />
                          <span className="text-xs text-gray-400 block text-center">Minutes</span>
                        </div>
                        <div className="space-y-1">
                          <input
                            type="number"
                            min="0"
                            max="59"
                            value={duration.seconds}
                            onChange={e => setDuration(prev => ({ ...prev, seconds: e.target.value }))}
                            className="w-full bg-dark-bg text-dark-text p-2 rounded border border-gray-800"
                            placeholder="0"
                          />
                          <span className="text-xs text-gray-400 block text-center">Seconds</span>
                        </div>
                      </div>
                    </div>
                    
                    <div className="text-sm text-accent-mint">
                      Duration: {formatDisplayDuration()}
                    </div>

                    {/* Type-specific fields */}
                    {type === 'feeding' && (
                      <select
                        value={specificType}
                        onChange={(e) => setSpecificType(e.target.value)}
                        className="w-full bg-dark-bg text-dark-text p-2 rounded border border-gray-800"
                        required
                      >
                        <option value="">Select Type</option>
                        <option value="left_breast">Left Breast</option>
                        <option value="right_breast">Right Breast</option>
                        <option value="bottle">Bottle</option>
                        <option value="solid">Solid Food</option>
                      </select>
                    )}

                    {/* Notes field for non-diaper entries */}
                    <textarea
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      placeholder="Notes"
                      className="w-full bg-dark-bg text-dark-text p-2 rounded border border-gray-800 h-24"
                    />
                  </>
                )}

                {type === 'diaper' && (
                  <div className="space-y-4 bg-dark-bg/50 p-4 rounded-lg border border-gray-800">
                    <div>
                      <label className="block text-sm text-gray-400 mb-2">Diaper Type</label>
                      <div className="grid grid-cols-3 gap-2">
                        <label className="flex items-center space-x-2 p-2 bg-dark-bg rounded border border-gray-800">
                          <input
                            type="radio"
                            value="wet"
                            checked={diaperType === 'wet'}
                            onChange={(e) => setDiaperType(e.target.value as DiaperType)}
                            className="text-accent-lavender"
                          />
                          <span className="text-sm text-gray-400">Wet</span>
                        </label>
                        <label className="flex items-center space-x-2 p-2 bg-dark-bg rounded border border-gray-800">
                          <input
                            type="radio"
                            value="dirty"
                            checked={diaperType === 'dirty'}
                            onChange={(e) => setDiaperType(e.target.value as DiaperType)}
                            className="text-accent-lavender"
                          />
                          <span className="text-sm text-gray-400">Dirty</span>
                        </label>
                        <label className="flex items-center space-x-2 p-2 bg-dark-bg rounded border border-gray-800">
                          <input
                            type="radio"
                            value="both"
                            checked={diaperType === 'both'}
                            onChange={(e) => setDiaperType(e.target.value as DiaperType)}
                            className="text-accent-lavender"
                          />
                          <span className="text-sm text-gray-400">Both</span>
                        </label>
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm text-gray-400 mb-1">Date</label>
                      <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="w-full bg-dark-bg text-white rounded-lg p-2 border border-gray-800"
                        max={new Date().toISOString().split('T')[0]}
                        required
                      />
                    </div>

                    <div>
                      <label className="block text-sm text-gray-400 mb-1">Time</label>
                      <input
                        type="time"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        className="w-full bg-dark-bg text-white rounded-lg p-2 border border-gray-800"
                        required
                      />
                    </div>
                  </div>
                )}

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full bg-accent-lavender/20 text-accent-lavender p-4 rounded-lg 
                           border border-accent-lavender/30 flex items-center justify-center 
                           space-x-2 hover:bg-accent-lavender/30 transition-colors
                           disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? 'Saving...' : 'Save Entry'}
                </button>
              </form>
            </>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
