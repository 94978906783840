import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { auth } from '../../services/firebase';

interface Props {
  children: React.ReactNode;
}

export default function AdminRoute({ children }: Props) {
  const { currentUser } = useAuth();
  
  useEffect(() => {
    // Force token refresh when admin route is accessed
    if (currentUser) {
      auth.currentUser?.getIdToken(true);
    }
  }, [currentUser]);

  console.log('Current user:', currentUser?.email);
  console.log('Is admin:', currentUser?.email === 'taiwojolaade@gmail.com');

  const isAdmin = currentUser?.email === 'taiwojolaade@gmail.com';

  if (!currentUser || !isAdmin) {
    console.log('Access denied - redirecting to home');
    return <Navigate to="/" />;
  }

  return <>{children}</>;
} 