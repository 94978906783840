import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authService } from '../../services/auth';
import { motion } from 'framer-motion';
import { HeartIcon, EyeIcon, EyeSlashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { standardizeAge } from '../../utils/ageConversion';

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [babyName, setBabyName] = useState('');
  const [babyAgeYears, setBabyAgeYears] = useState('');
  const [babyAgeMonths, setBabyAgeMonths] = useState('');
  const [babyAgeWeeks, setBabyAgeWeeks] = useState('');
  const [babyAgeDays, setBabyAgeDays] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const validatePassword = (password: string) => {
    const minLength = 6;
    return password.length >= minLength;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validatePassword(password)) {
      setError('Password must be at least 6 characters long');
      return;
    }

    const years = babyAgeYears ? parseInt(babyAgeYears) : undefined;
    const months = babyAgeMonths ? parseInt(babyAgeMonths) : undefined;
    const weeks = babyAgeWeeks ? parseInt(babyAgeWeeks) : undefined;
    const days = babyAgeDays ? parseInt(babyAgeDays) : undefined;

    if ((years !== undefined && isNaN(years)) || 
        (months !== undefined && isNaN(months)) || 
        (weeks !== undefined && isNaN(weeks)) ||
        (days !== undefined && isNaN(days))) {
      setError('Please enter valid numbers for baby\'s age');
      return;
    }

    const rawBabyAge = (years !== undefined || months !== undefined || weeks !== undefined || days !== undefined) 
      ? { years, months, weeks, days }
      : undefined;

    const babyAge = rawBabyAge ? standardizeAge(rawBabyAge) : undefined;

    try {
      setError('');
      setLoading(true);
      
      await authService.signUp(
        email, 
        password, 
        name, 
        babyName || undefined,
        babyAge
      );

      navigate('/app', { replace: true });
    } catch (err) {
      console.error('Signup error:', err);
      if ((err as any).code === 'auth/email-already-in-use') {
        setError('An account with this email already exists');
      } else {
        setError('Failed to create account');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-dark-bg to-dark-bg/95 p-4 sm:p-6">
      <div className="w-full max-w-md mx-auto relative">
        {/* Close Button */}
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute -top-4 right-0 p-2 text-gray-400 hover:text-white transition-colors"
          onClick={() => navigate('/')}
        >
          <XMarkIcon className="h-6 w-6" />
        </motion.button>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-6"
        >
          {/* Logo/Header - Made more compact on mobile */}
          <div className="text-center">
            <div className="flex justify-center mb-3">
              <div className="p-2 sm:p-3 bg-accent-lavender/20 rounded-full">
                <HeartIcon className="h-6 w-6 sm:h-8 sm:w-8 text-accent-lavender" />
              </div>
            </div>
            <h2 className="text-2xl sm:text-3xl font-bold text-white">Welcome to Nurturing Mom</h2>
            <p className="mt-1 sm:mt-2 text-sm sm:text-base text-gray-400">Create your account to start your journey</p>
          </div>

          {/* Sign Up Form - Adjusted padding for mobile */}
          <div className="bg-dark-card rounded-xl shadow-xl border border-gray-800 p-4 sm:p-8 space-y-4 sm:space-y-6">
            {error && (
              <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-3">
                <p className="text-red-500 text-sm text-center">{error}</p>
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-4">
              {/* Form fields with adjusted spacing */}
              <div className="space-y-1.5 sm:space-y-2">
                <label className="text-sm text-gray-400 block">Your Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your name"
                  required
                  className="w-full p-3 bg-dark-bg text-white border border-gray-800 rounded-lg focus:outline-none focus:border-accent-lavender/50 transition-colors"
                />
              </div>

              <div className="space-y-1.5 sm:space-y-2">
                <label className="text-sm text-gray-400 block">Baby's Name (Optional)</label>
                <input
                  type="text"
                  value={babyName}
                  onChange={(e) => setBabyName(e.target.value)}
                  placeholder="Enter your baby's name"
                  className="w-full p-3 bg-dark-bg text-white border border-gray-800 rounded-lg focus:outline-none focus:border-accent-lavender/50 transition-colors"
                />
              </div>

              <div className="space-y-1.5 sm:space-y-2">
                <label className="text-sm text-gray-400 block">Baby's Age (Optional)</label>
                <div className="grid grid-cols-4 gap-1.5 sm:gap-2">
                  <div className="space-y-0.5 sm:space-y-1">
                    <input
                      type="number"
                      min="0"
                      value={babyAgeYears}
                      onChange={(e) => setBabyAgeYears(e.target.value)}
                      placeholder="Years"
                      className="w-full p-2 sm:p-3 text-sm bg-dark-bg text-white border border-gray-800 rounded-lg focus:outline-none focus:border-accent-lavender/50 transition-colors"
                    />
                    <span className="text-xs text-gray-400">Years</span>
                  </div>
                  <div className="space-y-0.5 sm:space-y-1">
                    <input
                      type="number"
                      min="0"
                      value={babyAgeMonths}
                      onChange={(e) => setBabyAgeMonths(e.target.value)}
                      placeholder="Months"
                      className="w-full p-2 sm:p-3 text-sm bg-dark-bg text-white border border-gray-800 rounded-lg focus:outline-none focus:border-accent-lavender/50 transition-colors"
                    />
                    <span className="text-xs text-gray-400">Months</span>
                  </div>
                  <div className="space-y-0.5 sm:space-y-1">
                    <input
                      type="number"
                      min="0"
                      value={babyAgeWeeks}
                      onChange={(e) => setBabyAgeWeeks(e.target.value)}
                      placeholder="Weeks"
                      className="w-full p-2 sm:p-3 text-sm bg-dark-bg text-white border border-gray-800 rounded-lg focus:outline-none focus:border-accent-lavender/50 transition-colors"
                    />
                    <span className="text-xs text-gray-400">Weeks</span>
                  </div>
                  <div className="space-y-0.5 sm:space-y-1">
                    <input
                      type="number"
                      min="0"
                      value={babyAgeDays}
                      onChange={(e) => setBabyAgeDays(e.target.value)}
                      placeholder="Days"
                      className="w-full p-2 sm:p-3 text-sm bg-dark-bg text-white border border-gray-800 rounded-lg focus:outline-none focus:border-accent-lavender/50 transition-colors"
                    />
                    <span className="text-xs text-gray-400">Days</span>
                  </div>
                </div>
                <p className="text-xs text-gray-400 mt-1">
                  Enter any combination of years, months, weeks, and days
                </p>
              </div>

              <div className="space-y-1.5 sm:space-y-2">
                <label className="text-sm text-gray-400 block">Email Address</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                  className="w-full p-3 bg-dark-bg text-white border border-gray-800 rounded-lg focus:outline-none focus:border-accent-lavender/50 transition-colors"
                />
              </div>

              <div className="space-y-1.5 sm:space-y-2">
                <label className="text-sm text-gray-400 block">Password</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Create a password"
                    required
                    className="w-full p-3 bg-dark-bg text-white border border-gray-800 rounded-lg focus:outline-none focus:border-accent-lavender/50 transition-colors pr-10"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300"
                  >
                    {showPassword ? (
                      <EyeSlashIcon className="h-5 w-5" />
                    ) : (
                      <EyeIcon className="h-5 w-5" />
                    )}
                  </button>
                </div>
              </div>

              <button
                type="submit"
                disabled={loading}
                className="w-full bg-gradient-to-r from-accent-lavender to-accent-rose p-3 sm:p-4 rounded-lg text-white font-medium text-sm sm:text-base
                         hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed mt-2"
              >
                {loading ? (
                  <div className="flex items-center justify-center space-x-2">
                    <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                    <span>Creating Account...</span>
                  </div>
                ) : (
                  'Create Account'
                )}
              </button>
            </form>

            <div className="text-center pt-2">
              <Link 
                to="/login" 
                className="text-accent-lavender hover:text-accent-lavender/80 text-sm transition-colors"
              >
                Already have an account? Sign in
              </Link>
            </div>
          </div>

          {/* Footer - Made more compact on mobile */}
          <p className="text-center text-gray-500 text-xs sm:text-sm px-4">
            By signing up, you agree to our Terms of Service and Privacy Policy
          </p>
        </motion.div>
      </div>
    </div>
  );
}

export default SignUp;