import { 
  collection, 
  query, 
  where, 
  orderBy, 
  getDocs,
  limit 
} from 'firebase/firestore';
import { db } from './firebase';
import { LearningVideo, Article } from '../types/learning';

class LearningService {
  async getLatestVideo(): Promise<LearningVideo | null> {
    try {
      const videosRef = collection(db, 'videos');
      const q = query(
        videosRef,
        where('isActive', '==', true),
        orderBy('date', 'desc'),
        limit(1)
      );

      const snapshot = await getDocs(q);
      if (snapshot.empty) return null;

      const doc = snapshot.docs[0];
      return { id: doc.id, ...doc.data() } as LearningVideo;
    } catch (error) {
      console.error('Error getting latest video:', error);
      throw error;
    }
  }

  async getActiveArticles(maxArticles = 3): Promise<Article[]> {
    try {
      const articlesRef = collection(db, 'articles');
      const q = query(
        articlesRef,
        where('isActive', '==', true),
        orderBy('date', 'desc'),
        limit(maxArticles)
      );

      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Article[];
    } catch (error) {
      console.error('Error getting articles:', error);
      throw error;
    }
  }
}

export const learningService = new LearningService();