import React from 'react';
import { motion } from 'framer-motion';

interface Props {
  children: React.ReactNode;
}

export function PhoneMockup({ children }: Props) {
  return (
    <div className="flex justify-center items-center w-full">
      <div className="relative w-[280px] h-[570px]">
        {/* iPhone Frame - Removed white border, kept dark theme */}
        <motion.div 
          className="absolute inset-0 bg-[#1A1A1A] rounded-[50px] shadow-2xl border-[14px] border-[#1A1A1A]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          {/* Screen Content */}
          <div className="absolute inset-0 bg-black overflow-hidden rounded-[38px]">
            {/* Dynamic Island */}
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-[120px] h-[35px] bg-black rounded-b-[20px] z-20" />

            {/* Status Bar */}
            <div className="h-12 flex items-center justify-between px-6">
              <div className="text-xs text-white font-medium">9:41</div>
              <div className="flex items-center space-x-2">
                <div className="w-4 h-4 rounded-full bg-[#34C759]/20 ring-1 ring-[#34C759]/30" />
                <div className="w-4 h-4 rounded-full bg-[#FF3B30]/20 ring-1 ring-[#FF3B30]/30" />
              </div>
            </div>

            {/* Main Content Area */}
            <div className="px-3 h-[calc(100%-3rem)] overflow-hidden">
              {children}
            </div>
          </div>

          {/* Side Buttons */}
          <div className="absolute top-24 -left-[14px] w-[4px] h-8 bg-[#2A2A2A] rounded-l-sm" />
          <div className="absolute top-36 -left-[14px] w-[4px] h-16 bg-[#2A2A2A] rounded-l-sm" />
          <div className="absolute top-56 -left-[14px] w-[4px] h-16 bg-[#2A2A2A] rounded-l-sm" />
          <div className="absolute top-32 -right-[14px] w-[4px] h-24 bg-[#2A2A2A] rounded-r-sm" />
        </motion.div>

        {/* Screen Reflections */}
        <motion.div 
          className="absolute inset-[14px] rounded-[38px] bg-gradient-to-tr from-white/5 to-transparent pointer-events-none"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        />

        {/* Bottom Shadow */}
        <div className="absolute -bottom-6 left-1/2 transform -translate-x-1/2 w-[85%] h-6 bg-black/20 blur-xl rounded-full" />
      </div>
    </div>
  );
} 