import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { storage } from '../../services/storage';
import { HeartIcon, MoonIcon, ClockIcon } from '@heroicons/react/24/outline';

export function DailySummary() {
  const [todayStats, setTodayStats] = useState({
    feedings: 0,
    diapers: 0,
    sleep: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadTodayStats() {
      try {
        const startOfDay = new Date();
        startOfDay.setHours(0, 0, 0, 0);

        // Load all types of entries
        const [feedings, diapers, sleeps] = await Promise.all([
          storage.getEntries('feeding'),
          storage.getEntries('diaper'),
          storage.getEntries('sleep')
        ]);

        // Filter for today's entries
        const todayFeedings = feedings.filter(entry => 
          entry.timestamp >= startOfDay.getTime()
        ).length;

        const todayDiapers = diapers.filter(entry => 
          entry.timestamp >= startOfDay.getTime()
        ).length;

        const todaySleep = sleeps.filter(entry => 
          entry.timestamp >= startOfDay.getTime()
        ).length;

        setTodayStats({
          feedings: todayFeedings,
          diapers: todayDiapers,
          sleep: todaySleep
        });
      } catch (error) {
        console.error('Error loading daily stats:', error);
      } finally {
        setLoading(false);
      }
    }

    loadTodayStats();
  }, []);

  if (loading) {
    return (
      <div className="animate-pulse bg-dark-card rounded-lg p-4 border border-gray-800">
        <div className="h-20 bg-gray-800/50 rounded" />
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-dark-card rounded-lg p-4 border border-gray-800"
    >
      <h3 className="text-lg font-medium text-white mb-3">Today's Summary</h3>
      <div className="grid grid-cols-3 gap-3">
        <div className="flex items-center space-x-2">
          <div className="p-2 bg-accent-peach/10 rounded-lg">
            <HeartIcon className="h-5 w-5 text-accent-peach" />
          </div>
          <div>
            <p className="text-sm text-gray-400">Feedings</p>
            <p className="font-medium text-white">{todayStats.feedings}</p>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <div className="p-2 bg-accent-rose/10 rounded-lg">
            <ClockIcon className="h-5 w-5 text-accent-rose" />
          </div>
          <div>
            <p className="text-sm text-gray-400">Diapers</p>
            <p className="font-medium text-white">{todayStats.diapers}</p>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <div className="p-2 bg-accent-mint/10 rounded-lg">
            <MoonIcon className="h-5 w-5 text-accent-mint" />
          </div>
          <div>
            <p className="text-sm text-gray-400">Sleep</p>
            <p className="font-medium text-white">{todayStats.sleep}</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
} 