import React from 'react';
import { WellnessEntry } from '../../types/wellness';

interface WellnessStatsProps {
  entries: WellnessEntry[];
  type: WellnessEntry['type'];
}

export function WellnessStats({ entries, type }: WellnessStatsProps) {
  const today = new Date().setHours(0, 0, 0, 0);
  const weekAgo = today - 7 * 24 * 60 * 60 * 1000;
  const weeklyEntries = entries.filter(entry => entry.timestamp >= weekAgo);

  const getAverageRating = () => {
    if (!weeklyEntries.length) return 0;
    return Math.round(
      (weeklyEntries.reduce((acc, curr) => acc + curr.rating, 0) / weeklyEntries.length) * 10
    ) / 10;
  };

  return (
    <div className="bg-gradient-to-br from-dark-card/95 via-dark-card/90 to-dark-card/80 rounded-xl p-6 border border-accent-lavender/20">
      <h4 className="text-accent-lavender font-medium mb-4 flex items-center gap-2">
        <span className="text-xl">📊</span>
        Your Wellness Journey
      </h4>
      
      {/* Weekly visualization with softer, more nurturing design */}
      <div className="mt-6 space-y-3">
        {Array.from({ length: 7 }).map((_, i) => {
          const date = new Date(today - i * 24 * 60 * 60 * 1000);
          const dayEntries = weeklyEntries.filter(
            entry => new Date(entry.timestamp).toDateString() === date.toDateString()
          );
          const avgRating = dayEntries.length
            ? Math.round(dayEntries.reduce((acc, curr) => acc + curr.rating, 0) / dayEntries.length)
            : 0;

          return (
            <div key={i} className="flex items-center space-x-3">
              <span className="text-sm text-gray-400 w-10">
                {date.toLocaleDateString('en-US', { weekday: 'short' })}
              </span>
              <div className="flex-1 bg-dark-bg rounded-full h-8 p-1 overflow-hidden border border-gray-800/30">
                <div
                  className={`h-full rounded-full transition-all duration-500
                    ${avgRating >= 4 ? 'bg-gradient-to-r from-accent-mint/60 to-accent-mint' : ''}
                    ${avgRating === 3 ? 'bg-gradient-to-r from-accent-lavender/60 to-accent-lavender' : ''}
                    ${avgRating <= 2 && avgRating > 0 ? 'bg-gradient-to-r from-accent-peach/60 to-accent-peach' : ''}
                    ${avgRating === 0 ? 'bg-gray-800/20' : ''}`}
                  style={{ width: `${avgRating * 20}%` }}
                >
                  {avgRating > 0 && (
                    <div className="h-full flex items-center justify-end pr-2">
                      <span className="text-xs text-white">
                        {['😔', '😐', '🙂', '😊', '🥰'][avgRating - 1]}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Stats cards with more nurturing design */}
      <div className="mt-6 grid grid-cols-2 gap-4">
        <div className="bg-dark-bg/50 rounded-xl p-4 border border-accent-lavender/20 backdrop-blur-sm">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-accent-lavender/10 rounded-lg">
              <span className="text-2xl">💫</span>
            </div>
            <div>
              <p className="text-sm text-gray-400">Average Rating</p>
              <p className="text-xl font-medium text-accent-lavender">{getAverageRating()}</p>
            </div>
          </div>
        </div>
        <div className="bg-dark-bg/50 rounded-xl p-4 border border-accent-mint/20 backdrop-blur-sm">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-accent-mint/10 rounded-lg">
              <span className="text-2xl">🌸</span>
            </div>
            <div>
              <p className="text-sm text-gray-400">Check-ins</p>
              <p className="text-xl font-medium text-accent-mint">{weeklyEntries.length}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Encouraging message */}
      <div className="mt-6 p-4 bg-accent-lavender/5 rounded-xl border border-accent-lavender/10">
        <p className="text-sm text-center text-gray-300">
          {getAverageRating() >= 4 ? "You're thriving! Keep embracing these beautiful moments 💝" :
           getAverageRating() >= 3 ? "You're doing great! Every step forward matters 💫" :
           "Remember to be gentle with yourself. You're doing amazing 🌸"}
        </p>
      </div>
    </div>
  );
}
