import React from 'react';
import { motion } from 'framer-motion';
import { MILESTONES, MilestoneType } from '../../types/victory';

interface Props {
  type: MilestoneType;
  currentValue: number;
  nextMilestone: {
    threshold: number;
    title: string;
    icon: string;
    category: string;
    hint: string;
  };
}

export const MilestoneProgress = ({ type, currentValue, nextMilestone }: Props) => {
  const progress = (currentValue / nextMilestone.threshold) * 100;
  const remaining = nextMilestone.threshold - currentValue;

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-dark-bg/30 rounded-lg p-4 hover:bg-dark-bg/40 transition-colors"
    >
      <div className="space-y-2">
        {/* Title and Progress */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="text-xl">{nextMilestone.icon}</span>
            <div>
              <h4 className="text-sm font-medium text-white">{nextMilestone.title}</h4>
              <p className="text-xs text-gray-400">{nextMilestone.hint}</p>
            </div>
          </div>
          <div className="text-right">
            <p className="text-sm text-accent-gold">
              {currentValue}/{nextMilestone.threshold}
            </p>
            <p className="text-xs text-gray-400">
              {remaining} to go!
            </p>
          </div>
        </div>

        {/* Progress Bar */}
        <div className="relative h-2 bg-dark-bg rounded-full overflow-hidden">
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 1, ease: "easeOut" }}
            className={`absolute h-full rounded-full bg-gradient-to-r 
              ${type === 'feeding' ? 'from-accent-rose/50 to-accent-rose' : ''}
              ${type === 'sleep' ? 'from-accent-lavender/50 to-accent-lavender' : ''}
              ${type === 'diaper' ? 'from-accent-mint/50 to-accent-mint' : ''}
              ${type === 'streak' ? 'from-accent-gold/50 to-accent-gold' : ''}
              ${type === 'nighttime' ? 'from-accent-peach/50 to-accent-peach' : ''}
              ${type === 'consistency' ? 'from-accent-rose/50 to-accent-mint' : ''}
              ${type === 'supermom' ? 'from-accent-gold/50 to-accent-lavender' : ''}
              ${type === 'dedication' ? 'from-accent-rose/50 to-accent-gold' : ''}`}
          />
        </div>
      </div>
    </motion.div>
  );
}; 