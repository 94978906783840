import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { WellnessEntry } from '../../types/wellness';
import { ChevronDownIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { storage } from '../../services/storage';

interface Props {
  entries: WellnessEntry[];
  type: WellnessEntry['type'];
  setEntries: React.Dispatch<React.SetStateAction<WellnessEntry[]>>;
  setToast?: (toast: { message: string; type: 'success' | 'error' } | null) => void;
}

export function WellnessJournal({ entries, type, setEntries, setToast }: Props) {
  const [showAll, setShowAll] = useState(false);
  const [editingEntry, setEditingEntry] = useState<WellnessEntry | null>(null);
  const [editedNotes, setEditedNotes] = useState('');

  const sortedEntries = [...entries].sort((a, b) => b.timestamp - a.timestamp);
  const displayedEntries = showAll ? sortedEntries : sortedEntries.slice(0, 3);

  const handleEdit = (entry: WellnessEntry) => {
    setEditingEntry(entry);
    setEditedNotes(entry.notes || '');
  };

  const handleSaveEdit = async () => {
    if (!editingEntry) return;
    
    try {
      await storage.updateWellnessEntry({
        ...editingEntry,
        notes: editedNotes
      });
      setToast?.({ message: 'Entry updated successfully', type: 'success' });
      setEditingEntry(null);
    } catch (error) {
      setToast?.({ message: 'Failed to update entry', type: 'error' });
    }
  };

  const handleDelete = async (entry: WellnessEntry) => {
    if (window.confirm('Are you sure you want to delete this entry?')) {
      try {
        await storage.deleteWellnessEntry(entry.id);
        setEntries(prevEntries => prevEntries.filter(e => e.id !== entry.id));
        setToast?.({ message: 'Entry deleted successfully', type: 'success' });
      } catch (error) {
        setToast?.({ message: 'Failed to delete entry', type: 'error' });
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-br from-dark-card/95 via-dark-card/90 to-dark-card/80 rounded-xl p-6 border border-accent-lavender/20"
    >
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-accent-lavender font-medium flex items-center gap-2">
          <span className="text-xl">📔</span>
          Your Journal Entries
        </h4>
        {entries.length > 3 && (
          <button
            onClick={() => setShowAll(!showAll)}
            className="flex items-center gap-2 text-sm text-accent-lavender hover:text-accent-lavender/80 transition-colors"
          >
            {showAll ? 'Show Recent' : 'View All'}
            <ChevronDownIcon className={`h-4 w-4 transform transition-transform ${showAll ? 'rotate-180' : ''}`} />
          </button>
        )}
      </div>

      <AnimatePresence>
        <div className="space-y-4">
          {displayedEntries.map((entry) => (
            <motion.div
              key={entry.timestamp}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="bg-dark-bg/30 rounded-lg p-4 border border-gray-800/30"
            >
              <div className="flex justify-between items-start mb-2">
                <div className="flex items-center gap-2">
                  <span className="text-2xl">
                    {type === 'mood' && ['😔', '😐', '🙂', '😊', '🥰'][entry.rating - 1]}
                    {type === 'sleep' && ['😴', '🥱', '😌', '😊', '✨'][entry.rating - 1]}
                    {type === 'energy' && ['🔋', '⚡️', '💫', '✨', '🌟'][entry.rating - 1]}
                  </span>
                  <div>
                    <p className="text-sm text-gray-400">
                      {new Date(entry.timestamp).toLocaleDateString('en-US', {
                        weekday: 'long',
                        month: 'short',
                        day: 'numeric'
                      })}
                    </p>
                    <p className="text-xs text-gray-500">
                      {new Date(entry.timestamp).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit'
                      })}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => handleEdit(entry)}
                    className="p-1 text-accent-lavender hover:bg-accent-lavender/10 rounded transition-colors"
                  >
                    <PencilIcon className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => handleDelete(entry)}
                    className="p-1 text-accent-rose hover:bg-accent-rose/10 rounded transition-colors"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>
              
              {editingEntry?.id === entry.id ? (
                <div className="mt-2">
                  <textarea
                    value={editedNotes}
                    onChange={(e) => setEditedNotes(e.target.value)}
                    className="w-full bg-dark-bg/50 rounded-lg p-2 text-sm text-gray-200 border border-accent-lavender/20"
                    rows={3}
                  />
                  <div className="flex justify-end gap-2 mt-2">
                    <button
                      onClick={() => setEditingEntry(null)}
                      className="px-3 py-1 text-sm text-gray-400 hover:text-gray-300"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSaveEdit}
                      className="px-3 py-1 text-sm bg-accent-lavender/20 text-accent-lavender rounded-lg hover:bg-accent-lavender/30"
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                entry.notes && (
                  <p className="text-sm text-gray-300 mt-2 whitespace-pre-line">
                    {entry.notes}
                  </p>
                )
              )}
            </motion.div>
          ))}

          {entries.length === 0 && (
            <p className="text-center text-gray-400 py-8">
              No entries yet. Start your wellness journey by checking in above! 🌸
            </p>
          )}
        </div>
      </AnimatePresence>
    </motion.div>
  );
} 