import { TrackingEntry } from '../types/tracking';
import { WellnessEntry } from '../types/wellness';
import { HealthType, HealthEntry } from '../types/health';
import { VictoryEntry } from '../types/victory';
import { firebaseStorage } from './firebaseStorage';
import { auth } from './firebase';

// Update the interfaces to include userId
interface TrackingEntryWithUser extends TrackingEntry {
  userId: string;
}

interface WellnessEntryWithUser extends WellnessEntry {
  userId: string;
}

interface HealthEntryWithUser extends HealthEntry {
  userId: string;
}

interface VictoryEntryWithUser extends VictoryEntry {
  userId: string;
}

class StorageService {
  private checkAuth() {
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error('User not authenticated');
    return userId;
  }

  // Baby Tracking Methods
  async getEntries(type: TrackingEntry['type']) {
    const userId = this.checkAuth();
    return firebaseStorage.getEntries(type, userId);
  }

  async addEntry(entry: Omit<TrackingEntryWithUser, 'id'>) {
    const userId = this.checkAuth();
    return firebaseStorage.addEntry({
      ...entry,
      userId,
      timestamp: Date.now()
    });
  }

  async deleteEntry(entryId: string) {
    this.checkAuth(); // Verify user is authenticated
    await firebaseStorage.deleteEntry(entryId);
  }

  // Wellness Methods
  async getWellnessEntries(type: WellnessEntry['type']) {
    const userId = this.checkAuth();
    return firebaseStorage.getWellnessEntries(type, userId);
  }

  async addWellnessEntry(entry: Omit<WellnessEntryWithUser, 'id'>) {
    const userId = this.checkAuth();
    return firebaseStorage.addWellnessEntry({
      ...entry,
      userId
    });
  }

  async updateWellnessEntry(entry: WellnessEntry) {
    this.checkAuth(); // Verify user is authenticated
    return firebaseStorage.updateWellnessEntry(entry);
  }

  async deleteWellnessEntry(entryId: string) {
    this.checkAuth(); // Verify user is authenticated
    return firebaseStorage.deleteWellnessEntry(entryId);
  }

  // Health Methods
  async getHealthEntries(type: HealthType) {
    const userId = this.checkAuth();
    return firebaseStorage.getHealthEntries(type, userId);
  }

  async addHealthEntry(entry: Omit<HealthEntryWithUser, 'id'>) {
    const userId = this.checkAuth();
    return firebaseStorage.addHealthEntry({
      ...entry,
      userId,
      timestamp: Date.now()
    });
  }

  async updateHealthEntry(entry: HealthEntry) {
    this.checkAuth(); // Verify user is authenticated
    return firebaseStorage.updateHealthEntry(entry);
  }

  async deleteHealthEntry(entry: HealthEntry) {
    const userId = this.checkAuth();
    return firebaseStorage.deleteHealthEntry(entry.id, userId);
  }

  // Victory Methods
  async getVictories() {
    const userId = this.checkAuth();
    return firebaseStorage.getVictories(userId);
  }

  async addVictory(entry: Omit<VictoryEntryWithUser, 'id'>) {
    const userId = this.checkAuth();
    return firebaseStorage.addVictory({
      ...entry,
      userId,
      timestamp: Date.now()
    });
  }

  async deleteVictory(victoryId: string) {
    const userId = this.checkAuth();
    return firebaseStorage.deleteVictory(victoryId, userId);
  }

  async shareVictory(victoryId: string) {
    const userId = this.checkAuth();
    return firebaseStorage.updateVictory(victoryId, { shared: true });
  }
}

export const storage = new StorageService();
