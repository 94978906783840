import React from 'react';
import { TrackingEntry } from '../../types/tracking';
import { motion } from 'framer-motion';

interface Props {
  entries: TrackingEntry[];
  type: 'feeding' | 'sleep' | 'diaper';
}

export function TrackingStats({ entries, type }: Props) {
  const today = new Date().setHours(0, 0, 0, 0);
  const todayEntries = entries.filter(entry => 
    new Date(entry.timestamp).setHours(0, 0, 0, 0) === today
  );

  const renderFeedingStats = () => {
    const totalToday = todayEntries.length;
    
    // Get feeding pattern for today
    const morningFeedings = todayEntries.filter(e => {
      const hour = new Date(e.timestamp).getHours();
      return hour >= 6 && hour < 12;
    }).length;

    const afternoonFeedings = todayEntries.filter(e => {
      const hour = new Date(e.timestamp).getHours();
      return hour >= 12 && hour < 18;
    }).length;

    const eveningFeedings = todayEntries.filter(e => {
      const hour = new Date(e.timestamp).getHours();
      return hour >= 18 || hour < 6;
    }).length;

    // Get last 7 days trend
    const last7Days = Array.from({ length: 7 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - i);
      date.setHours(0, 0, 0, 0);
      return date.getTime();
    });

    const weeklyTrend = last7Days.map(date => ({
      date,
      count: entries.filter(e => 
        new Date(e.timestamp).setHours(0, 0, 0, 0) === date
      ).length
    }));

    return (
      <div className="space-y-6">
        {/* Today's Overview */}
        <div>
          <h3 className="text-lg font-medium text-white">Today's Feedings</h3>
          <div className="mt-2 grid grid-cols-3 gap-4">
            <div className="bg-dark-bg p-3 rounded-lg text-center">
              <p className="text-sm text-gray-400">Morning</p>
              <p className="text-2xl font-bold text-accent-mint">{morningFeedings}</p>
              <p className="text-xs text-gray-500">6am - 12pm</p>
            </div>
            <div className="bg-dark-bg p-3 rounded-lg text-center">
              <p className="text-sm text-gray-400">Afternoon</p>
              <p className="text-2xl font-bold text-accent-peach">{afternoonFeedings}</p>
              <p className="text-xs text-gray-500">12pm - 6pm</p>
            </div>
            <div className="bg-dark-bg p-3 rounded-lg text-center">
              <p className="text-sm text-gray-400">Evening</p>
              <p className="text-2xl font-bold text-accent-lavender">{eveningFeedings}</p>
              <p className="text-xs text-gray-500">6pm - 6am</p>
            </div>
          </div>
        </div>

        {/* Weekly Trend */}
        <div>
          <div className="flex items-center justify-between">
            <h4 className="text-sm font-medium text-gray-400">7-Day Trend</h4>
            <p className="text-xs text-gray-500">
              Avg: {Math.round(weeklyTrend.reduce((acc, day) => acc + day.count, 0) / 7)} per day
            </p>
          </div>
          <div className="mt-2 flex items-end justify-between h-24 bg-dark-bg rounded-lg p-2">
            {weeklyTrend.reverse().map((day, i) => (
              <div key={day.date} className="flex flex-col items-center flex-1">
                <div 
                  className="w-4 bg-accent-lavender/30 rounded-t"
                  style={{ 
                    height: `${(day.count / Math.max(...weeklyTrend.map(d => d.count))) * 100}%`,
                    minHeight: '4px'
                  }}
                />
                <p className="mt-1 text-xs text-gray-500">
                  {i === 6 ? 'Today' : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date(day.date).getDay()]}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Quick Stats */}
        {todayEntries.length > 0 && (
          <div className="flex justify-between text-sm text-gray-400">
            <p>
              Last feeding: {new Date(Math.max(...todayEntries.map(e => e.timestamp)))
                .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            </p>
            <p>Total today: {totalToday}</p>
          </div>
        )}
      </div>
    );
  };

  const renderSleepStats = () => {
    const totalSleepToday = todayEntries.reduce((acc, entry) => acc + (entry.duration || 0), 0);
    
    // Calculate sleep quality indicators
    const longNaps = todayEntries.filter(e => (e.duration || 0) > 90 * 60).length; // > 90 mins
    const shortNaps = todayEntries.filter(e => (e.duration || 0) <= 30 * 60).length; // <= 30 mins
    
    // Get last 7 days sleep trend
    const last7Days = Array.from({ length: 7 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - i);
      date.setHours(0, 0, 0, 0);
      return date.getTime();
    });

    const weeklyTrend = last7Days.map(date => {
      const dayEntries = entries.filter(e => 
        new Date(e.timestamp).setHours(0, 0, 0, 0) === date
      );
      return {
        date,
        totalHours: dayEntries.reduce((acc, entry) => acc + (entry.duration || 0), 0) / 3600
      };
    });

    return (
      <div className="space-y-6">
        {/* Today's Overview */}
        <div>
          <h3 className="text-lg font-medium text-white">Today's Sleep</h3>
          <div className="mt-2 bg-dark-bg p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-3xl font-bold text-accent-mint">
                  {Math.floor(totalSleepToday / 3600)}h {Math.round((totalSleepToday % 3600) / 60)}m
                </p>
                <p className="text-sm text-gray-400 mt-1">Total Sleep</p>
              </div>
              <div className="text-right">
                <p className="text-xl font-bold text-accent-lavender">{todayEntries.length}</p>
                <p className="text-sm text-gray-400">Naps</p>
              </div>
            </div>
          </div>
        </div>

        {/* Sleep Pattern */}
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-dark-bg p-3 rounded-lg text-center">
            <p className="text-2xl font-bold text-accent-peach">{longNaps}</p>
            <p className="text-sm text-gray-400">Long Naps</p>
            <p className="text-xs text-gray-500">&gt; 90 mins</p>
          </div>
          <div className="bg-dark-bg p-3 rounded-lg text-center">
            <p className="text-2xl font-bold text-accent-rose">{shortNaps}</p>
            <p className="text-sm text-gray-400">Short Naps</p>
            <p className="text-xs text-gray-500">&lt; 30 mins</p>
          </div>
        </div>

        {/* Weekly Trend */}
        <div>
          <div className="flex items-center justify-between">
            <h4 className="text-sm font-medium text-gray-400">7-Day Sleep Trend</h4>
            <p className="text-xs text-gray-500">
              Avg: {Math.round(weeklyTrend.reduce((acc, day) => acc + day.totalHours, 0) / 7)}h per day
            </p>
          </div>
          <div className="mt-2 flex items-end justify-between h-24 bg-dark-bg rounded-lg p-2">
            {weeklyTrend.reverse().map((day, i) => (
              <div key={day.date} className="flex flex-col items-center flex-1">
                <div 
                  className="w-4 bg-accent-mint/30 rounded-t"
                  style={{ 
                    height: `${(day.totalHours / Math.max(...weeklyTrend.map(d => d.totalHours))) * 100}%`,
                    minHeight: '4px'
                  }}
                />
                <p className="mt-1 text-xs text-gray-500">
                  {i === 6 ? 'Today' : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date(day.date).getDay()]}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderDiaperStats = () => {
    const todayTotal = todayEntries.length;
    
    // Get diaper pattern for today
    const morningChanges = todayEntries.filter(e => {
      const hour = new Date(e.timestamp).getHours();
      return hour >= 6 && hour < 12;
    }).length;

    const afternoonChanges = todayEntries.filter(e => {
      const hour = new Date(e.timestamp).getHours();
      return hour >= 12 && hour < 18;
    }).length;

    const eveningChanges = todayEntries.filter(e => {
      const hour = new Date(e.timestamp).getHours();
      return hour >= 18 || hour < 6;
    }).length;

    // Get last 7 days trend
    const last7Days = Array.from({ length: 7 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - i);
      date.setHours(0, 0, 0, 0);
      return date.getTime();
    });

    const weeklyTrend = last7Days.map(date => ({
      date,
      count: entries.filter(e => 
        new Date(e.timestamp).setHours(0, 0, 0, 0) === date
      ).length
    }));

    // Calculate average changes per day
    const weeklyAverage = Math.round(weeklyTrend.reduce((acc, day) => acc + day.count, 0) / 7);

    return (
      <div className="space-y-6">
        {/* Today's Overview */}
        <div>
          <h3 className="text-lg font-medium text-white">Today's Changes</h3>
          <div className="mt-2 grid grid-cols-3 gap-4">
            <div className="bg-dark-bg p-3 rounded-lg text-center">
              <p className="text-sm text-gray-400">Morning</p>
              <p className="text-2xl font-bold text-accent-mint">{morningChanges}</p>
              <p className="text-xs text-gray-500">6am - 12pm</p>
            </div>
            <div className="bg-dark-bg p-3 rounded-lg text-center">
              <p className="text-sm text-gray-400">Afternoon</p>
              <p className="text-2xl font-bold text-accent-peach">{afternoonChanges}</p>
              <p className="text-xs text-gray-500">12pm - 6pm</p>
            </div>
            <div className="bg-dark-bg p-3 rounded-lg text-center">
              <p className="text-sm text-gray-400">Evening</p>
              <p className="text-2xl font-bold text-accent-lavender">{eveningChanges}</p>
              <p className="text-xs text-gray-500">6pm - 6am</p>
            </div>
          </div>
        </div>

        {/* Daily Summary */}
        <div className="bg-dark-bg p-4 rounded-lg">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-3xl font-bold text-accent-rose">{todayTotal}</p>
              <p className="text-sm text-gray-400 mt-1">Total Today</p>
            </div>
            <div className="text-right">
              <p className="text-xl font-bold text-accent-mint">
                {todayTotal > 0 ? `${Math.round(todayTotal / 24 * 4)} per 4hrs` : '-'}
              </p>
              <p className="text-sm text-gray-400">Average Rate</p>
            </div>
          </div>
        </div>

        {/* Weekly Trend */}
        <div>
          <div className="flex items-center justify-between">
            <h4 className="text-sm font-medium text-gray-400">7-Day Trend</h4>
            <p className="text-xs text-gray-500">
              Weekly Avg: {weeklyAverage} per day
            </p>
          </div>
          <div className="mt-2 flex items-end justify-between h-24 bg-dark-bg rounded-lg p-2">
            {weeklyTrend.reverse().map((day, i) => (
              <div key={day.date} className="flex flex-col items-center flex-1">
                <div 
                  className="w-4 bg-accent-rose/30 rounded-t"
                  style={{ 
                    height: `${(day.count / Math.max(...weeklyTrend.map(d => d.count))) * 100}%`,
                    minHeight: '4px'
                  }}
                />
                <p className="mt-1 text-xs text-gray-500">
                  {i === 6 ? 'Today' : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date(day.date).getDay()]}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Last Change Info */}
        {todayEntries.length > 0 && (
          <div className="text-sm text-gray-400 text-center">
            Last change: {new Date(Math.max(...todayEntries.map(e => e.timestamp)))
              .toLocaleTimeString([], { 
                hour: '2-digit', 
                minute: '2-digit',
                hour12: true 
              })}
          </div>
        )}
      </div>
    );
  };

  return (
    <motion.div 
      className="bg-dark-card rounded-lg p-4 border border-gray-800"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      {type === 'feeding' && renderFeedingStats()}
      {type === 'sleep' && renderSleepStats()}
      {type === 'diaper' && renderDiaperStats()}
    </motion.div>
  );
}
