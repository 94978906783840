import { Link, Outlet, useLocation } from 'react-router-dom';
import { 
  ChartBarIcon,
  VideoCameraIcon,
  NewspaperIcon 
} from '@heroicons/react/24/outline';

export function AdminLayout() {
  const location = useLocation();

  const navigation = [
    {
      name: 'Dashboard',
      href: '/admin',
      icon: ChartBarIcon,
      current: location.pathname === '/admin'
    },
    {
      name: 'Videos',
      href: '/admin/videos',
      icon: VideoCameraIcon,
      current: location.pathname === '/admin/videos'
    },
    {
      name: 'Articles',
      href: '/admin/articles',
      icon: NewspaperIcon,
      current: location.pathname === '/admin/articles'
    }
  ];

  return (
    <div className="min-h-screen bg-dark-bg">
      {/* Navigation */}
      <nav className="bg-dark-card border-b border-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <h1 className="text-xl font-bold text-white">Admin Panel</h1>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={`${
                      item.current
                        ? 'border-accent-lavender text-white'
                        : 'border-transparent text-gray-400 hover:text-white'
                    }
                    inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
                  >
                    <item.icon className="h-5 w-5 mr-2" />
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Page Content */}
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <Outlet />
      </main>
    </div>
  );
} 