import React, { useState, useEffect, useCallback } from 'react';
import { HeartIcon, MoonIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { storage } from '../services/storage';
import { WellnessEntry, MoodRating } from '../types/wellness';
import { WellnessStats } from './wellness/WellnessStats';
import { WellnessCelebrations } from './wellness/WellnessCelebrations';
import { TabTransition } from './wellness/TabTransition';
import { motion } from 'framer-motion';
import { haptics } from '../utils/haptics';
import { Toast } from './common/Toast';
import { TabButton } from './common/TabButton/TabButton';
import { auth } from '../services/firebase';
import { WellnessJournal } from './wellness/WellnessJournal';

type WellnessType = 'mood' | 'sleep' | 'energy';

function Wellness() {
  const [activeTab, setActiveTab] = useState<WellnessType>('mood');
  const [entries, setEntries] = useState<WellnessEntry[]>([]);
  const [rating, setRating] = useState<WellnessEntry['rating'] | null>(null);
  const [notes, setNotes] = useState('');
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadEntries = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await storage.getWellnessEntries(activeTab);
      setEntries(data);
    } catch (error) {
      console.error('Error loading entries:', error);
      if ((error as Error).message === 'User not authenticated') {
        setToast({ message: 'Please sign in to view entries', type: 'error' });
      } else {
        setToast({ message: 'Failed to load entries', type: 'error' });
      }
    } finally {
      setIsLoading(false);
    }
  }, [activeTab]);

  useEffect(() => {
    loadEntries();
  }, [loadEntries]);

  const handleSaveCheckin = async () => {
    if (!rating) {
      haptics.error();
      setToast({ message: 'Please select a rating', type: 'error' });
      setTimeout(() => setToast(null), 3000);
      return;
    }
    
    try {
      const entry = {
        userId: auth.currentUser?.uid || '',
        type: activeTab,
        timestamp: Date.now(),
        rating,
        ...(notes.trim() && { notes: notes.trim() })
      };

      await storage.addWellnessEntry(entry);
      haptics.success();
      setToast({ message: 'Check-in saved! 🌸', type: 'success' });
      setRating(null);
      setNotes('');
      await loadEntries();
    } catch (error) {
      console.error('Error saving check-in:', error);
      if ((error as Error).message === 'User not authenticated') {
        setToast({ message: 'Please sign in to save entries', type: 'error' });
      } else {
        setToast({ message: 'Failed to save check-in', type: 'error' });
      }
    } finally {
      setTimeout(() => setToast(null), 3000);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="text-accent-lavender animate-pulse">Loading...</div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Redesigned Header */}
      <div className="bg-gradient-to-br from-accent-lavender/20 to-accent-rose/20 rounded-xl p-6 border border-accent-lavender/20">
        <h2 className="text-2xl font-bold text-white">Your Wellness Journey</h2>
        <p className="text-gray-300 mt-2">
          Because a happy, healthy mom means a happy, healthy baby 💝
        </p>
        <div className="mt-4 p-3 bg-accent-lavender/10 rounded-lg border border-accent-lavender/20">
          <p className="text-sm text-gray-200">
            Take a moment for yourself. Your well-being matters just as much as your baby's. 
            These check-ins help you track your journey and celebrate your strength as a mother.
          </p>
        </div>
      </div>

      {/* Simplified and more intuitive tab buttons */}
      <div className="grid grid-cols-3 gap-3">
        <TabButton
          icon={<HeartIcon className="h-6 w-6" />}
          label="How are you?"
          subLabel="Track your feelings"
          isActive={activeTab === 'mood'}
          onClick={() => setActiveTab('mood')}
          iconColor="text-accent-peach"
        />
        <TabButton
          icon={<MoonIcon className="h-6 w-6" />}
          label="Sleep Quality"
          subLabel="How did you rest?"
          isActive={activeTab === 'sleep'}
          onClick={() => setActiveTab('sleep')}
          iconColor="text-accent-mint"
        />
        <TabButton
          icon={<SparklesIcon className="h-6 w-6" />}
          label="Energy Level"
          subLabel="Your daily energy"
          isActive={activeTab === 'energy'}
          onClick={() => setActiveTab('energy')}
          iconColor="text-accent-rose"
        />
      </div>

      {/* Enhanced Check-in Card */}
      <TabTransition tab={activeTab}>
        <div className="bg-gradient-to-br from-dark-card/95 via-dark-card/90 to-dark-card/80 rounded-xl p-6 border border-gray-800">
          {/* Welcoming Header */}
          <motion.div 
            className="text-center mb-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.1 }}
          >
            <h3 className="text-xl font-medium">
              {activeTab === 'mood' && "How's your heart today, mama? 💝"}
              {activeTab === 'sleep' && "Let's talk about your rest 🌙"}
              {activeTab === 'energy' && "How's your energy today? ⚡️"}
            </h3>
            <p className="text-sm text-gray-400 mt-2">
              {activeTab === 'mood' && "Your emotional well-being matters"}
              {activeTab === 'sleep' && "Quality rest is essential"}
              {activeTab === 'energy' && "Track your energy levels"}
            </p>
          </motion.div>

          {/* Enhanced Rating Selection */}
          <motion.div 
            className="space-y-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <div className="flex justify-between items-center px-4">
              {[1, 2, 3, 4, 5].map((value, index) => (
                <motion.button
                  key={value}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.1 * (index + 1) }}
                  onClick={() => setRating(value as MoodRating)}
                  className={`relative group`}
                >
                  {/* Emoji Button */}
                  <div className={`w-16 h-16 rounded-full flex items-center justify-center 
                                transition-all duration-300 transform
                                ${rating === value 
                                  ? 'bg-accent-lavender/20 scale-110' 
                                  : 'hover:bg-accent-lavender/10 hover:scale-105'}`}
                  >
                    <span className="text-2xl">
                      {activeTab === 'mood' && ['😔', '😐', '🙂', '😊', '🥰'][value - 1]}
                      {activeTab === 'sleep' && ['😴', '🥱', '😌', '😊', '✨'][value - 1]}
                      {activeTab === 'energy' && ['🔋', '⚡️', '💫', '✨', '🌟'][value - 1]}
                    </span>
                  </div>
                  
                  {/* Label */}
                  <div className={`absolute -bottom-5 left-1/2 transform -translate-x-1/2 
                                whitespace-nowrap text-xs transition-all duration-300 w-32 text-center
                                ${rating === value ? 'text-accent-lavender' : 'text-gray-400'}`}>
                    {activeTab === 'mood' && [
                      'Struggling',
                      'Coping',
                      'Okay',
                      'Good',
                      'Amazing'
                    ][value - 1]}
                    {activeTab === 'sleep' && [
                      'Exhausted',
                      'Restless',
                      'Rested',
                      'Peaceful',
                      'Refreshed'
                    ][value - 1]}
                    {activeTab === 'energy' && [
                      'Low',
                      'Building',
                      'Steady',
                      'Strong',
                      'Energized'
                    ][value - 1]}
                  </div>
                </motion.button>
              ))}
            </div>

            {/* Reflection Prompts */}
            <div className="space-y-4 mt-28">
              <h4 className="text-sm font-medium text-gray-400 mb-3">
                {activeTab === 'mood' && "Today's Reflections 💝"}
                {activeTab === 'sleep' && "Rest Reflections 🌙"}
                {activeTab === 'energy' && "Energy Check 💫"}
              </h4>
              <div className="bg-dark-bg/30 rounded-xl p-4 border border-accent-lavender/20">
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder={
                    activeTab === 'mood' 
                      ? "How are you really feeling? Remember, all emotions are valid. What made you smile today? What support do you need? 💝"
                    : activeTab === 'sleep'
                      ? "How was your rest? What helped you sleep better? What could make your rest more peaceful? 🌙"
                    : "What gives you energy? What drains you? What would help you feel more energized? ⚡️"
                  }
                  className="w-full bg-transparent border-none focus:ring-0 text-gray-200 placeholder-gray-500
                    resize-none h-24 text-sm"
                />
                
                {/* Optional Prompts */}
                <div className="mt-3 flex flex-wrap gap-2">
                  {activeTab === 'mood' && [
                    "I'm proud of...",
                    "I need support with...",
                    "Today's win was...",
                    "I'm grateful for..."
                  ].map(prompt => (
                    <button
                      key={prompt}
                      onClick={() => setNotes(notes ? `${notes}\n${prompt} ` : `${prompt} `)}
                      className="text-xs px-3 py-1.5 rounded-full bg-accent-lavender/10 text-accent-lavender
                               hover:bg-accent-lavender/20 transition-colors"
                    >
                      {prompt}
                    </button>
                  ))}
                  
                  {activeTab === 'sleep' && [
                    "My rest improved when...",
                    "I could sleep better if...",
                    "What helped me rest...",
                    "Tomorrow I'll try..."
                  ].map(prompt => (
                    <button
                      key={prompt}
                      onClick={() => setNotes(notes ? `${notes}\n${prompt} ` : `${prompt} `)}
                      className="text-xs px-3 py-1.5 rounded-full bg-accent-mint/10 text-accent-mint
                               hover:bg-accent-mint/20 transition-colors"
                    >
                      {prompt}
                    </button>
                  ))}
                  
                  {activeTab === 'energy' && [
                    "I feel energized when...",
                    "I need more...",
                    "What helped today...",
                    "I'll recharge by..."
                  ].map(prompt => (
                    <button
                      key={prompt}
                      onClick={() => setNotes(notes ? `${notes}\n${prompt} ` : `${prompt} `)}
                      className="text-xs px-3 py-1.5 rounded-full bg-accent-rose/10 text-accent-rose
                               hover:bg-accent-rose/20 transition-colors"
                    >
                      {prompt}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <motion.button 
              onClick={handleSaveCheckin}
              disabled={!rating}
              className={`w-full p-4 rounded-xl border flex items-center justify-center space-x-2 mt-8
                        ${rating 
                          ? 'bg-accent-lavender/20 border-accent-lavender text-accent-lavender hover:bg-accent-lavender/30' 
                          : 'bg-gray-800/20 border-gray-800 text-gray-500'}`}
            >
              <span className="text-lg">Save Check-in</span>
              {rating && <span>✨</span>}
            </motion.button>
          </motion.div>
        </div>
      </TabTransition>

      {/* Move WellnessCelebrations and WellnessStats here */}
      <WellnessCelebrations entries={entries} type={activeTab} />
      <WellnessStats entries={entries} type={activeTab} />
      <WellnessJournal 
        entries={entries} 
        type={activeTab} 
        setEntries={setEntries}
        setToast={setToast}
      />
      <Toast 
        message={toast?.message || ''} 
        type={toast?.type || 'success'} 
        isVisible={!!toast} 
      />
    </div>
  );
}

export default Wellness; 