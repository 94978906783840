import React from 'react';
import { motion } from 'framer-motion';
import { WeeklyStats, Milestone } from '../../types/victory';
import { StarIcon, HeartIcon, MoonIcon, SparklesIcon } from '@heroicons/react/24/outline';

interface Props {
  type: 'daily' | 'weekly' | 'milestone' | 'allTimeStats';
  data: {
    daily?: {
      feedingCount: number;
      sleepDuration: number;
      diaperCount: number;
      achievements: string[];
    };
    weekly?: WeeklyStats;
    milestone?: Milestone;
    stats?: {
      feedings: number;
      sleep: number;
      diapers: number;
      days: number;
    };
  };
}

export const ShareableImage = React.forwardRef<HTMLDivElement, Props>(({ type, data }, ref) => {
  return (
    <div 
      ref={ref}
      className="w-[1080px] h-[1080px] relative bg-gradient-to-br from-dark-bg via-dark-bg/95 to-dark-bg/90 p-16"
    >
      {/* Beautiful decorative elements */}
      <div className="absolute top-0 right-0 w-[600px] h-[600px] bg-accent-gold/10 rounded-full blur-[100px]" />
      <div className="absolute bottom-0 left-0 w-[500px] h-[500px] bg-accent-rose/10 rounded-full blur-[80px]" />
      <div className="absolute top-1/2 left-1/2 w-[400px] h-[400px] bg-accent-mint/10 rounded-full blur-[120px] transform -translate-x-1/2 -translate-y-1/2" />

      {/* Logo and Title */}
      <div className="text-center mb-16">
        <h1 className="text-6xl font-bold bg-gradient-to-r from-accent-rose via-accent-lavender to-accent-mint 
                     bg-clip-text text-transparent mb-4">
          NurturingMom
        </h1>
        {type === 'weekly' && <p className="text-3xl text-accent-mint">Weekly Progress Report 📊</p>}
        {type === 'milestone' && <p className="text-3xl text-accent-gold">Achievement Unlocked! 🎉</p>}
        {type === 'allTimeStats' && <p className="text-3xl text-accent-lavender">My Motherhood Journey 💖</p>}
      </div>

      {type === 'allTimeStats' && data.stats && (
        <div className="space-y-12">
          <h2 className="text-3xl font-bold text-white text-center mb-8">My Motherhood Journey</h2>
          <div className="grid grid-cols-2 gap-8">
            <StatBox icon="🍼" value={data.stats.feedings} label="Total Feedings" color="rose" />
            <StatBox icon="😴" value={Math.round(data.stats.sleep / 3600)} label="Hours of Sleep" color="lavender" />
            <StatBox icon="✨" value={data.stats.diapers} label="Diaper Changes" color="mint" />
            <StatBox icon="❤️" value={data.stats.days} label="Days of Love" color="gold" />
          </div>
          <div className="bg-dark-card/30 rounded-3xl p-8 backdrop-blur-sm border border-accent-lavender/20">
            <p className="text-2xl text-white text-center">
              {data.stats.days} Days of Beautiful Moments
            </p>
          </div>
        </div>
      )}

      {type === 'milestone' && data.milestone && (
        <div className="space-y-12">
          <div className="text-center">
            <span className="text-9xl mb-8 block">{data.milestone.icon}</span>
            <h2 className="text-5xl font-bold text-white mb-6">{data.milestone.title}</h2>
            <p className="text-3xl text-white">{data.milestone.description}</p>
          </div>
          
          <div className="bg-dark-card/30 rounded-3xl p-8 backdrop-blur-sm border border-accent-gold/20">
            <div className="text-center">
              <p className="text-2xl text-white">
                Achievement unlocked on {new Date(data.milestone.achievedAt).toLocaleDateString()}
              </p>
              {data.milestone.hint && (
                <p className="text-xl text-accent-gold mt-4">
                  {data.milestone.hint}
                </p>
              )}
            </div>
          </div>
        </div>
      )}

      {type === 'weekly' && data.weekly && (
        <div className="space-y-12">
          <h2 className="text-3xl font-bold text-white text-center mb-8">Weekly Progress Report</h2>
          <div className="grid grid-cols-3 gap-8">
            <WeeklyStatBox 
              title="Feedings"
              value={data.weekly.totalFeedings}
              improvement={data.weekly.improvementFromLastWeek?.feedings}
              icon="🍼"
              color="rose"
            />
            <WeeklyStatBox 
              title="Hours of Sleep"
              value={Math.round(data.weekly.totalSleep / 3600)}
              improvement={data.weekly.improvementFromLastWeek?.sleep && 
                Math.round(data.weekly.improvementFromLastWeek.sleep / 3600)}
              icon="😴"
              color="lavender"
            />
            <WeeklyStatBox 
              title="Changes"
              value={data.weekly.totalDiapers}
              improvement={data.weekly.improvementFromLastWeek?.diapers}
              icon="✨"
              color="mint"
            />
          </div>

          {/* Weekly insights */}
          <div className="bg-dark-card/30 rounded-3xl p-8 backdrop-blur-sm border border-accent-mint/20">
            <h3 className="text-2xl font-medium text-accent-mint mb-6">Weekly Highlights</h3>
            <div className="grid grid-cols-2 gap-6">
              {generateWeeklyInsights(data.weekly).map((insight, index) => (
                <div key={index} className="flex items-center gap-4">
                  <div className="w-3 h-3 rounded-full bg-accent-mint/50" />
                  <p className="text-xl text-gray-200">{insight}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Footer */}
      <div className="absolute bottom-16 left-16 right-16 text-center">
        <p className="text-xl text-white">
          Track your journey at nurturingmom.app
        </p>
      </div>
    </div>
  );
});

const StatBox = ({ icon, value, label, color }: { 
  icon: string;
  value: number;
  label: string;
  color: string;
}) => (
  <div className={`bg-dark-bg/50 rounded-2xl p-8 border border-accent-${color}/20`}>
    <div className="text-5xl mb-4">{icon}</div>
    <p className={`text-5xl font-bold text-accent-${color} mb-3`}>{value}</p>
    <p className="text-2xl text-white">{label}</p>
  </div>
);

const WeeklyStatBox = ({ 
  title, 
  value, 
  improvement, 
  icon, 
  color 
}: { 
  title: string;
  value: number;
  improvement?: number;
  icon: string;
  color: string;
}) => (
  <div className={`bg-dark-bg/50 rounded-2xl p-8 border border-accent-${color}/20`}>
    <div className="text-5xl mb-4">{icon}</div>
    <p className={`text-5xl font-bold text-accent-${color} mb-3`}>{value}</p>
    <p className="text-2xl text-gray-200 mb-2">{title}</p>
    {improvement !== undefined && (
      <p className={`text-xl ${improvement >= 0 ? 'text-accent-mint' : 'text-accent-rose'}`}>
        {improvement >= 0 ? '+' : ''}{improvement} from last week
      </p>
    )}
  </div>
);

const generateWeeklyInsights = (stats: WeeklyStats): string[] => {
  const insights: string[] = [];
  
  // Add insights based on improvements
  if (stats.improvementFromLastWeek) {
    if (stats.improvementFromLastWeek.feedings && stats.improvementFromLastWeek.feedings > 0) {
      insights.push(`${stats.improvementFromLastWeek.feedings} more feedings this week! 🎉`);
    }
    if (stats.improvementFromLastWeek.sleep && stats.improvementFromLastWeek.sleep > 0) {
      insights.push(`${Math.round(stats.improvementFromLastWeek.sleep / 3600)} more hours of sleep! 🌙`);
    }
    if (stats.improvementFromLastWeek.diapers && stats.improvementFromLastWeek.diapers > 0) {
      insights.push(`${stats.improvementFromLastWeek.diapers} more diaper changes! 💪`);
    }
  }

  // Add average insights
  insights.push(`Average of ${Math.round(stats.totalFeedings / 7)} feedings per day 🍼`);
  insights.push(`${Math.round((stats.totalSleep / 3600) / 7)} hours of sleep on average 😴`);
  
  return insights;
}; 