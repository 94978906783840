import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  HeartIcon, 
  SparklesIcon, 
  MoonIcon, 
  ClockIcon,
  ChatBubbleLeftRightIcon,
  AcademicCapIcon,
  ChartBarIcon,
  BookOpenIcon,
  StarIcon,
  BeakerIcon
} from '@heroicons/react/24/outline';
import { FeatureShowcase } from './previews/FeatureShowcase';
import { PhoneMockup } from './previews/PhoneMockup';
import { WellnessPreview } from './previews/WellnessPreview';
import { TrackingFormPreview } from './previews/TrackingFormPreview';
import { StatsPreview } from './previews/StatsPreview';
import { VictoriesPreview } from './previews/VictoriesPreview';
import { HealthFormPreview } from './previews/HealthFormPreview';

function FeaturePhones() {
  return (
    <div className="relative w-full overflow-hidden py-10">
      <div className="max-w-7xl mx-auto px-2">
        <div className="relative flex justify-center items-center gap-4 sm:gap-8">
          {/* Outer left */}
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="w-[200px] sm:w-[240px] -rotate-12 translate-y-8 hidden md:block opacity-80"
          >
            <PhoneMockup>
              <WellnessPreview />
            </PhoneMockup>
          </motion.div>

          {/* Inner left */}
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="w-[200px] sm:w-[240px] -rotate-6 translate-y-4 hidden sm:block opacity-90"
          >
            <PhoneMockup>
              <TrackingFormPreview />
            </PhoneMockup>
          </motion.div>

          {/* Center */}
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="w-[240px] sm:w-[280px] z-10"
          >
            <PhoneMockup>
              <StatsPreview />
            </PhoneMockup>
          </motion.div>

          {/* Inner right */}
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="w-[200px] sm:w-[240px] rotate-6 translate-y-4 hidden sm:block opacity-90"
          >
            <PhoneMockup>
              <VictoriesPreview />
            </PhoneMockup>
          </motion.div>

          {/* Outer right */}
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="w-[200px] sm:w-[240px] rotate-12 translate-y-8 hidden md:block opacity-80"
          >
            <PhoneMockup>
              <HealthFormPreview />
            </PhoneMockup>
          </motion.div>
        </div>

        {/* Gradient overlays */}
        <div className="absolute inset-0 bg-gradient-to-r from-dark-bg via-transparent to-dark-bg pointer-events-none" />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-dark-bg pointer-events-none" />
      </div>
    </div>
  );
}

function MobileAppShowcase() {
  return (
    <div className="md:hidden relative -mt-10 overflow-hidden">
      {/* Background Gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-dark-bg via-dark-bg/50 to-dark-bg pointer-events-none" />

      {/* Main Content */}
      <div className="relative">
        {/* Feature Sections */}
        <div className="space-y-24 pb-20"> {/* Increased spacing between sections */}
          {/* Dashboard Section */}
          <div className="text-center px-4">
            <h3 className="text-2xl font-bold mb-3">Beautiful Dashboard</h3>
            <p className="text-sm text-gray-400 mb-6 max-w-xs mx-auto">
              Everything you need, elegantly organized in one place
            </p>
            <div className="relative h-[400px]">
              <div className="absolute left-1/2 -translate-x-1/2 w-[200px]">
                <PhoneMockup>
                  <StatsPreview />
                </PhoneMockup>
              </div>
            </div>
          </div>

          {/* Baby Tracking Section */}
          <div className="text-center px-4">
            <h3 className="text-2xl font-bold mb-3">Smart Baby Tracking</h3>
            <p className="text-sm text-gray-400 mb-6 max-w-xs mx-auto">
              Effortlessly track feeds, sleep, and changes
            </p>
            <div className="relative h-[400px]">
              <div className="absolute left-1/2 -translate-x-1/2 w-[200px] rotate-[-5deg]">
                <PhoneMockup>
                  <TrackingFormPreview />
                </PhoneMockup>
              </div>
            </div>
          </div>

          {/* Wellness Section */}
          <div className="text-center px-4">
            <h3 className="text-2xl font-bold mb-3">Mom's Wellness</h3>
            <p className="text-sm text-gray-400 mb-6 max-w-xs mx-auto">
              Because your wellbeing matters too
            </p>
            <div className="relative h-[400px]">
              <div className="absolute left-1/2 -translate-x-1/2 w-[200px] rotate-[5deg]">
                <PhoneMockup>
                  <WellnessPreview />
                </PhoneMockup>
              </div>
            </div>
          </div>

          {/* Health Section */}
          <div className="text-center px-4">
            <h3 className="text-2xl font-bold mb-3">Health & Growth</h3>
            <p className="text-sm text-gray-400 mb-6 max-w-xs mx-auto">
              Never miss important milestones
            </p>
            <div className="relative h-[400px]">
              <div className="absolute left-1/2 -translate-x-1/2 w-[200px] rotate-[-3deg]">
                <PhoneMockup>
                  <HealthFormPreview />
                </PhoneMockup>
              </div>
            </div>
          </div>

          {/* Victories Section */}
          <div className="text-center px-4">
            <h3 className="text-2xl font-bold mb-3">Daily Victories</h3>
            <p className="text-sm text-gray-400 mb-6 max-w-xs mx-auto">
              Celebrate every moment of your journey
            </p>
            <div className="relative h-[400px]">
              <div className="absolute left-1/2 -translate-x-1/2 w-[200px] rotate-[3deg]">
                <PhoneMockup>
                  <VictoriesPreview />
                </PhoneMockup>
              </div>
            </div>
          </div>
        </div>

        {/* Quick Action */}
        <div className="text-center px-6 py-12 bg-gradient-to-t from-dark-bg to-transparent">
          <Link
            to="/signup"
            className="inline-block w-full px-6 py-3 bg-accent-mint text-dark-bg rounded-xl 
                     text-lg font-medium hover:bg-accent-mint/90 transition-colors"
          >
            Start Your Journey
          </Link>
        </div>
      </div>
    </div>
  );
}

function FloatingElements() {
  return (
    <>
      {/* Hero Section Elements - Smaller & fewer on mobile */}
      <motion.div 
        className="absolute top-32 left-[10%] text-accent-lavender/10"
        animate={{ 
          y: [-10, 10, -10],
          rotate: [-5, 5, -5]
        }}
        transition={{ 
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <span className="text-2xl md:text-4xl">👶</span>
      </motion.div>

      <motion.div 
        className="absolute top-40 right-[15%] text-accent-mint/10"
        animate={{ 
          y: [10, -10, 10],
          rotate: [5, -5, 5]
        }}
        transition={{ 
          duration: 7,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <span className="text-xl md:text-3xl">🍼</span>
      </motion.div>

      {/* Features Section Elements - Some hidden on mobile */}
      <motion.div 
        className="absolute top-[40%] left-[5%] text-accent-rose/10 hidden md:block"
        animate={{ 
          scale: [1, 1.1, 1],
          rotate: [0, -10, 0]
        }}
        transition={{ 
          duration: 6,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <HeartIcon className="w-16 h-16" />
      </motion.div>

      {/* Middle Section Elements - Smaller on mobile */}
      <motion.div 
        className="absolute top-[60%] left-[20%] text-accent-mint/10"
        animate={{ 
          y: [-15, 15, -15],
          x: [-5, 5, -5]
        }}
        transition={{ 
          duration: 10,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <span className="text-2xl md:text-4xl">🌙</span>
      </motion.div>

      <motion.div 
        className="absolute top-[65%] right-[25%] text-accent-peach/10"
        animate={{ 
          y: [15, -15, 15],
          x: [5, -5, 5]
        }}
        transition={{ 
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <ClockIcon className="w-8 h-8 md:w-14 md:h-14" />
      </motion.div>

      {/* Bottom Section Elements - Some hidden on mobile */}
      <motion.div 
        className="absolute bottom-[20%] left-[15%] text-accent-lavender/10 hidden md:block"
        animate={{ 
          scale: [1, 1.15, 1],
          rotate: [-5, 5, -5]
        }}
        transition={{ 
          duration: 7,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <SparklesIcon className="w-12 h-12" />
      </motion.div>

      {/* Additional Scattered Elements - Fewer & smaller on mobile */}
      <motion.div 
        className="absolute top-[30%] left-[30%] text-accent-gold/10"
        animate={{ 
          opacity: [0.5, 1, 0.5],
          scale: [1, 1.1, 1]
        }}
        transition={{ 
          duration: 5,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <span className="text-base md:text-2xl">✨</span>
      </motion.div>

      <motion.div 
        className="absolute top-[70%] right-[30%] text-accent-rose/10 hidden md:block"
        animate={{ 
          opacity: [0.5, 1, 0.5],
          scale: [1, 1.1, 1]
        }}
        transition={{ 
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <BeakerIcon className="w-10 h-10" />
      </motion.div>
    </>
  );
}

function MotherChildIllustration() {
  return (
    <svg 
      width="48" 
      height="48" 
      viewBox="0 0 48 48" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className="opacity-80"
    >
      {/* Mother's head with more detail */}
      <path 
        d="M24 8C19 8 15 12 15 17C15 22 19 26 24 26C29 26 33 22 33 17C33 12 29 8 24 8Z"
        className="fill-accent-lavender/30" 
      />
      
      {/* Mother's arms cradling baby */}
      <path 
        d="M18 24C18 24 20 32 24 32C28 32 30 24 30 24"
        className="stroke-accent-rose/30" 
        strokeWidth="2"
        strokeLinecap="round"
      />
      
      {/* Baby in arms - smaller to represent newborn */}
      <path
        d="M22 26C22 26 24 30 26 26"
        className="stroke-accent-mint/30"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle 
        cx="24" 
        cy="28" 
        r="2" 
        className="fill-accent-mint/30" 
      />
      
      {/* Mother's loving gaze down at baby */}
      <path
        d="M22 16C22 16 23 17 24 17C25 17 26 16 26 16"
        className="stroke-accent-rose/30"
        strokeWidth="1"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function LandingPage() {
  const [showStory, setShowStory] = useState(false);

  return (
    <div className="min-h-screen bg-dark-bg text-white relative overflow-hidden">
      <FloatingElements />
      
      {/* Header/Nav */}
      <nav className="fixed top-0 left-0 right-0 bg-dark-card/95 backdrop-blur-xl border-b border-gray-800 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <HeartIcon className="h-8 w-8 text-accent-lavender" />
              <span className="ml-2 text-xl font-bold bg-gradient-to-r from-accent-lavender via-accent-rose to-accent-mint bg-clip-text text-transparent">
                NurturingMom
              </span>
            </div>
            <div className="flex items-center space-x-4">
              <Link 
                to="/login"
                className="px-4 py-2 bg-dark-card text-accent-lavender border border-accent-lavender/30 
                         hover:bg-accent-lavender/10 rounded-lg transition-colors"
              >
                Log In
              </Link>
              <Link 
                to="/signup"
                className="px-4 py-2 bg-accent-mint text-dark-bg rounded-lg 
                         hover:bg-accent-mint/90 transition-colors"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section - Adjusted mobile padding */}
      <section className="pt-28 sm:pt-20 pb-8"> {/* Increased padding-top for mobile only */}
        <div className="max-w-4xl mx-auto text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="space-y-4 sm:space-y-6"
          >
            <h1 className="text-3xl sm:text-5xl font-bold leading-tight">
              <span className="hidden sm:block">Your Companion Through</span>
              <span className="sm:hidden">Your Companion Through{" "}
                <span className="bg-gradient-to-r from-accent-lavender to-accent-rose bg-clip-text text-transparent">
                  Early Motherhood
                </span>
              </span>
              <span className="hidden sm:block bg-gradient-to-r from-accent-lavender to-accent-rose bg-clip-text text-transparent">
                Early Motherhood
              </span>
            </h1>
            <p className="text-base sm:text-xl text-gray-400 max-w-xl mx-auto">
              Navigate the precious first months with confidence.
            </p>
            <div className="flex flex-col items-center space-y-6"> {/* Increased from space-y-3 */}
              <Link
                to="/signup"
                className="px-6 sm:px-8 py-3 bg-accent-mint text-dark-bg rounded-lg 
                         text-base sm:text-lg font-medium hover:bg-accent-mint/90 transition-colors"
              >
                Start Your Journey
              </Link>
              
              {/* Story Toggle - Added more space above */}
              <button
                onClick={() => setShowStory(!showStory)}
                className="flex items-center text-accent-mint hover:text-accent-mint/80 transition-colors"
              >
                <span className="text-sm sm:text-base font-medium">
                  {showStory ? 'Hide Our Story' : 'Read Our Story'}
                </span>
                <svg
                  className={`w-4 h-4 ml-2 transform transition-transform ${showStory ? 'rotate-180' : 'rotate-0'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                    d="M19 9l-7 7-7-7" />
                </svg>
              </button>

              {/* Collapsible Story Text - Fixed mobile spacing and overflow */}
              <motion.div
                initial={false}
                animate={{ height: showStory ? 'auto' : 0, opacity: showStory ? 1 : 0 }}
                className="overflow-hidden max-w-2xl mx-auto px-4 sm:px-0" // Added padding for mobile
              >
                <p className="text-xs sm:text-sm text-gray-300 leading-relaxed mt-4 mb-6"> {/* Added bottom margin */}
                  When my wife, Bibie, and I brought our baby boy, Eden, home, we were thrilled but also a bit overwhelmed. There was so much to keep track of, and we wanted to make sure we were doing everything right.
                  {/* Added line break */}
                  <br /><br />
                  So, I built this app to help us out. It made things easier for us, and I hope it can help you too.
                </p>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Mobile: See It In Action - Adjusted spacing */}
      <div className="md:hidden -mt-4"> {/* Reduced negative margin */}
        <FeatureShowcase />
      </div>

      {/* Desktop: Product Showcase - Reduced spacing */}
      <div className="hidden md:block">
        <div className="py-2"> {/* Reduced from py-4 */}
          <FeaturePhones />
        </div>
      </div>

      {/* Features Grid - Adjusted formatting */}
      <section className="py-8 px-4 sm:px-6 lg:px-8 bg-dark-card/50">
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-6">
            <h2 className="text-2xl sm:text-3xl font-bold mb-2">Designed for Your New Journey</h2>
            <p className="text-sm sm:text-base text-gray-400 max-w-2xl mx-auto">
              Everything you need to feel confident and supported in your early motherhood days
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: "Never Miss a Moment",
                desc: "Keep track of feedings, sleep, and changes with simple timers. No more second-guessing when the last feed was – especially during those sleepy 3 AM sessions.",
                icon: ClockIcon,
                color: 'peach'
              },
              {
                title: "Your Wellbeing Matters",
                desc: "Because taking care of yourself is just as important. Track your mood, sleep, and energy levels. Get gentle reminders for self-care and celebrate your progress.",
                icon: SparklesIcon,
                color: 'mint'
              },
              {
                title: "Celebrate Every Win",
                desc: "From the first smile to sleeping through the night – capture and celebrate those precious milestones. You're doing amazing, and we help you see it.",
                icon: StarIcon,
                color: 'gold'
              },
              {
                title: "Peace of Mind",
                desc: "Never worry about missing vaccinations or checkups. Get timely reminders and keep all health records in one place. Perfect for sharing with healthcare providers.",
                icon: HeartIcon,
                color: 'rose'
              },
              {
                title: "Understand Patterns",
                desc: "Discover your baby's natural rhythms with easy-to-read charts. Spot patterns in feeding, sleep, and more – helping you plan and adjust your routine.",
                icon: ChartBarIcon,
                color: 'lavender'
              },
              {
                title: "Your Journey, Your Story",
                desc: "Document the beautiful (and challenging) moments of early motherhood. Our guided prompts help you reflect and remember these precious times.",
                icon: BookOpenIcon,
                color: 'mint'
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                className={`bg-dark-card p-6 rounded-xl border border-gray-800 hover:border-accent-${feature.color}/30 transition-all duration-300`}
              >
                <div className={`p-3 bg-accent-${feature.color}/10 w-fit rounded-lg mb-4`}>
                  <feature.icon className={`h-6 w-6 text-accent-${feature.color}`} />
                </div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-400">{feature.desc}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Desktop: See It In Action */}
      <section className="hidden md:block py-8">
        <FeatureShowcase />
      </section>

      {/* CTA Section - Adjusted formatting */}
      <section className="pt-6 pb-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="space-y-4 sm:space-y-6"
          >
            <h2 className="text-2xl sm:text-3xl font-bold">Begin Your Journey Today</h2>
            <p className="text-sm sm:text-base text-gray-400 max-w-2xl mx-auto">
              Join thousands of moms making their early motherhood journey more organized and beautiful.
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-4 mt-4">
              <Link
                to="/signup"
                className="px-6 sm:px-8 py-3 bg-accent-mint text-dark-bg rounded-lg 
                         text-base sm:text-lg font-medium hover:bg-accent-mint/90 transition-colors"
              >
                Get Started
              </Link>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Footer - Simplified */}
      <footer className="bg-dark-card border-t border-gray-800 py-3">
        <div className="max-w-7xl mx-auto text-center">
          <p className="text-gray-400 mb-1.5">
            © 2024 NurturingMom. All rights reserved.
          </p>
          
          {/* Dedication - Without illustration */}
          <p 
            className="font-['Crimson_Text'] italic text-sm sm:text-base
                     bg-gradient-to-r from-accent-lavender via-accent-rose to-accent-mint 
                     bg-clip-text text-transparent"
          >
            Made with love for Bibie & Eden
          </p>
        </div>
      </footer>
    </div>
  );
} 