import React from 'react';
import { motion } from 'framer-motion';
import { VictoryEntry } from '../../types/victory';
import { StarIcon, HeartIcon, MoonIcon, SparklesIcon, ShareIcon } from '@heroicons/react/24/outline';
import { toPng } from 'html-to-image';
import { ShareableVictoryCard } from './ShareableVictoryCard';
import ReactDOM from 'react-dom';

interface Props {
  victory: VictoryEntry;
  isToday: boolean;
  setToast: (toast: { message: string; type: 'success' | 'error' } | null) => void;
}

export const VictoryCard = ({ victory, isToday, setToast }: Props) => {
  const date = new Date(victory.timestamp);

  const generateShareText = (victory: VictoryEntry): string => {
    let text = `🌟 My Motherhood Journey - ${new Date(victory.timestamp).toLocaleDateString()}\n\n`;
    
    text += `Today's Victories:\n`;
    text += `🍼 ${victory.feedingCount} Feedings\n`;
    text += `😴 ${Math.round((victory.sleepDuration || 0) / 3600)} Hours of Sleep\n`;
    text += `💪 ${victory.diaperCount} Diaper Changes\n\n`;

    if (victory.customVictories?.length) {
      text += `Special Achievements:\n`;
      victory.customVictories.forEach(achievement => {
        text += `${achievement}\n`;
      });
    }

    text += `\nTracked with NurturingMom ❤️`;
    
    return text;
  };

  const handleShare = async () => {
    try {
      // Create a portal for the shareable card
      const portal = document.createElement('div');
      portal.style.position = 'absolute';
      portal.style.left = '-9999px';
      document.body.appendChild(portal);

      // Create a ref for the shareable card
      const shareableRef = React.createRef<HTMLDivElement>();
      
      // Render the shareable card
      ReactDOM.render(
        <ShareableVictoryCard ref={shareableRef} victory={victory} />,
        portal
      );

      // Wait for images to load
      await new Promise(resolve => setTimeout(resolve, 100));

      if (shareableRef.current) {
        const dataUrl = await toPng(shareableRef.current, {
          quality: 0.95,
          pixelRatio: 2
        });

        // Try Web Share API first
        if (navigator.share) {
          const blob = await (await fetch(dataUrl)).blob();
          const file = new File([blob], 'victory.png', { type: 'image/png' });
          
          await navigator.share({
            files: [file],
            title: 'My Motherhood Victory',
            text: 'Celebrating my journey of motherhood! ❤️'
          });
        } else {
          // Fallback to download
          const link = document.createElement('a');
          link.download = 'motherhood-victory.png';
          link.href = dataUrl;
          link.click();
        }

        setToast({ message: 'Victory card ready to share!', type: 'success' });
      }
    } catch (error) {
      console.error('Error sharing:', error);
      setToast({ message: 'Failed to create shareable card', type: 'error' });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="relative overflow-hidden rounded-xl"
    >
      {/* Beautiful gradient background */}
      <div className="absolute inset-0 bg-gradient-to-br from-dark-card/95 via-dark-card/90 to-dark-card/80" />
      <div className="absolute inset-0 bg-gradient-to-br from-accent-gold/5 via-accent-rose/5 to-accent-mint/5 opacity-60" />
      
      {/* Decorative elements */}
      <div className="absolute top-0 right-0 w-32 h-32 bg-accent-gold/5 rounded-full blur-3xl" />
      <div className="absolute bottom-0 left-0 w-24 h-24 bg-accent-rose/5 rounded-full blur-2xl" />

      <div className="relative p-6 border border-gray-800/50 rounded-xl backdrop-blur-sm">
        {/* Header with Share Button */}
        <div className="flex justify-between items-start mb-6">
          <div>
            <h3 className="text-xl font-bold text-white flex items-center gap-2">
              {isToday && <StarIcon className="h-5 w-5 text-accent-gold animate-pulse" />}
              {isToday ? "Today's Journey" : formatDate(date)}
            </h3>
            <p className="text-sm text-gray-400 mt-1">
              {isToday ? "Creating beautiful memories" : getRelativeTimeText(date)}
            </p>
          </div>
          {/* Share Button - Made more prominent */}
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleShare}
            className="flex items-center gap-2 px-4 py-2 bg-accent-gold/10 rounded-lg 
                     text-accent-gold hover:bg-accent-gold/20 transition-all duration-300"
          >
            <ShareIcon className="h-5 w-5" />
            <span className="text-sm">Share</span>
          </motion.button>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-3 gap-4 mb-6">
          <StatBox
            icon={<HeartIcon className="h-6 w-6" />}
            value={victory.feedingCount || 0}
            label="Feedings"
            color="rose"
          />
          <StatBox
            icon={<MoonIcon className="h-6 w-6" />}
            value={Math.round((victory.sleepDuration || 0) / 3600)}
            label="Hours Sleep"
            color="lavender"
          />
          <StatBox
            icon={<SparklesIcon className="h-6 w-6" />}
            value={victory.diaperCount || 0}
            label="Changes"
            color="mint"
          />
        </div>

        {/* Achievements */}
        {victory.customVictories && victory.customVictories.length > 0 && (
          <div className="bg-dark-bg/30 rounded-lg p-4">
            <h4 className="text-sm font-medium text-accent-gold mb-3">Today's Special Moments</h4>
            <div className="space-y-2">
              {victory.customVictories.map((achievement, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="flex items-center gap-2"
                >
                  <div className="w-2 h-2 rounded-full bg-accent-gold/50" />
                  <p className="text-sm text-gray-300">{achievement}</p>
                </motion.div>
              ))}
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

const StatBox = ({ icon, value, label, color }: { 
  icon: React.ReactNode; 
  value: number; 
  label: string;
  color: string;
}) => (
  <div className={`bg-dark-bg/50 rounded-lg p-4 border border-accent-${color}/20`}>
    <div className={`bg-accent-${color}/10 p-2 rounded-full w-12 h-12 mx-auto mb-2 
                   flex items-center justify-center`}>
      <div className={`text-accent-${color}`}>{icon}</div>
    </div>
    <p className={`text-2xl font-bold text-accent-${color}`}>{value}</p>
    <p className="text-xs text-gray-400">{label}</p>
  </div>
);

const formatDate = (date: Date) => {
  return date.toLocaleDateString('en-US', { 
    weekday: 'long',
    month: 'short',
    day: 'numeric'
  });
};

const getRelativeTimeText = (date: Date) => {
  const days = Math.floor((new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
  if (days === 1) return 'Yesterday';
  if (days < 7) return `${days} days ago`;
  return formatDate(date);
}; 