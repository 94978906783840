import React from 'react';
import { useNavigate } from 'react-router-dom';
import { VideoCameraIcon, NewspaperIcon } from '@heroicons/react/24/outline';

export function AdminDashboard() {
  const navigate = useNavigate();

  const adminActions = [
    {
      title: 'Manage Videos',
      description: 'Upload and manage daily learning videos',
      icon: VideoCameraIcon,
      path: '/admin/videos',
      color: 'text-accent-mint'
    },
    {
      title: 'Manage Articles',
      description: 'Add and manage learning articles',
      icon: NewspaperIcon,
      path: '/admin/articles',
      color: 'text-accent-peach'
    }
  ];

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white">Admin Dashboard</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {adminActions.map((action) => (
          <button
            key={action.path}
            onClick={() => navigate(action.path)}
            className="bg-dark-card p-6 rounded-lg border border-gray-800 
                     hover:border-accent-lavender/30 transition-all duration-300
                     text-left"
          >
            <div className="flex items-start space-x-4">
              <div className={`p-3 rounded-lg bg-dark-bg ${action.color}`}>
                <action.icon className="h-6 w-6" />
              </div>
              <div>
                <h3 className="text-lg font-medium text-white">{action.title}</h3>
                <p className="text-sm text-gray-400 mt-1">{action.description}</p>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
} 