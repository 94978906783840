interface BabyAge {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
}

export function standardizeAge(age: BabyAge) {
  let totalDays = 0;
  
  // Convert everything to days
  if (age.years) totalDays += age.years * 365;
  if (age.months) totalDays += age.months * 30.44; // Average month length
  if (age.weeks) totalDays += age.weeks * 7;
  if (age.days) totalDays += age.days;

  // Convert back to a standardized format
  const years = Math.floor(totalDays / 365);
  totalDays %= 365;
  
  const months = Math.floor(totalDays / 30.44);
  totalDays %= 30.44;
  
  const weeks = Math.floor(totalDays / 7);
  const days = Math.round(totalDays % 7);

  return {
    years: years || undefined,
    months: months || undefined,
    weeks: weeks || undefined,
    days: days || undefined
  };
}

export function formatAge(age: BabyAge): string {
  const standardized = standardizeAge(age);
  const parts: string[] = [];

  if (standardized.years) parts.push(`${standardized.years}y`);
  if (standardized.months) parts.push(`${standardized.months}m`);
  if (standardized.weeks) parts.push(`${standardized.weeks}w`);
  if (standardized.days) parts.push(`${standardized.days}d`);

  return parts.join(' ');
} 