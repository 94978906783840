import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { PaperAirplaneIcon, ExclamationTriangleIcon, TrashIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Message } from '../../types/learning';
import { aiService } from '../../services/aiService';
import { Toast } from '../common/Toast';

const MAX_MESSAGES = 50; // Increased max messages for better context
const MESSAGE_TIMEOUT = 30000; // Increased timeout for longer responses
const RATE_LIMIT = 10; // Messages per minute
const CONTEXT_WINDOW = 10; // Number of previous messages to include for context

// Add these quick prompt categories
const QUICK_PROMPTS = {
  'Sleep': [
    "Help! My baby won't sleep through the night",
    "What's a good bedtime routine for a 6-month-old?",
    "How to handle sleep regression?"
  ],
  'Feeding': [
    "When should I start solid foods?",
    "Tips for successful breastfeeding",
    "How often should my newborn feed?"
  ],
  'Development': [
    "What milestones should I look for?",
    "Activities to support baby's development",
    "When should my baby start crawling?"
  ],
  'Self-Care': [
    "How to manage postpartum stress",
    "Tips for new mom self-care",
    "Balancing baby care and rest"
  ]
};

// Add helpful resource links that AI can reference
const RESOURCES = {
  'Sleep': [
    { title: 'Sleep Training Guide', url: '...' },
    { title: 'Baby Sleep Patterns', url: '...' }
  ],
  'Feeding': [
    { title: 'Breastfeeding Tips', url: '...' },
    { title: 'Solid Food Introduction', url: '...' }
  ],
  // Add more categories
};

// Add mood selector
const MOODS = ['Worried', 'Tired', 'Overwhelmed', 'Hopeful', 'Confused'];

// Add this function before the component
const extractTopics = (text: string): string[] => {
  const commonTopics = [
    'Sleep', 'Feeding', 'Development', 'Health',
    'Nutrition', 'Behavior', 'Safety', 'Milestones',
    'Breastfeeding', 'Solid Foods', 'Teething',
    'Crying', 'Playtime', 'Growth', 'Vaccinations'
  ];
  
  return commonTopics.filter(topic => 
    text.toLowerCase().includes(topic.toLowerCase())
  );
};

export default function AIChatSection() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [messageCount, setMessageCount] = useState(0);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const lastMessageTime = useRef<number>(0);
  const [babyAge, setBabyAge] = useState<string>('');
  const [topicsDiscussed, setTopicsDiscussed] = useState<string[]>([]);
  const [showQuickPrompts, setShowQuickPrompts] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null);

  // Load chat history from localStorage on component mount
  useEffect(() => {
    const savedMessages = localStorage.getItem('chatHistory');
    if (savedMessages) {
      try {
        const parsedMessages = JSON.parse(savedMessages);
        setMessages(parsedMessages);
        // Extract topics from saved messages
        const allTopics = parsedMessages
          .filter((msg: Message) => msg.sender === 'ai')
          .flatMap((msg: Message) => extractTopics(msg.text));
        setTopicsDiscussed(Array.from(new Set(allTopics)));
      } catch (error) {
        console.error('Error parsing saved messages:', error);
        localStorage.removeItem('chatHistory');
      }
    }
  }, []);

  // Save chat history to localStorage whenever messages change
  useEffect(() => {
    if (messages.length > 0) {
      localStorage.setItem('chatHistory', JSON.stringify(messages));
    }
  }, [messages]);

  // Auto-scroll to bottom when new messages arrive
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Reset message count every minute
  useEffect(() => {
    const interval = setInterval(() => {
      setMessageCount(0);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const clearChat = () => {
    if (window.confirm('Are you sure you want to clear your chat history? This cannot be undone.')) {
      setMessages([]);
      setTopicsDiscussed([]);
      localStorage.removeItem('chatHistory');
      setToast({ message: 'Chat history cleared', type: 'success' });
    }
  };

  const getContextMessages = () => {
    // Get last CONTEXT_WINDOW messages for context
    return messages.slice(-CONTEXT_WINDOW).map(msg => ({
      role: msg.role || (msg.sender === 'user' ? 'user' : 'assistant'),
      content: msg.text
    }));
  };

  const checkRateLimit = (): boolean => {
    const now = Date.now();
    if (messageCount >= RATE_LIMIT) {
      setError('Please wait a moment before sending more messages.');
      return false;
    }
    if (now - lastMessageTime.current < 1000) { // Minimum 1 second between messages
      setError('Please slow down your messages.');
      return false;
    }
    return true;
  };

  const validateInput = (text: string): boolean => {
    if (text.length > 500) {
      setError('Message is too long. Please keep it under 500 characters.');
      return false;
    }
    if (text.trim().length < 2) {
      setError('Message is too short.');
      return false;
    }
    // Check for inappropriate content
    const inappropriatePatterns = [
      /\b(hack|crack|exploit)\b/i,
      // Add more patterns as needed
    ];
    if (inappropriatePatterns.some(pattern => pattern.test(text))) {
      setError('Your message contains inappropriate content.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!checkRateLimit()) return;
    if (!validateInput(input)) return;

    const userMessage: Message = {
      id: Date.now().toString(),
      text: input.trim(),
      sender: 'user',
      timestamp: Date.now(),
      role: 'user'
    };

    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsLoading(true);
    setMessageCount(prev => prev + 1);
    lastMessageTime.current = Date.now();

    const timeoutId = setTimeout(() => {
      setIsLoading(false);
      setError('Response timed out. Please try again.');
    }, MESSAGE_TIMEOUT);

    try {
      // Get context from previous messages
      const contextMessages = getContextMessages();
      const contextWithAge = babyAge ? `My baby is in the ${babyAge} age range. ${input}` : input;
      const response = await aiService.generateResponse(contextWithAge, contextMessages);

      clearTimeout(timeoutId);

      const aiMessage: Message = {
        id: (Date.now() + 1).toString(),
        text: response,
        sender: 'ai',
        timestamp: Date.now(),
        role: 'assistant'
      };

      setMessages(prev => {
        const newMessages = [...prev, aiMessage];
        if (newMessages.length > MAX_MESSAGES) {
          return newMessages.slice(-MAX_MESSAGES);
        }
        return newMessages;
      });
    } catch (error) {
      console.error('AI response error:', error);
      setError('Failed to get response. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.sender === 'ai') {
        const newTopics = extractTopics(lastMessage.text);
        setTopicsDiscussed(prev => Array.from(new Set([...prev, ...newTopics])));
      }
    }
  }, [messages]);

  return (
    <div className="bg-dark-card rounded-lg border border-gray-800 h-[600px] flex flex-col">
      {/* Header with Baby Age Selector and Clear Chat */}
      <div className="p-4 border-b border-gray-800">
        <div className="flex justify-between items-center mb-3">
          <div>
            <h3 className="text-lg font-medium text-white">Ask AI Assistant</h3>
            <p className="text-sm text-gray-400">Get instant answers to your parenting questions</p>
          </div>
          <div className="flex items-center space-x-3">
            <select
              value={babyAge}
              onChange={(e) => setBabyAge(e.target.value)}
              className="bg-dark-bg text-white border border-gray-800 rounded-lg px-3 py-1 text-sm"
            >
              <option value="">Baby's Age</option>
              <option value="newborn">0-3 months</option>
              <option value="infant">3-6 months</option>
              <option value="baby">6-12 months</option>
              <option value="toddler">1-3 years</option>
            </select>
            {messages.length > 0 && (
              <button
                onClick={clearChat}
                className="p-2 text-gray-400 hover:text-accent-rose rounded-lg 
                         hover:bg-accent-rose/10 transition-colors"
                title="Clear chat history"
              >
                <TrashIcon className="h-5 w-5" />
              </button>
            )}
          </div>
        </div>
        {messages.length > 0 && (
          <div className="text-xs text-gray-500">
            Chat history is automatically saved
          </div>
        )}
      </div>

      {/* Quick Prompts - Collapsible */}
      <div className="border-b border-gray-800">
        <button
          onClick={() => setShowQuickPrompts(!showQuickPrompts)}
          className="w-full p-2 text-sm text-gray-400 hover:bg-gray-800/50 flex items-center justify-between"
        >
          <span>Popular Questions</span>
          <ChevronDownIcon 
            className={`h-4 w-4 transform transition-transform ${showQuickPrompts ? 'rotate-180' : ''}`} 
          />
        </button>
        {showQuickPrompts && (
          <div className="p-2 grid grid-cols-2 gap-2 bg-dark-bg/50">
            {Object.entries(QUICK_PROMPTS).slice(0, 4).map(([category, prompts]) => (
              prompts.slice(0, 2).map(prompt => (
                <button
                  key={prompt}
                  onClick={() => {
                    setInput(prompt);
                    setShowQuickPrompts(false);
                    handleSubmit(new Event('submit') as any);
                  }}
                  className="text-xs text-left text-gray-300 hover:text-white 
                           hover:bg-gray-800/50 p-2 rounded-lg transition-colors"
                >
                  {prompt}
                </button>
              ))
            ))}
          </div>
        )}
      </div>

      {/* Chat Messages */}
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.length === 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-dark-bg border border-gray-800 rounded-lg p-3"
          >
            <p className="text-sm text-gray-300">
              Hello! I'm here to help with your parenting questions. Feel free to ask anything!
            </p>
          </motion.div>
        )}

        {messages.map(message => (
          <motion.div
            key={message.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
          >
            <div
              className={`max-w-[80%] rounded-lg p-3 ${
                message.sender === 'user'
                  ? 'bg-accent-lavender/20 text-white'
                  : 'bg-dark-bg border border-gray-800 text-gray-300'
              }`}
            >
              <p className="text-sm whitespace-pre-wrap">{message.text}</p>
              <span className="text-xs text-gray-500 mt-1 block">
                {new Date(message.timestamp).toLocaleTimeString()}
              </span>
            </div>
          </motion.div>
        ))}

        {isLoading && (
          <div className="flex justify-start">
            <div className="bg-dark-bg border border-gray-800 rounded-lg p-3">
              <div className="flex space-x-2">
                <div className="w-2 h-2 bg-accent-lavender/50 rounded-full animate-bounce" />
                <div className="w-2 h-2 bg-accent-lavender/50 rounded-full animate-bounce delay-100" />
                <div className="w-2 h-2 bg-accent-lavender/50 rounded-full animate-bounce delay-200" />
              </div>
            </div>
          </div>
        )}

        {error && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="flex items-center space-x-2 text-accent-rose text-sm"
          >
            <ExclamationTriangleIcon className="h-4 w-4" />
            <span>{error}</span>
          </motion.div>
        )}

        <div ref={messagesEndRef} />
      </div>

      {/* Input Form */}
      <form onSubmit={handleSubmit} className="p-4 border-t border-gray-800">
        <div className="flex space-x-2">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your question..."
            disabled={isLoading}
            maxLength={500}
            className="flex-1 bg-dark-bg text-white rounded-lg px-4 py-2 border border-gray-800 
                     focus:outline-none focus:border-accent-lavender/50 disabled:opacity-50"
          />
          <button
            type="submit"
            disabled={!input.trim() || isLoading}
            className="p-2 bg-accent-lavender/20 text-accent-lavender rounded-lg 
                     hover:bg-accent-lavender/30 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <PaperAirplaneIcon className="h-5 w-5" />
          </button>
        </div>
        <div className="mt-2 text-xs text-gray-500">
          {500 - input.length} characters remaining
        </div>
      </form>

      {/* Add Toast component */}
      <Toast 
        message={toast?.message || ''} 
        type={toast?.type || 'success'} 
        isVisible={!!toast} 
      />
    </div>
  );
} 