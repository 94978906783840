import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  UserIcon, 
  KeyIcon, 
  EnvelopeIcon, 
  ArrowRightOnRectangleIcon,
  PencilIcon,
  HeartIcon
} from '@heroicons/react/24/outline';
import { useAuth } from '../../contexts/AuthContext';
import { authService } from '../../services/auth';
import { Toast } from '../common/Toast';

function UserProfile() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null);

  // Form states
  const [name, setName] = useState(currentUser?.name || '');
  const [babyName, setBabyName] = useState(currentUser?.babyName || '');
  const [babyAgeYears, setBabyAgeYears] = useState(currentUser?.babyAge?.years?.toString() || '');
  const [babyAgeMonths, setBabyAgeMonths] = useState(currentUser?.babyAge?.months?.toString() || '');
  const [babyAgeDays, setBabyAgeDays] = useState(currentUser?.babyAge?.days?.toString() || '');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');

  const handleUpdateProfile = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const babyAge = (babyAgeYears || babyAgeMonths || babyAgeDays) 
        ? {
            years: babyAgeYears ? parseInt(babyAgeYears) : undefined,
            months: babyAgeMonths ? parseInt(babyAgeMonths) : undefined,
            days: babyAgeDays ? parseInt(babyAgeDays) : undefined
          }
        : undefined;

      await authService.updateUserProfile({
        name,
        babyName: babyName || undefined,
        babyAge
      });
      
      setToast({ message: 'Profile updated successfully!', type: 'success' });
      setIsEditing(false);
    } catch (err) {
      setToast({ message: 'Failed to update profile', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      return;
    }
    
    setLoading(true);
    
    try {
      await authService.updatePassword(newPassword);
      setToast({ message: 'Password updated successfully!', type: 'success' });
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      setToast({ message: 'Failed to update password', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      await authService.updateEmail(newEmail);
      setToast({ message: 'Email update verification sent!', type: 'success' });
      setNewEmail('');
    } catch (err) {
      setToast({ message: 'Failed to update email', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await authService.signOut();
      navigate('/login');
    } catch (err) {
      setToast({ message: 'Failed to log out', type: 'error' });
    }
  };

  const handleCancelEdit = () => {
    // Reset form values to current user values
    setName(currentUser?.name || '');
    setBabyName(currentUser?.babyName || '');
    setBabyAgeYears(currentUser?.babyAge?.years?.toString() || '');
    setBabyAgeMonths(currentUser?.babyAge?.months?.toString() || '');
    setBabyAgeDays(currentUser?.babyAge?.days?.toString() || '');
    setIsEditing(false);
  };

  return (
    <div className="max-w-2xl mx-auto p-4 space-y-6">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="space-y-6"
      >
        {/* Header */}
        <div className="bg-dark-card rounded-xl p-6 border border-gray-800">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="p-3 bg-accent-lavender/20 rounded-full">
                <UserIcon className="h-6 w-6 text-accent-lavender" />
              </div>
              <div>
                <h2 className="text-xl font-bold text-white">{currentUser?.name}</h2>
                <p className="text-gray-400">{currentUser?.email}</p>
              </div>
            </div>
            <button
              onClick={() => setIsEditing(!isEditing)}
              className="p-2 hover:bg-gray-800 rounded-lg transition-colors"
            >
              <PencilIcon className="h-5 w-5 text-gray-400" />
            </button>
          </div>
        </div>

        {/* Baby Info Display when not editing */}
        {!isEditing && currentUser?.babyName && (
          <div className="bg-dark-card rounded-xl p-6 border border-gray-800">
            <div className="flex items-center space-x-3 mb-4">
              <HeartIcon className="h-5 w-5 text-accent-rose" />
              <h3 className="text-lg font-medium text-white">Baby Details</h3>
            </div>
            <div className="space-y-2">
              <p className="text-gray-400">
                Name: <span className="text-white">{currentUser.babyName}</span>
              </p>
              {currentUser.babyAge && (
                <p className="text-gray-400">
                  Age:{' '}
                  <span className="text-white">
                    {currentUser.babyAge.years ? `${currentUser.babyAge.years}y ` : ''}
                    {currentUser.babyAge.months ? `${currentUser.babyAge.months}m ` : ''}
                    {currentUser.babyAge.days ? `${currentUser.babyAge.days}d` : ''}
                  </span>
                </p>
              )}
            </div>
          </div>
        )}

        {/* Profile Form */}
        {isEditing && (
          <motion.form
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            onSubmit={handleUpdateProfile}
            className="bg-dark-card rounded-xl p-6 border border-gray-800 space-y-4"
          >
            <div className="space-y-2">
              <label className="text-sm text-gray-400">Your Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-3 bg-dark-bg text-white border border-gray-800 rounded-lg"
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm text-gray-400">Baby's Name</label>
              <input
                type="text"
                value={babyName}
                onChange={(e) => setBabyName(e.target.value)}
                className="w-full p-3 bg-dark-bg text-white border border-gray-800 rounded-lg"
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm text-gray-400">Baby's Age</label>
              <div className="grid grid-cols-3 gap-2">
                <div className="space-y-1">
                  <input
                    type="number"
                    min="0"
                    value={babyAgeYears}
                    onChange={(e) => setBabyAgeYears(e.target.value)}
                    placeholder="Years"
                    className="w-full p-3 bg-dark-bg text-white border border-gray-800 rounded-lg"
                  />
                  <span className="text-xs text-gray-400">Years</span>
                </div>
                <div className="space-y-1">
                  <input
                    type="number"
                    min="0"
                    max="11"
                    value={babyAgeMonths}
                    onChange={(e) => setBabyAgeMonths(e.target.value)}
                    placeholder="Months"
                    className="w-full p-3 bg-dark-bg text-white border border-gray-800 rounded-lg"
                  />
                  <span className="text-xs text-gray-400">Months</span>
                </div>
                <div className="space-y-1">
                  <input
                    type="number"
                    min="0"
                    max="31"
                    value={babyAgeDays}
                    onChange={(e) => setBabyAgeDays(e.target.value)}
                    placeholder="Days"
                    className="w-full p-3 bg-dark-bg text-white border border-gray-800 rounded-lg"
                  />
                  <span className="text-xs text-gray-400">Days</span>
                </div>
              </div>
            </div>

            <div className="flex space-x-3">
              <button
                type="submit"
                disabled={loading}
                className="flex-1 bg-accent-lavender/20 text-accent-lavender p-3 rounded-lg border border-accent-lavender/30
                         hover:bg-accent-lavender/30 transition-colors disabled:opacity-50"
              >
                {loading ? 'Updating...' : 'Update Profile'}
              </button>
              <button
                type="button"
                onClick={handleCancelEdit}
                className="flex-1 bg-gray-800/50 text-gray-400 p-3 rounded-lg border border-gray-700
                         hover:bg-gray-800 transition-colors"
              >
                Cancel
              </button>
            </div>
          </motion.form>
        )}

        {/* Security Section */}
        <div className="bg-dark-card rounded-xl p-6 border border-gray-800 space-y-6">
          <h3 className="text-lg font-medium text-white">Security Settings</h3>

          {/* Change Password */}
          <form onSubmit={handleUpdatePassword} className="space-y-4">
            <div className="flex items-center space-x-3 text-gray-400">
              <KeyIcon className="h-5 w-5" />
              <h4>Change Password</h4>
            </div>
            <div className="space-y-2">
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
                className="w-full p-3 bg-dark-bg text-white border border-gray-800 rounded-lg"
              />
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm New Password"
                className="w-full p-3 bg-dark-bg text-white border border-gray-800 rounded-lg"
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full bg-accent-mint/20 text-accent-mint p-3 rounded-lg border border-accent-mint/30
                       hover:bg-accent-mint/30 transition-colors disabled:opacity-50"
            >
              Update Password
            </button>
          </form>

          {/* Change Email */}
          <form onSubmit={handleUpdateEmail} className="space-y-4">
            <div className="flex items-center space-x-3 text-gray-400">
              <EnvelopeIcon className="h-5 w-5" />
              <h4>Change Email</h4>
            </div>
            <input
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="New Email Address"
              className="w-full p-3 bg-dark-bg text-white border border-gray-800 rounded-lg"
            />
            <button
              type="submit"
              disabled={loading}
              className="w-full bg-accent-peach/20 text-accent-peach p-3 rounded-lg border border-accent-peach/30
                       hover:bg-accent-peach/30 transition-colors disabled:opacity-50"
            >
              Update Email
            </button>
          </form>
        </div>

        {/* Logout Button */}
        <button
          onClick={handleLogout}
          className="w-full bg-accent-rose/20 text-accent-rose p-4 rounded-lg border border-accent-rose/30
                   hover:bg-accent-rose/30 transition-colors flex items-center justify-center space-x-2"
        >
          <ArrowRightOnRectangleIcon className="h-5 w-5" />
          <span>Log Out</span>
        </button>
      </motion.div>

      <Toast 
        message={toast?.message || ''} 
        type={toast?.type || 'success'} 
        isVisible={!!toast} 
      />
    </div>
  );
}

export default UserProfile; 