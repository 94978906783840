import React, { useState } from 'react';
import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, PlusIcon } from '@heroicons/react/24/outline';
import { vaccineSchedule } from './UpcomingVaccinations';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export function VaccineSelector({ value, onChange }: Props) {
  const [query, setQuery] = useState('');
  
  const allVaccines = Array.from(new Set(
    vaccineSchedule.flatMap((s) => s.vaccines)
  )).sort();

  const filteredVaccines = query === ''
    ? allVaccines
    : allVaccines.filter((vaccine: string) =>
        vaccine.toLowerCase().includes(query.toLowerCase())
      );

  const handleCustomVaccine = () => {
    onChange(query);
  };

  return (
    <Combobox value={value} onChange={onChange}>
      <div className="relative">
        <div className="relative w-full">
          <Combobox.Input
            className="w-full bg-dark-bg text-white rounded-lg p-2 border border-gray-800 pr-10"
            onChange={(event) => {
              setQuery(event.target.value);
            }}
            displayValue={(vaccine: string) => vaccine}
            placeholder="Select or type vaccine name"
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
        </div>
        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-dark-bg py-1 border border-gray-800 shadow-lg">
          {query && !filteredVaccines.includes(query) && (
            <button
              onClick={handleCustomVaccine}
              className="relative w-full cursor-pointer select-none py-2 px-4 text-left text-gray-400 hover:bg-accent-lavender/20 hover:text-white flex items-center space-x-2"
            >
              <PlusIcon className="h-4 w-4" />
              <span>Add "{query}" as custom vaccine</span>
            </button>
          )}
          {filteredVaccines.map((vaccine: string) => (
            <Combobox.Option
              key={vaccine}
              value={vaccine}
              className={({ active }) =>
                `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                  active ? 'bg-accent-lavender/20 text-white' : 'text-gray-400'
                }`
              }
            >
              {({ selected, active }) => (
                <>
                  <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                    {vaccine}
                  </span>
                  {selected ? (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-accent-mint">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
