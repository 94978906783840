import React from 'react';
import { TrackingEntry } from '../../types/tracking';
import { motion } from 'framer-motion';
import { 
  ClockIcon, 
  TrashIcon, 
  PencilIcon,
  MoonIcon,
  HeartIcon,
  ClipboardIcon
} from '@heroicons/react/24/outline';

interface Props {
  entries: TrackingEntry[];
  activeTab: 'feeding' | 'sleep' | 'diaper';
  onDelete: (entry: TrackingEntry) => void;
  onEdit: (entry: TrackingEntry) => void;
  onEntriesUpdate: () => void;
}

export function EntriesSection({ entries, activeTab, onDelete, onEdit }: Props) {
  // Group entries by date, ensuring we handle timestamps correctly
  const groupedEntries = entries.reduce((groups, entry) => {
    // Ensure we have a valid date by multiplying by 1000 if needed
    const timestamp = entry.timestamp.toString().length <= 10 
      ? entry.timestamp * 1000 
      : entry.timestamp;
    
    const date = new Date(timestamp).toLocaleDateString();
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push({
      ...entry,
      timestamp: timestamp // Update the timestamp in the entry as well
    });
    return groups;
  }, {} as Record<string, TrackingEntry[]>);

  // Sort dates in descending order
  const sortedDates = Object.keys(groupedEntries).sort((a, b) => 
    new Date(b).getTime() - new Date(a).getTime()
  );

  const getEntryIcon = () => {
    switch (activeTab) {
      case 'feeding': return HeartIcon;
      case 'sleep': return MoonIcon;
      case 'diaper': return ClipboardIcon;
    }
  };

  const formatDuration = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    }
    if (minutes > 0) {
      return `${minutes}m ${remainingSeconds > 0 ? `${remainingSeconds}s` : ''}`;
    }
    return `${seconds}s`;
  };

  const getEntryColor = () => {
    switch (activeTab) {
      case 'feeding': return 'text-accent-peach';
      case 'sleep': return 'text-accent-mint';
      case 'diaper': return 'text-accent-rose';
    }
  };

  const formatEntryTime = (timestamp: number) => {
    // Ensure we have a valid timestamp by multiplying by 1000 if needed
    const adjustedTimestamp = timestamp.toString().length <= 10 
      ? timestamp * 1000 
      : timestamp;

    return new Date(adjustedTimestamp).toLocaleTimeString([], { 
      hour: '2-digit', 
      minute: '2-digit',
      hour12: true 
    });
  };

  const formatEntryDate = (date: string) => {
    const today = new Date().toLocaleDateString();
    const yesterday = new Date(Date.now() - 86400000).toLocaleDateString();

    if (date === today) {
      return 'Today';
    } else if (date === yesterday) {
      return 'Yesterday';
    }

    return new Date(date).toLocaleDateString(undefined, { 
      weekday: 'short', 
      month: 'short', 
      day: 'numeric' 
    });
  };

  const formatEntryType = (entry: TrackingEntry) => {
    if (entry.feedingType) {
      switch (entry.feedingType) {
        case 'left_breast': return 'Left Breast';
        case 'right_breast': return 'Right Breast';
        case 'bottle': return 'Bottle';
        case 'solid': return 'Solid Food';
        default: return entry.feedingType;
      }
    }
    if (entry.sleepType) {
      switch (entry.sleepType) {
        case 'nap': return 'Nap';
        case 'night': return 'Night Sleep';
        default: return entry.sleepType;
      }
    }
    if (entry.diaperType) {
      switch (entry.diaperType) {
        case 'wet': return 'Wet';
        case 'dirty': return 'Dirty';
        case 'both': return 'Wet & Dirty';
        default: return entry.diaperType;
      }
    }
    return '';
  };

  const EntryIcon = getEntryIcon();
  const entryColor = getEntryColor();

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium text-white">Recent Entries</h3>
      
      {sortedDates.map(date => (
        <motion.div
          key={date}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-2"
        >
          <div className="flex items-center space-x-2">
            <div className="h-px flex-grow bg-gray-800" />
            <p className="text-sm text-gray-400">
              {formatEntryDate(date)}
            </p>
            <div className="h-px flex-grow bg-gray-800" />
          </div>

          {groupedEntries[date].sort((a, b) => b.timestamp - a.timestamp).map(entry => (
            <motion.div
              key={entry.id}
              layout
              className="bg-dark-bg rounded-lg p-3 border border-gray-800"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className={`p-2 rounded-lg ${entryColor.replace('text-', 'bg-')}/10`}>
                    <EntryIcon className={`h-5 w-5 ${entryColor}`} />
                  </div>
                  <div>
                    <div className="flex items-center space-x-2">
                      <p className="text-white">
                        {formatEntryTime(entry.timestamp)}
                      </p>
                      <span className={`text-sm ${entryColor}`}>
                        {formatEntryType(entry)}
                      </span>
                    </div>
                    {entry.duration && (
                      <div className="flex items-center text-sm text-gray-400">
                        <ClockIcon className="h-4 w-4 mr-1" />
                        {formatDuration(entry.duration)}
                      </div>
                    )}
                    {entry.notes && (
                      <p className="text-sm text-gray-400 mt-1">{entry.notes}</p>
                    )}
                  </div>
                </div>

                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => onEdit(entry)}
                    className="p-1 text-gray-400 hover:text-white rounded-lg 
                             hover:bg-gray-800 transition-colors"
                  >
                    <PencilIcon className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => onDelete(entry)}
                    className="p-1 text-gray-400 hover:text-accent-rose rounded-lg 
                             hover:bg-accent-rose/10 transition-colors"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      ))}

      {sortedDates.length === 0 && (
        <div className="text-center py-8 text-gray-400">
          No entries yet
        </div>
      )}
    </div>
  );
}

// Add an empty export to make it a module
export {}; 