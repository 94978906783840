import React from 'react';
import { motion } from 'framer-motion';
import { ClockIcon, MoonIcon, HeartIcon, PlusIcon } from '@heroicons/react/24/outline';

export function TrackingFormPreview() {
  return (
    <div className="space-y-4">
      {/* Header */}
      <div className="bg-dark-card rounded-lg p-4 border border-gray-800">
        <h2 className="text-lg font-bold text-accent-lavender">
          Emma's Tracking
        </h2>
        <p className="text-sm text-gray-400 mt-1">
          Record daily activities
        </p>
        <div className="mt-1 text-sm text-accent-mint">
          Age: 3m 15d
        </div>
      </div>

      {/* Quick Action Buttons */}
      <div className="grid grid-cols-3 gap-3">
        {[
          { icon: HeartIcon, label: 'Feeding', color: 'peach', active: true },
          { icon: MoonIcon, label: 'Sleep', color: 'mint' },
          { icon: ClockIcon, label: 'Diaper', color: 'rose' }
        ].map((item, index) => (
          <motion.button
            key={index}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`p-3 rounded-lg ${item.active 
              ? `bg-accent-${item.color}/20 border-accent-${item.color}` 
              : 'bg-dark-bg border-gray-800'} 
              border flex flex-col items-center space-y-1.5`}
          >
            <item.icon className={`h-5 w-5 ${item.active ? `text-accent-${item.color}` : 'text-gray-400'}`} />
            <span className={`text-xs ${item.active ? 'text-white' : 'text-gray-400'}`}>{item.label}</span>
          </motion.button>
        ))}
      </div>

      {/* Timer Section */}
      <div className="bg-dark-bg/50 rounded-xl p-4 border border-accent-mint/20">
        <div className="text-center space-y-2">
          <div className="text-3xl font-bold text-accent-mint">
            00:15:30
          </div>
          <p className="text-xs text-gray-400">Active Feeding Timer</p>
          <button className="w-full p-2.5 rounded-lg bg-accent-lavender/20 text-accent-lavender 
                         border border-accent-lavender/30 text-sm font-medium mt-2">
            Stop Timer
          </button>
        </div>
      </div>

      {/* Add New Entry Button */}
      <motion.button 
        whileHover={{ scale: 1.02 }}
        className="w-full bg-accent-lavender/20 text-accent-lavender p-3 rounded-lg 
                 border border-accent-lavender/30 flex items-center justify-center space-x-2"
      >
        <PlusIcon className="h-4 w-4" />
        <span className="text-sm">Add New Entry</span>
      </motion.button>

      {/* Recent Entries */}
      <div className="space-y-2">
        <h3 className="text-sm font-medium text-gray-300">Recent Entries</h3>
        <div className="bg-dark-bg rounded-lg p-3 border border-gray-800">
          <div className="flex justify-between items-start">
            <div>
              <div className="flex items-center space-x-2">
                <HeartIcon className="h-4 w-4 text-accent-peach" />
                <span className="text-sm text-accent-peach">Left Breast</span>
              </div>
              <p className="text-xs text-gray-400 mt-1">18 minutes</p>
            </div>
            <span className="text-xs text-gray-500">20 mins ago</span>
          </div>
        </div>
        <div className="bg-dark-bg rounded-lg p-3 border border-gray-800">
          <div className="flex justify-between items-start">
            <div>
              <div className="flex items-center space-x-2">
                <MoonIcon className="h-4 w-4 text-accent-mint" />
                <span className="text-sm text-accent-mint">Nap</span>
              </div>
              <p className="text-xs text-gray-400 mt-1">2h 30m</p>
            </div>
            <span className="text-xs text-gray-500">1h ago</span>
          </div>
        </div>
      </div>

      {/* Daily Summary */}
      <div className="bg-dark-bg rounded-lg p-3 border border-gray-800">
        <h3 className="text-sm font-medium text-gray-300 mb-2">Today's Summary</h3>
        <div className="grid grid-cols-3 gap-2">
          <div className="text-center">
            <p className="text-lg font-bold text-accent-peach">8</p>
            <p className="text-[10px] text-gray-400">Feedings</p>
          </div>
          <div className="text-center">
            <p className="text-lg font-bold text-accent-mint">12h</p>
            <p className="text-[10px] text-gray-400">Sleep</p>
          </div>
          <div className="text-center">
            <p className="text-lg font-bold text-accent-rose">6</p>
            <p className="text-[10px] text-gray-400">Changes</p>
          </div>
        </div>
      </div>
    </div>
  );
} 