import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  XMarkIcon, 
  SpeakerWaveIcon, 
  SpeakerXMarkIcon,
  UserCircleIcon,
  PlayIcon 
} from '@heroicons/react/24/outline';
import { LearningVideo } from '../../types/learning';

interface Props {
  video?: LearningVideo | null;
  onClose?: () => void;
}

export default function VideoPlayer({ video }: Props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  // Placeholder video data - replace with actual data from your backend
  const defaultVideo = {
    id: 'daily-1',
    title: "Today's Parenting Tip",
    description: "Learn about essential baby care techniques and tips for new mothers.",
    videoUrl: "https://example.com/placeholder-video.mp4",
    thumbnailUrl: "/default-thumbnail.jpg",
    date: Date.now(),
    creator: {
      name: "Dr. Sarah Johnson",
      profession: "Pediatric Specialist",
      avatar: "/default-avatar.jpg"
    },
    expert: {
      name: "Dr. Sarah Johnson",
      title: "Pediatric Specialist",
      avatarUrl: "/default-avatar.jpg"
    },
    duration: 90,
    isActive: true,
    uploadedAt: Date.now()
  };

  const currentVideo = video || defaultVideo;

  return (
    <div className="bg-dark-card rounded-lg overflow-hidden border border-gray-800">
      {/* Video Container */}
      <div className="relative aspect-[9/16] max-w-sm mx-auto bg-dark-bg">
        {!isPlaying ? (
          <div className="relative w-full h-full cursor-pointer" onClick={() => setIsPlaying(true)}>
            {/* Thumbnail with gradient overlay */}
            <img
              src={currentVideo.thumbnailUrl}
              alt={currentVideo.title}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent">
              {/* Play button */}
              <div className="absolute inset-0 flex items-center justify-center">
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="bg-accent-lavender/90 p-4 rounded-full"
                >
                  <PlayIcon className="h-8 w-8 text-white" />
                </motion.div>
              </div>

              {/* Video Info Overlay */}
              <div className="absolute bottom-0 left-0 right-0 p-4">
                <div className="space-y-2">
                  <h3 className="text-lg font-bold text-white leading-tight">
                    {currentVideo.title}
                  </h3>
                  <div className="flex items-center justify-between text-xs text-gray-300">
                    <span>{currentVideo.expert.name}</span>
                    <span>{currentVideo.expert.title}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="relative w-full h-full">
            <video
              src={currentVideo.videoUrl}
              className="w-full h-full object-cover"
              autoPlay
              playsInline
              loop
              muted={isMuted}
              controls
            />
            {/* Volume control */}
            <button
              onClick={() => setIsMuted(!isMuted)}
              className="absolute top-4 right-4 p-2 bg-black/40 rounded-full z-10"
            >
              {isMuted ? (
                <SpeakerXMarkIcon className="h-6 w-6 text-white" />
              ) : (
                <SpeakerWaveIcon className="h-6 w-6 text-white" />
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
} 