import React from 'react';
import { motion } from 'framer-motion';
import { StarIcon } from '@heroicons/react/24/outline';

export function VictoriesPreview() {
  return (
    <div className="space-y-4">
      <div className="bg-gradient-to-br from-accent-gold/20 to-accent-rose/20 rounded-xl p-4">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-accent-gold/20 rounded-lg">
            <StarIcon className="h-5 w-5 text-accent-gold" />
          </div>
          <div>
            <h3 className="text-base font-medium text-white">Today's Victories</h3>
            <p className="text-sm text-gray-400">4 achievements unlocked!</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-2">
        <div className="bg-dark-bg p-2 rounded-lg text-center">
          <p className="text-xl font-bold text-accent-mint">8</p>
          <p className="text-[10px] text-gray-400">Feedings</p>
        </div>
        <div className="bg-dark-bg p-2 rounded-lg text-center">
          <p className="text-xl font-bold text-accent-rose">14h</p>
          <p className="text-[10px] text-gray-400">Sleep</p>
        </div>
        <div className="bg-dark-bg p-2 rounded-lg text-center">
          <p className="text-xl font-bold text-accent-peach">6</p>
          <p className="text-[10px] text-gray-400">Changes</p>
        </div>
      </div>

      <div className="space-y-2">
        {[
          { 
            title: 'Sleep Champion', 
            desc: 'Baby slept 6 hours straight!', 
            icon: '🌙',
            color: 'mint'
          },
          { 
            title: 'Feeding Expert', 
            desc: 'Maintained consistent 3-hour feeding schedule', 
            icon: '💝',
            color: 'rose'
          },
          { 
            title: '7-Day Streak', 
            desc: 'A week of dedicated tracking', 
            icon: '🎯',
            color: 'gold'
          },
          { 
            title: 'Super Mom', 
            desc: 'Completed 20+ care activities today', 
            icon: '✨',
            color: 'lavender'
          }
        ].map((achievement, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className={`bg-dark-bg p-3 rounded-lg border border-accent-${achievement.color}/20`}
          >
            <div className="flex items-center space-x-3">
              <div className="text-xl">{achievement.icon}</div>
              <div>
                <h4 className={`text-sm font-medium text-accent-${achievement.color}`}>
                  {achievement.title}
                </h4>
                <p className="text-xs text-gray-400">{achievement.desc}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      <div className="bg-dark-bg p-3 rounded-lg border border-gray-800">
        <div className="flex justify-between items-center mb-2">
          <h4 className="text-sm text-gray-300">Weekly Progress</h4>
          <span className="text-xs text-accent-gold">85% Complete</span>
        </div>
        <div className="h-2 bg-dark-card rounded-full overflow-hidden">
          <div className="h-full w-[85%] bg-gradient-to-r from-accent-gold/60 to-accent-gold rounded-full" />
        </div>
      </div>
    </div>
  );
} 