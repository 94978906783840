import React from 'react';
import { motion } from 'framer-motion';
import { HeartIcon, CalendarIcon, BeakerIcon } from '@heroicons/react/24/outline';

export function HealthFormPreview() {
  return (
    <div className="space-y-4">
      {/* Header */}
      <div className="bg-dark-card rounded-lg p-4 border border-gray-800">
        <h2 className="text-lg font-bold text-accent-rose">
          Emma's Health
        </h2>
        <p className="text-sm text-gray-400 mt-1">
          Track health & appointments
        </p>
      </div>

      {/* Tab Buttons - Simplified */}
      <div className="grid grid-cols-3 gap-2">
        <button className="p-3 rounded-lg bg-dark-bg border border-gray-800 flex flex-col items-center space-y-1.5">
          <BeakerIcon className="h-5 w-5 text-gray-400" />
          <span className="text-xs text-gray-400">Vaccinations</span>
        </button>
        <button className="p-3 rounded-lg bg-accent-mint/10 border border-gray-800 flex flex-col items-center space-y-1.5">
          <CalendarIcon className="h-5 w-5 text-accent-mint" />
          <span className="text-xs text-accent-mint">Appointments</span>
        </button>
        <button className="p-3 rounded-lg bg-dark-bg border border-gray-800 flex flex-col items-center space-y-1.5">
          <HeartIcon className="h-5 w-5 text-gray-400" />
          <span className="text-xs text-gray-400">Concerns</span>
        </button>
      </div>

      {/* Appointments List */}
      <div className="bg-dark-bg rounded-lg p-4 border border-gray-800">
        <div className="flex justify-between items-center mb-3">
          <h3 className="text-sm font-medium text-white">Upcoming</h3>
          <button className="text-xs text-accent-mint bg-accent-mint/10 px-3 py-1 rounded-lg">
            Add New
          </button>
        </div>

        <div className="space-y-3">
          {[
            {
              title: '4-Month Checkup',
              doctor: 'Dr. Smith Pediatrics',
              date: 'Feb 21',
              time: '10:30 AM',
              type: 'checkup'
            },
            {
              title: 'DTaP Vaccination',
              doctor: 'City Children\'s Hospital',
              date: 'Mar 15',
              time: '2:00 PM',
              type: 'vaccination'
            }
          ].map((apt, index) => (
            <div key={index} className="bg-dark-card p-3 rounded-lg border border-gray-800">
              <div className="flex items-start justify-between">
                <div className="flex items-start space-x-2">
                  <div className="mt-0.5">
                    {apt.type === 'checkup' ? 
                      <CalendarIcon className="h-4 w-4 text-accent-mint" /> :
                      <BeakerIcon className="h-4 w-4 text-accent-peach" />
                    }
                  </div>
                  <div>
                    <h4 className={`text-sm font-medium ${
                      apt.type === 'checkup' ? 'text-accent-mint' : 'text-accent-peach'
                    }`}>
                      {apt.title}
                    </h4>
                    <p className="text-xs text-gray-400 mt-0.5">{apt.doctor}</p>
                    <div className="flex items-center space-x-2 mt-1">
                      <span className="text-[10px] text-gray-500">{apt.date}</span>
                      <span className={`text-[10px] ${
                        apt.type === 'checkup' ? 'text-accent-mint' : 'text-accent-peach'
                      }`}>
                        {apt.time}
                      </span>
                    </div>
                  </div>
                </div>
                <button className="text-[10px] px-2 py-1 rounded-full bg-accent-mint/10 text-accent-mint">
                  📅 Add
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Reminders */}
      <div className="bg-dark-bg rounded-lg p-4 border border-gray-800">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-sm font-medium text-white">Reminders</h3>
          <span className="text-xs text-accent-mint">2 active</span>
        </div>
        <div className="space-y-2">
          <div className="bg-dark-card p-2 rounded-lg border border-gray-800 flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className="w-1.5 h-1.5 rounded-full bg-accent-mint" />
              <span className="text-sm text-gray-400">Checkup reminder</span>
            </div>
            <span className="text-xs text-accent-mint">1 day before</span>
          </div>
          <div className="bg-dark-card p-2 rounded-lg border border-gray-800 flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <div className="w-1.5 h-1.5 rounded-full bg-accent-peach" />
              <span className="text-sm text-gray-400">Vaccination prep</span>
            </div>
            <span className="text-xs text-accent-peach">2 days before</span>
          </div>
        </div>
      </div>
    </div>
  );
} 