import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  PlayCircleIcon, 
  NewspaperIcon, 
  ChatBubbleBottomCenterTextIcon,
  ExclamationTriangleIcon 
} from '@heroicons/react/24/outline';
import { TabButton } from '../common/TabButton/TabButton';
import { TabTransition } from '../wellness/TabTransition';
import { learningService } from '../../services/learning';
import { LearningVideo, Article } from '../../types/learning';
import VideoPlayer from './VideoPlayer';
import ArticleLinks from './ArticleLinks';
import AIChatSection from './AIChatSection';

type LearningTab = 'video' | 'articles' | 'ai-chat';

function Learning() {
  const [activeTab, setActiveTab] = useState<LearningTab>('video');
  const [currentVideo, setCurrentVideo] = useState<LearningVideo | null>(null);
  const [articles, setArticles] = useState<Article[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const loadContent = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const [video, articlesList] = await Promise.all([
        learningService.getLatestVideo(),
        learningService.getActiveArticles()
      ]);
      setCurrentVideo(video);
      setArticles(articlesList);
    } catch (error) {
      console.error('Error loading learning content:', error);
      setError('Failed to load content. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  // Initial load
  useEffect(() => {
    loadContent();
  }, []);

  // Refresh content every 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      loadContent();
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const renderDisclaimer = (tab: LearningTab) => {
    let message = '';
    switch (tab) {
      case 'video':
        message = 'The videos provided are for informational purposes only. Always consult with your healthcare provider for medical advice specific to your situation.';
        break;
      case 'articles':
        message = 'Articles shared here are for educational purposes. Information provided should not replace professional medical advice. Consult your healthcare provider before making any health-related decisions.';
        break;
      case 'ai-chat':
        message = 'AI responses are generated automatically and should not be considered medical advice. Always verify information with qualified healthcare professionals.';
        break;
    }

    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-accent-rose/10 border border-accent-rose/20 rounded-lg p-4 mb-4"
      >
        <div className="flex items-start space-x-3">
          <ExclamationTriangleIcon className="h-5 w-5 text-accent-rose flex-shrink-0 mt-0.5" />
          <div>
            <h4 className="text-sm font-medium text-accent-rose mb-1">Disclaimer</h4>
            <p className="text-sm text-gray-400">{message}</p>
          </div>
        </div>
      </motion.div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-pulse text-accent-lavender">Loading content...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-accent-rose">{error}</div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Header */}
      <div className="bg-dark-card rounded-lg p-6 border border-gray-800">
        <h2 className="text-2xl font-bold text-accent-lavender">
          Learning Hub
        </h2>
        <p className="text-gray-400 mt-2">
          Discover daily insights, tips, and expert advice for your motherhood journey
        </p>
      </div>

      {/* Navigation Tabs */}
      <div className="grid grid-cols-3 gap-3">
        <TabButton
          icon={<PlayCircleIcon className="h-6 w-6" />}
          label="Daily Video"
          isActive={activeTab === 'video'}
          onClick={() => setActiveTab('video')}
          iconColor="text-accent-mint"
        />
        <TabButton
          icon={<NewspaperIcon className="h-6 w-6" />}
          label="Articles"
          isActive={activeTab === 'articles'}
          onClick={() => setActiveTab('articles')}
          iconColor="text-accent-peach"
        />
        <TabButton
          icon={<ChatBubbleBottomCenterTextIcon className="h-6 w-6" />}
          label="Ask AI"
          isActive={activeTab === 'ai-chat'}
          onClick={() => setActiveTab('ai-chat')}
          iconColor="text-accent-lavender"
        />
      </div>

      {/* Content Section */}
      <TabTransition tab={activeTab}>
        <div className="space-y-4">
          {/* Disclaimer */}
          {renderDisclaimer(activeTab)}

          {activeTab === 'video' && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <VideoPlayer video={currentVideo} />
            </motion.div>
          )}

          {activeTab === 'articles' && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <ArticleLinks articles={articles} />
            </motion.div>
          )}

          {activeTab === 'ai-chat' && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <AIChatSection />
            </motion.div>
          )}
        </div>
      </TabTransition>
    </div>
  );
}

export default Learning; 