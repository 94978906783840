import React from 'react';
import { HealthEntry } from '../../types/health';

interface VaccineSchedule {
  age: string;
  months: number;
  vaccines: string[];
}

export const vaccineSchedule: VaccineSchedule[] = [
  { age: "Birth", months: 0, vaccines: ["BCG", "Hepatitis B (1st dose)"] },
  { age: "2 months", months: 2, vaccines: ["DTaP (1st)", "IPV (1st)", "Hib (1st)", "PCV13 (1st)", "Rotavirus (1st)"] },
  { age: "4 months", months: 4, vaccines: ["DTaP (2nd)", "IPV (2nd)", "Hib (2nd)", "PCV13 (2nd)", "Rotavirus (2nd)"] },
  { age: "6 months", months: 6, vaccines: ["DTaP (3rd)", "IPV (3rd)", "Hib (3rd)", "PCV13 (3rd)", "Rotavirus (3rd)", "Influenza (1st)"] },
  { age: "9 months", months: 9, vaccines: ["Hepatitis B (2nd dose)", "MMR (1st)"] },
  { age: "12 months", months: 12, vaccines: ["Varicella", "Hepatitis A (1st)", "PCV13 (4th)"] },
  { age: "15 months", months: 15, vaccines: ["DTaP (4th)", "Hib (4th)", "MMR (2nd)"] },
  { age: "18 months", months: 18, vaccines: ["Hepatitis A (2nd)", "Varicella (2nd)"] },
  { age: "4-6 years", months: 48, vaccines: ["DTaP (5th)", "IPV (4th)", "MMR (3rd)", "Varicella (3rd)"] },
];

export function UpcomingVaccinations({ entries }: { entries: HealthEntry[] }) {
  const completedVaccines = entries.map(entry => entry.title.toLowerCase());
  const totalVaccines = vaccineSchedule.reduce((acc, schedule) => acc + schedule.vaccines.length, 0);
  const completedCount = completedVaccines.length;
  const progressPercentage = (completedCount / totalVaccines) * 100;

  const schedules = [...vaccineSchedule].sort((a, b) => {
    const aCompleted = a.vaccines.filter(v => completedVaccines.includes(v.toLowerCase())).length;
    const bCompleted = b.vaccines.filter(v => completedVaccines.includes(v.toLowerCase())).length;
    return (aCompleted / a.vaccines.length) - (bCompleted / b.vaccines.length);
  });

  return (
    <div className="space-y-4">
      <div className="bg-dark-bg/50 rounded-lg p-4 border border-gray-800">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-accent-peach font-medium">Vaccination Progress</h3>
          <span className="text-sm text-gray-400">{completedCount} of {totalVaccines}</span>
        </div>
        <div className="w-full h-2 bg-gray-800 rounded-full overflow-hidden">
          <div 
            className="h-full bg-accent-mint transition-all duration-500"
            style={{ width: `${progressPercentage}%` }}
          />
        </div>
      </div>

      <div className="bg-dark-bg/50 rounded-lg p-4 border border-gray-800">
        <h3 className="text-accent-peach font-medium mb-3">Recommended Vaccine Schedule</h3>
        <div className="space-y-4">
          {schedules.map((schedule, index) => (
            <div key={index} className="border-b border-gray-800 last:border-0 pb-3 last:pb-0">
              <div className="flex justify-between items-center mb-2">
                <h4 className="text-sm font-medium text-accent-lavender">{schedule.age}</h4>
                <span className="text-xs text-gray-400">
                  {schedule.vaccines.filter(v => completedVaccines.includes(v.toLowerCase())).length} of {schedule.vaccines.length}
                </span>
              </div>
              <ul className="space-y-1">
                {schedule.vaccines.map((vaccine, vIndex) => (
                  <li key={vIndex} className="flex items-center text-sm">
                    <span className={`w-4 h-4 rounded-full mr-2 flex items-center justify-center ${
                      completedVaccines.includes(vaccine.toLowerCase())
                        ? 'bg-accent-mint/20 text-accent-mint'
                        : 'bg-gray-800'
                    }`}>
                      {completedVaccines.includes(vaccine.toLowerCase()) && '✓'}
                    </span>
                    {vaccine}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
