import { useState } from 'react';
import { motion } from 'framer-motion';
import { PencilIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { HealthEntry, HealthType } from '../../types/health';
import { Dialog } from '@headlessui/react';

interface Props {
  entries: HealthEntry[];
  activeTab: HealthType;
  onEdit: (entry: HealthEntry) => void;
  onDelete: (entry: HealthEntry) => void;
  onEntriesUpdate: (entry: HealthEntry) => Promise<void>;
  setToast: (toast: { message: string; type: 'success' | 'error' } | null) => void;
}

interface DateGroup {
  date: string;
  label: string;
  entries: HealthEntry[];
}

export function HealthEntriesSection({ entries, activeTab, onEdit, onDelete, onEntriesUpdate, setToast }: Props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [sortBy, setSortBy] = useState<'date' | 'severity'>('date');
  const [isDeleting, setIsDeleting] = useState(false);

  const getSeverityColor = (severity?: 'low' | 'medium' | 'high') => {
    switch (severity) {
      case 'high':
        return 'border-red-500/30 bg-red-500/5';
      case 'medium':
        return 'border-yellow-500/30 bg-yellow-500/5';
      case 'low':
        return 'border-blue-500/30 bg-blue-500/5';
      default:
        return 'border-gray-800 bg-dark-card';
    }
  };

  const getFilteredEntries = () => {
    let filtered = Array.from(new Map(entries.map(entry => [entry.id, entry])).values());
    
    if (searchTerm) {
      filtered = filtered.filter(entry => 
        entry.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        entry.symptoms?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        entry.notes?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (activeTab === 'concerns') {
      return filtered.sort((a, b) => {
        if (sortBy === 'severity') {
          const severityOrder: Record<string, number> = { high: 0, medium: 1, low: 2 };
          const aSeverity = (a.severity || 'low') as keyof typeof severityOrder;
          const bSeverity = (b.severity || 'low') as keyof typeof severityOrder;
          return severityOrder[aSeverity] - severityOrder[bSeverity];
        }
        return b.timestamp - a.timestamp;
      });
    }

    return filtered.sort((a, b) => b.timestamp - a.timestamp);
  };

  const handleDelete = async (entry: HealthEntry) => {
    try {
      setIsDeleting(true);
      await onDelete(entry);
    } catch (error) {
      console.error('Error deleting entry:', error);
      if ((error as Error).message === 'User not authenticated') {
        setToast({ message: 'Please sign in to delete entries', type: 'error' });
      } else {
        setToast({ message: 'Failed to delete entry', type: 'error' });
      }
    } finally {
      setIsDeleting(false);
    }
  };

  const groupEntriesByDate = (): DateGroup[] => {
    const grouped: { [key: string]: HealthEntry[] } = {};
    const filteredEntries = getFilteredEntries();

    filteredEntries.forEach(entry => {
      const date = new Date(entry.date || entry.timestamp);
      const dateKey = date.toISOString().split('T')[0];
      
      if (!grouped[dateKey]) {
        grouped[dateKey] = [];
      }
      grouped[dateKey].push(entry);
    });

    return Object.entries(grouped).map(([date, entries]) => {
      const today = new Date().toISOString().split('T')[0];
      const yesterday = new Date(Date.now() - 86400000).toISOString().split('T')[0];
      
      let label = '';
      if (date === today) label = 'Today';
      else if (date === yesterday) label = 'Yesterday';
      else label = new Date(date).toLocaleDateString();

      return { date, label, entries };
    }).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-4">
        <div className="flex-1 relative">
          <MagnifyingGlassIcon className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search entries..."
            className="w-full bg-dark-bg text-white rounded-lg pl-10 pr-4 py-2 border border-gray-800"
          />
        </div>
        {activeTab === 'concerns' && (
          <div className="flex items-center justify-between">
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value as 'date' | 'severity')}
              className="bg-dark-bg text-white rounded-lg px-3 py-2 border border-gray-800"
            >
              <option value="date">Sort by Date</option>
              <option value="severity">Sort by Severity</option>
            </select>
          </div>
        )}
      </div>

      <div className="space-y-6">
        {groupEntriesByDate().map(group => (
          <div key={group.date} className="space-y-2">
            <h3 className="text-sm font-medium text-accent-lavender/70 px-1">
              {group.label}
            </h3>
            
            {group.entries.map(entry => (
              <motion.div
                key={entry.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className={`p-4 rounded-lg border ${
                  entry.resolved 
                    ? 'border-accent-mint/20 bg-accent-mint/5 opacity-75' 
                    : getSeverityColor(entry.severity)
                }`}
              >
                <div className="flex justify-between items-start">
                  <div className="flex-1">
                    <div className="flex items-center space-x-2">
                      <h3 className="font-medium text-white">
                        {entry.title}
                      </h3>
                      {activeTab === 'concerns' && (
                        <span className={`text-xs px-2 py-1 rounded ${
                          entry.severity === 'high' ? 'bg-red-500/20 text-red-500' :
                          entry.severity === 'medium' ? 'bg-yellow-500/20 text-yellow-500' :
                          'bg-blue-500/20 text-blue-500'
                        }`}>
                          {entry.severity?.toUpperCase()}
                        </span>
                      )}
                    </div>
                    <p className="text-sm mt-1 text-gray-400">
                      {entry.symptoms}
                    </p>
                    {entry.notes && (
                      <p className="text-sm mt-2 text-gray-500">
                        {entry.notes}
                      </p>
                    )}
                    <div className="flex space-x-2 ml-4">
                      <button onClick={() => onEdit(entry)} className="p-1 text-gray-400 hover:text-accent-mint">
                        <PencilIcon className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleDelete(entry)}
                        disabled={isDeleting}
                        className="text-red-400 hover:text-red-300 disabled:opacity-50"
                      >
                        {isDeleting ? 'Deleting...' : 'Delete'}
                      </button>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        ))}
      </div>

      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        {/* Delete confirmation dialog similar to EntriesSection */}
      </Dialog>
    </div>
  );
}
