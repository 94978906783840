import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authService } from '../../services/auth';
import { motion } from 'framer-motion';
import { HeartIcon, EyeIcon, EyeSlashIcon, XMarkIcon } from '@heroicons/react/24/outline';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [resetSent, setResetSent] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setError('');
      setLoading(true);
      await authService.login(email, password);
      setTimeout(() => {
        navigate('/app', { replace: true });
      }, 500);
    } catch (err) {
      console.error('Login error:', err);
      setError('Failed to sign in');
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Please enter your email address');
      return;
    }
    try {
      setLoading(true);
      await authService.resetPassword(email);
      setResetSent(true);
      setError('');
    } catch (err) {
      setError('Failed to send reset email');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-dark-bg to-dark-bg/95 p-4 sm:p-6">
      <div className="w-full max-w-md mx-auto relative">
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute -top-4 right-0 p-2 text-gray-400 hover:text-white transition-colors"
          onClick={() => navigate('/')}
        >
          <XMarkIcon className="h-6 w-6" />
        </motion.button>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-6"
        >
          {/* Logo/Header - Made more compact on mobile */}
          <div className="text-center">
            <div className="flex justify-center mb-3">
              <div className="p-2 sm:p-3 bg-accent-lavender/20 rounded-full">
                <HeartIcon className="h-6 w-6 sm:h-8 sm:w-8 text-accent-lavender" />
              </div>
            </div>
            <h2 className="text-2xl sm:text-3xl font-bold text-white">Welcome Back</h2>
            <p className="mt-1 sm:mt-2 text-sm sm:text-base text-gray-400">Sign in to continue your journey</p>
          </div>

          {/* Sign In Form - Adjusted padding for mobile */}
          <div className="bg-dark-card rounded-xl shadow-xl border border-gray-800 p-4 sm:p-8 space-y-4 sm:space-y-6">
            {error && (
              <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-4">
                <p className="text-red-500 text-sm text-center">{error}</p>
              </div>
            )}

            {resetSent && (
              <div className="bg-green-500/10 border border-green-500/20 rounded-lg p-4">
                <p className="text-green-500 text-sm text-center">
                  Password reset email sent! Please check your inbox.
                </p>
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-1.5 sm:space-y-2">
                <label className="text-sm text-gray-400 block">Email Address</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                  className="w-full p-2 sm:p-3 text-sm bg-dark-bg text-white border border-gray-800 rounded-lg focus:outline-none focus:border-accent-lavender/50 transition-colors"
                />
              </div>

              <div className="space-y-1.5 sm:space-y-2">
                <label className="text-sm text-gray-400 block">Password</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    required
                    className="w-full p-2 sm:p-3 text-sm bg-dark-bg text-white border border-gray-800 rounded-lg focus:outline-none focus:border-accent-lavender/50 transition-colors pr-10"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300"
                  >
                    {showPassword ? (
                      <EyeSlashIcon className="h-5 w-5" />
                    ) : (
                      <EyeIcon className="h-5 w-5" />
                    )}
                  </button>
                </div>
              </div>

              <button
                type="button"
                onClick={handleForgotPassword}
                className="text-accent-lavender hover:text-accent-lavender/80 text-sm transition-colors w-full text-left mt-1"
              >
                Forgot password?
              </button>

              <button
                type="submit"
                disabled={loading}
                className="w-full bg-gradient-to-r from-accent-lavender to-accent-rose p-3 sm:p-4 rounded-lg text-white font-medium text-sm sm:text-base
                         hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed mt-2"
              >
                {loading ? (
                  <div className="flex items-center justify-center space-x-2">
                    <div className="w-5 h-5 border-2 border-white/20 border-t-white rounded-full animate-spin" />
                    <span>Signing in...</span>
                  </div>
                ) : (
                  'Sign In'
                )}
              </button>
            </form>

            <div className="text-center pt-2">
              <Link 
                to="/signup" 
                className="text-accent-lavender hover:text-accent-lavender/80 text-sm transition-colors"
              >
                Need an account? Sign up
              </Link>
            </div>
          </div>

          {/* Footer */}
          <p className="text-center text-gray-500 text-xs sm:text-sm px-4">
            Nurturing every step of your motherhood journey
          </p>
        </motion.div>
      </div>
    </div>
  );
}

export default Login;