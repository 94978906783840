import React from 'react';
import { motion } from 'framer-motion';
import { WeeklyStats } from '../../types/victory';
import { ChartBarIcon, ArrowTrendingUpIcon, ShareIcon } from '@heroicons/react/24/outline';
import ReactDOM from 'react-dom';
import { toPng } from 'html-to-image';
import { ShareableImage } from './ShareableImage';

interface Props {
  weeklyStats: WeeklyStats;
  setToast?: (toast: { message: string; type: 'success' | 'error' } | null) => void;
}

export const WeeklyVictories = ({ weeklyStats, setToast }: Props) => {
  const handleShare = async () => {
    try {
      const portal = document.createElement('div');
      portal.style.position = 'absolute';
      portal.style.left = '-9999px';
      document.body.appendChild(portal);

      const shareableRef = React.createRef<HTMLDivElement>();
      
      ReactDOM.render(
        <ShareableImage 
          ref={shareableRef} 
          type="weekly" 
          data={{ weekly: weeklyStats }} 
        />,
        portal
      );

      await new Promise(resolve => setTimeout(resolve, 100));

      if (shareableRef.current) {
        const dataUrl = await toPng(shareableRef.current, {
          quality: 0.95,
          pixelRatio: 2
        });

        if (navigator.share) {
          const blob = await (await fetch(dataUrl)).blob();
          const file = new File([blob], 'weekly-progress.png', { type: 'image/png' });
          
          await navigator.share({
            files: [file],
            title: 'My Weekly Progress',
            text: 'Check out my weekly progress in motherhood! 💪'
          });
        } else {
          const link = document.createElement('a');
          link.download = 'weekly-progress.png';
          link.href = dataUrl;
          link.click();
        }

        setToast?.({ message: 'Progress card ready to share!', type: 'success' });
      }
    } catch (error) {
      console.error('Error sharing:', error);
      setToast?.({ message: 'Failed to create shareable card', type: 'error' });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-br from-dark-card/95 via-dark-card/90 to-dark-card/80 rounded-xl p-6 border border-accent-mint/20"
    >
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-xl font-bold text-white flex items-center gap-2">
            <ChartBarIcon className="h-6 w-6 text-accent-mint" />
            This Week's Progress
          </h3>
        </div>
        {/* Add Share Button */}
        <motion.button 
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleShare}
          className="flex items-center gap-2 px-4 py-2 bg-accent-mint/10 rounded-lg 
                   text-accent-mint hover:bg-accent-mint/20 transition-all duration-300"
        >
          <ShareIcon className="h-5 w-5" />
          <span className="text-sm">Share</span>
        </motion.button>
      </div>

      <div className="grid grid-cols-3 gap-4 mb-6">
        <ProgressBox
          title="Feedings"
          value={weeklyStats.totalFeedings}
          improvement={weeklyStats.improvementFromLastWeek?.feedings}
          color="rose"
        />
        <ProgressBox
          title="Sleep Hours"
          value={Math.round(weeklyStats.totalSleep / 3600)}
          improvement={weeklyStats.improvementFromLastWeek?.sleep && 
            Math.round(weeklyStats.improvementFromLastWeek.sleep / 3600)}
          color="lavender"
        />
        <ProgressBox
          title="Changes"
          value={weeklyStats.totalDiapers}
          improvement={weeklyStats.improvementFromLastWeek?.diapers}
          color="mint"
        />
      </div>

      {/* Weekly Insights */}
      <div className="bg-dark-bg/30 rounded-lg p-4">
        <h4 className="text-sm font-medium text-accent-mint mb-3 flex items-center gap-2">
          <ArrowTrendingUpIcon className="h-4 w-4" />
          Weekly Insights
        </h4>
        <div className="space-y-2">
          {generateInsights(weeklyStats).map((insight, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className="flex items-center gap-2"
            >
              <div className="w-2 h-2 rounded-full bg-accent-mint/50" />
              <p className="text-sm text-gray-300">{insight}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

const ProgressBox = ({ 
  title, 
  value, 
  improvement, 
  color 
}: { 
  title: string;
  value: number;
  improvement?: number;
  color: string;
}) => (
  <div className={`bg-dark-bg/50 rounded-lg p-4 border border-accent-${color}/20`}>
    <p className="text-2xl font-bold text-accent-mint mb-1">{value}</p>
    <p className="text-xs text-gray-400">{title}</p>
    {improvement !== undefined && (
      <div className={`text-xs mt-2 ${improvement >= 0 ? 'text-accent-mint' : 'text-accent-rose'}`}>
        {improvement >= 0 ? '+' : ''}{improvement} from last week
      </div>
    )}
  </div>
);

const generateInsights = (stats: WeeklyStats): string[] => {
  const insights: string[] = [];

  // Average calculations
  const avgFeedingsPerDay = Math.round(stats.totalFeedings / 7);
  const avgSleepHoursPerDay = Math.round((stats.totalSleep / 3600) / 7);
  const avgDiapersPerDay = Math.round(stats.totalDiapers / 7);

  insights.push(`Average of ${avgFeedingsPerDay} feedings per day 🍼`);
  insights.push(`${avgSleepHoursPerDay} hours of sleep on average 😴`);
  insights.push(`About ${avgDiapersPerDay} diaper changes daily ✨`);

  // Improvements
  if (stats.improvementFromLastWeek) {
    const { feedings, sleep, diapers } = stats.improvementFromLastWeek;
    
    if (feedings && feedings > 0) {
      insights.push(`Feeding frequency increased by ${feedings} this week! 💪`);
    }
    if (sleep && sleep > 0) {
      insights.push(`${Math.round(sleep / 3600)} more hours of sleep this week! 🌙`);
    }
    if (diapers && diapers > 0) {
      insights.push(`${diapers} more diaper changes - growing strong! 🌟`);
    }
  }

  return insights;
}; 