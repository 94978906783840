export interface VictoryEntry {
  id: string;
  userId: string;
  timestamp: number;
  feedingCount?: number;
  sleepDuration?: number;
  diaperCount?: number;
  customVictories?: string[];
  weeklyStats?: WeeklyStats;
  milestones?: Milestone[];
  shared?: boolean;
}

export interface WeeklyStats {
  totalFeedings: number;
  totalSleep: number;
  totalDiapers: number;
  improvementFromLastWeek?: {
    feedings?: number;
    sleep?: number;
    diapers?: number;
  };
}

export interface Milestone {
  id: string;
  type: MilestoneType;
  title: string;
  description: string;
  value: number;
  achievedAt: number;
  icon: string;
  category: 'bronze' | 'silver' | 'gold' | 'platinum';
  hint?: string;
}

export type MilestoneType = 
  | 'feeding' 
  | 'sleep' 
  | 'diaper' 
  | 'streak'
  | 'nighttime'
  | 'consistency'
  | 'supermom'
  | 'dedication'
  | 'wellness'
  | 'bonding'
  | 'routine'
  | 'growth';

export const MILESTONES = {
  feeding: [
    { 
      threshold: 20, 
      category: 'bronze', 
      title: 'First Steps in Nurturing', 
      icon: '🍼',
      hint: 'Complete your first 20 feedings - you\'re getting into the rhythm!'
    },
    { 
      threshold: 50, 
      category: 'silver', 
      title: 'Feeding Champion', 
      icon: '💝',
      hint: 'Reached 50 feedings - you\'re mastering this!'
    },
    { 
      threshold: 100, 
      category: 'gold', 
      title: 'Nurturing Expert', 
      icon: '👑',
      hint: 'Amazing! 100 feedings completed with love'
    }
  ],
  sleep: [
    { 
      threshold: 20, 
      category: 'bronze', 
      title: 'Sleep Guardian', 
      icon: '😴',
      hint: 'Helped baby get 20 hours of peaceful sleep'
    },
    { 
      threshold: 50, 
      category: 'silver', 
      title: 'Rest Master', 
      icon: '🌙',
      hint: 'Achieved 50 hours of quality sleep time'
    },
    { 
      threshold: 100, 
      category: 'gold', 
      title: 'Dream Keeper', 
      icon: '✨',
      hint: 'Incredible! 100 hours of sweet dreams'
    }
  ],
  diaper: [
    { 
      threshold: 20, 
      category: 'bronze', 
      title: 'Care Champion', 
      icon: '💫',
      hint: 'Your first 20 diaper changes - getting confident!'
    },
    { 
      threshold: 50, 
      category: 'silver', 
      title: 'Quick Change Artist', 
      icon: '🌈',
      hint: 'Mastered 50 diaper changes like a pro'
    },
    { 
      threshold: 100, 
      category: 'gold', 
      title: 'Ultimate Caregiver', 
      icon: '🏆',
      hint: '100 changes! Nothing can surprise you now'
    }
  ],
  streak: [
    { 
      threshold: 7, 
      category: 'bronze', 
      title: 'Week Champion', 
      icon: '📅',
      hint: 'Tracked baby\'s care consistently for a full week'
    },
    { 
      threshold: 30, 
      category: 'silver', 
      title: 'Monthly Master', 
      icon: '🎉',
      hint: 'A whole month of dedicated tracking - amazing!'
    },
    { 
      threshold: 90, 
      category: 'gold', 
      title: 'Dedication Star', 
      icon: '⭐️',
      hint: 'Three months of consistent care tracking - you\'re incredible!'
    }
  ],
  nighttime: [
    { 
      threshold: 5, 
      category: 'bronze', 
      title: 'Night Shift Hero', 
      icon: '🌙',
      hint: 'Handled 5 night feedings in one week'
    },
    { 
      threshold: 15, 
      category: 'silver', 
      title: 'Midnight Champion', 
      icon: '✨',
      hint: 'Mastered 15 peaceful night-time moments'
    },
    { 
      threshold: 30, 
      category: 'gold', 
      title: 'Night Watch Guardian', 
      icon: '💫',
      hint: '30 night feeds - the true definition of dedication!'
    }
  ],
  consistency: [
    { 
      threshold: 3, 
      category: 'bronze', 
      title: 'Routine Builder', 
      icon: '📝',
      hint: '3 days of consistent feeding and sleep times'
    },
    { 
      threshold: 7, 
      category: 'silver', 
      title: 'Schedule Master', 
      icon: '⭐️',
      hint: 'A full week of maintaining baby\'s routine'
    },
    { 
      threshold: 14, 
      category: 'gold', 
      title: 'Rhythm Queen', 
      icon: '👑',
      hint: 'Two weeks of perfect routine - you\'re amazing!'
    }
  ],
  supermom: [
    { 
      threshold: 10, 
      category: 'bronze', 
      title: 'Super Mom Rising', 
      icon: '🦸‍♀️',
      hint: '10 care activities in one day - feeding, sleep, and changes combined'
    },
    { 
      threshold: 20, 
      category: 'silver', 
      title: 'Wonder Mom', 
      icon: '💫',
      hint: '20 total care moments in a day - you\'re incredible!'
    },
    { 
      threshold: 30, 
      category: 'gold', 
      title: 'Legendary Mom', 
      icon: '✨',
      hint: '30 moments of care in one day - true superhero status!'
    }
  ],
  dedication: [
    { 
      threshold: 50, 
      category: 'bronze', 
      title: 'Loving Guardian', 
      icon: '❤️',
      hint: '50 total tracked moments of baby care'
    },
    { 
      threshold: 100, 
      category: 'silver', 
      title: 'Care Master', 
      icon: '💖',
      hint: '100 precious moments tracked with love'
    },
    { 
      threshold: 200, 
      category: 'gold', 
      title: 'Heart of Gold', 
      icon: '💝',
      hint: '200 beautiful moments of motherhood documented'
    }
  ]
};

// Helper function to generate milestone descriptions
export const getMilestoneDescription = (type: MilestoneType, value: number): string => {
  switch (type) {
    case 'feeding':
      return `Completed ${value} loving feedings`;
    case 'sleep':
      return `Achieved ${value} hours of peaceful sleep`;
    case 'diaper':
      return `Mastered ${value} diaper changes`;
    case 'streak':
      return `${value} consecutive days of dedicated care`;
    case 'nighttime':
      return `Conquered ${value} night-time challenges`;
    case 'consistency':
      return `${value} days of perfect routine`;
    case 'supermom':
      return `Unlocked ${value} super mom moments`;
    case 'dedication':
      return `${value} moments of pure love and care`;
    default:
      return `Reached ${value} milestone`;
  }
}; 