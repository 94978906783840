import React from 'react';
import { motion } from 'framer-motion';

interface Props {
  icon: React.ReactNode;
  label: string;
  subLabel?: string;
  isActive: boolean;
  onClick: () => void;
  iconColor: string;
}

export function TabButton({ icon, label, subLabel, isActive, onClick, iconColor }: Props) {
  return (
    <motion.button
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      onClick={onClick}
      className={`p-4 rounded-xl border transition-all duration-300 flex flex-col items-center text-center
                 ${isActive 
                   ? 'bg-dark-card border-gray-800' 
                   : 'bg-dark-bg/50 border-transparent hover:bg-dark-card/50'}`}
    >
      <div className={`p-3 rounded-lg mb-2 ${isActive ? iconColor : 'text-gray-500'}`}>
        {icon}
      </div>
      <span className={`text-sm font-medium ${isActive ? 'text-white' : 'text-gray-400'}`}>
        {label}
      </span>
      {subLabel && (
        <span className="text-xs text-gray-500 mt-1">
          {subLabel}
        </span>
      )}
    </motion.button>
  );
} 