import React from 'react';
import { motion } from 'framer-motion';
import { 
  HeartIcon, 
  ClockIcon, 
  BeakerIcon,
  CalendarIcon
} from '@heroicons/react/24/outline';

export function StatsPreview() {
  return (
    <div className="space-y-2.5">
      {/* Welcome Section */}
      <div className="bg-gradient-to-br from-[#2A2A2A] to-[#1A1A1A] rounded-xl p-3">
        <h2 className="text-2xl font-bold text-white">Welcome Back, Mama! 🌸</h2>
        <p className="text-gray-400 mt-0.5">Here's your daily overview</p>
      </div>

      {/* Quick Stats Grid */}
      <div className="grid grid-cols-2 gap-2.5">
        <div className="bg-[#1A1A1A] rounded-xl p-2.5">
          <div className="flex items-center space-x-2 mb-0.5">
            <div className="bg-[#1A1A1A] p-1.5 rounded-lg">
              <HeartIcon className="h-4 w-4 text-[#C4A484]" />
            </div>
            <span className="text-sm text-gray-400">Last Feeding</span>
          </div>
          <p className="text-lg text-white ml-1">10:37 AM</p>
          <p className="text-xs text-gray-500 ml-1">12/11/2024</p>
        </div>

        <div className="bg-[#1A1A1A] rounded-xl p-2.5">
          <div className="flex items-center space-x-2 mb-0.5">
            <div className="bg-[#1A1A1A] p-1.5 rounded-lg">
              <ClockIcon className="h-4 w-4 text-[#4CAF50]" />
            </div>
            <span className="text-sm text-gray-400">Last Sleep</span>
          </div>
          <p className="text-lg text-white ml-1">33min</p>
          <p className="text-xs text-gray-500 ml-1">02:18 AM</p>
        </div>
      </div>

      {/* Quick Timer - More Compact */}
      <div className="bg-[#1A1A1A] rounded-xl p-3">
        <div className="flex justify-between items-center mb-1">
          <h3 className="text-base text-white">Quick Timer</h3>
          <div className="text-xs text-white bg-[#2A2A2A] px-2 py-1 rounded-lg">
            Feeding ▼
          </div>
        </div>
        <div className="text-center py-2">
          <p className="text-3xl font-bold text-white">00:00:00</p>
          <div className="mt-2">
            <button className="bg-[#4CAF50] text-white rounded-full w-10 h-10 flex items-center justify-center mx-auto">
              <span className="text-lg">▶</span>
            </button>
          </div>
        </div>
      </div>

      {/* Today's Summary - Fixed sizes */}
      <div className="bg-[#1A1A1A] rounded-xl p-2.5">
        <h3 className="text-base text-white mb-1.5">Today's Summary</h3>
        <div className="grid grid-cols-3 gap-2">
          <div className="text-center flex flex-col items-center">
            <HeartIcon className="h-3.5 w-3.5 text-[#C4A484] mb-0.5" />
            <p className="text-base text-white leading-none mb-0.5">6</p>
            <p className="text-[9px] text-gray-400">Feedings</p>
          </div>
          <div className="text-center flex flex-col items-center">
            <ClockIcon className="h-3.5 w-3.5 text-[#E57373] mb-0.5" />
            <p className="text-base text-white leading-none mb-0.5">4</p>
            <p className="text-[9px] text-gray-400">Diapers</p>
          </div>
          <div className="text-center flex flex-col items-center">
            <div className="h-3.5 w-3.5 flex items-center justify-center mb-0.5">
              <span className="text-[14px] leading-none">🌙</span>
            </div>
            <p className="text-base text-white leading-none mb-0.5">8</p>
            <p className="text-[9px] text-gray-400">Sleep</p>
          </div>
        </div>
      </div>

      {/* Upcoming Health Events */}
      <div className="bg-[#1A1A1A] rounded-xl p-3">
        <h3 className="text-base text-white mb-2">Upcoming Health</h3>
        <div className="text-center text-gray-400 py-1">
          No upcoming events
        </div>
      </div>
    </div>
  );
} 