import React from 'react';
import { motion } from 'framer-motion';
import { SparklesIcon } from '@heroicons/react/24/outline';

export function WellnessPreview() {
  return (
    <div className="space-y-4">
      {/* Header */}
      <div className="bg-gradient-to-br from-accent-lavender/20 to-accent-rose/20 rounded-xl p-3 border border-accent-lavender/20">
        <div className="flex items-center space-x-2">
          <div className="p-1.5 bg-accent-lavender/10 rounded-lg">
            <SparklesIcon className="h-5 w-5 text-accent-lavender" />
          </div>
          <div>
            <h3 className="text-base font-medium text-white">Wellness Check-in</h3>
            <p className="text-xs text-gray-400">How are you feeling today?</p>
          </div>
        </div>
      </div>

      {/* Mood Selection */}
      <div className="flex justify-between items-center px-2">
        {[
          { emoji: '😔', label: 'Struggling' },
          { emoji: '😐', label: 'Coping' },
          { emoji: '🙂', label: 'Okay' },
          { emoji: '😊', label: 'Good', active: true },
          { emoji: '🥰', label: 'Amazing' }
        ].map((mood, index) => (
          <motion.button
            key={index}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className={`flex flex-col items-center space-y-1 ${mood.active ? 'scale-110' : ''}`}
          >
            <div className={`w-10 h-10 rounded-full flex items-center justify-center
                          ${mood.active ? 'bg-accent-mint/20 border border-accent-mint/30' : ''}`}>
              <span className="text-xl">{mood.emoji}</span>
            </div>
            <span className={`text-[10px] ${mood.active ? 'text-accent-mint' : 'text-gray-400'}`}>
              {mood.label}
            </span>
          </motion.button>
        ))}
      </div>

      {/* Weekly Overview - Updated to match actual app */}
      <div className="bg-dark-bg rounded-lg p-3 border border-gray-800">
        <div className="flex items-center justify-between mb-2">
          <h4 className="text-sm font-medium text-gray-300">Weekly Overview</h4>
          <span className="text-xs text-accent-mint">Avg: 4.2</span>
        </div>
        <div className="space-y-2">
          {[
            { day: 'Mon', rating: 3, date: 'Feb 12' },
            { day: 'Tue', rating: 4, date: 'Feb 13' },
            { day: 'Wed', rating: 3, date: 'Feb 14' },
            { day: 'Thu', rating: 5, date: 'Feb 15' },
            { day: 'Fri', rating: 4, date: 'Feb 16', active: true },
            { day: 'Sat', rating: 0, date: 'Feb 17' },
            { day: 'Sun', rating: 0, date: 'Feb 18' }
          ].map((item) => (
            <div key={item.day} className="flex items-center space-x-2">
              <span className="text-[10px] text-gray-400 w-8">{item.day}</span>
              <div className="flex-1 bg-dark-bg rounded-full h-6 p-1 overflow-hidden border border-gray-800/30">
                <div
                  className={`h-full rounded-full transition-all duration-500
                    ${item.rating >= 4 ? 'bg-gradient-to-r from-accent-mint/60 to-accent-mint' : ''}
                    ${item.rating === 3 ? 'bg-gradient-to-r from-accent-lavender/60 to-accent-lavender' : ''}
                    ${item.rating <= 2 && item.rating > 0 ? 'bg-gradient-to-r from-accent-peach/60 to-accent-peach' : ''}
                    ${item.rating === 0 ? 'bg-gray-800/20' : ''}`}
                  style={{ width: `${item.rating * 20}%` }}
                >
                  {item.rating > 0 && (
                    <div className="h-full flex items-center justify-end pr-2">
                      <span className="text-[10px] text-white">
                        {['😔', '😐', '🙂', '😊', '🥰'][item.rating - 1]}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Reflection Area */}
      <div className="space-y-3">
        <div className="bg-dark-bg/30 rounded-xl p-3 border border-accent-lavender/20">
          <textarea
            placeholder="How are you really feeling? Remember, all emotions are valid..."
            className="w-full bg-transparent border-none focus:ring-0 text-gray-200 placeholder-gray-500
                     resize-none h-20 text-sm"
            defaultValue="Today was challenging but rewarding. Baby slept better, and I managed to take a short nap. Feeling proud of how I'm handling things. 💝"
          />
        </div>

        {/* Prompt Tags */}
        <div className="flex flex-wrap gap-1.5">
          {[
            "I'm proud of...",
            "I need support with...",
            "Today's win was...",
            "I'm grateful for..."
          ].map((prompt, index) => (
            <motion.button
              key={index}
              whileHover={{ scale: 1.05 }}
              className="px-2 py-1 rounded-full bg-accent-lavender/10 text-accent-lavender text-[10px]"
            >
              {prompt}
            </motion.button>
          ))}
        </div>
      </div>

      {/* Save Button */}
      <button className="w-full p-2.5 rounded-lg bg-accent-mint/20 text-accent-mint 
                     border border-accent-mint/30 text-sm font-medium">
        Save Check-in
      </button>
    </div>
  );
} 