import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Milestone, MilestoneType, MILESTONES } from '../../types/victory';
import { TrophyIcon, SparklesIcon, ShareIcon } from '@heroicons/react/24/outline';
import { MilestoneProgress } from './MilestoneProgress';
import { toPng } from 'html-to-image';
import ReactDOM from 'react-dom';
import { ShareableImage } from './ShareableImage';

interface Props {
  milestones: Milestone[];
  totalStats: {
    feedings: number;
    sleep: number;
    diapers: number;
    days: number;
  };
  setToast?: (toast: { message: string; type: 'success' | 'error' } | null) => void;
}

export const AllTimeAchievements = ({ milestones, totalStats, setToast }: Props) => {
  const [selectedMilestone, setSelectedMilestone] = useState<Milestone | undefined>(milestones[0]);

  const handleShare = async (type: 'stats' | 'milestone') => {
    try {
      const portal = document.createElement('div');
      portal.style.position = 'absolute';
      portal.style.left = '-9999px';
      document.body.appendChild(portal);

      const shareableRef = React.createRef<HTMLDivElement>();
      
      ReactDOM.render(
        <ShareableImage 
          ref={shareableRef} 
          type={type === 'stats' ? 'allTimeStats' : 'milestone'} 
          data={{ 
            milestone: type === 'milestone' ? selectedMilestone : undefined,
            stats: type === 'stats' ? totalStats : undefined
          }} 
        />,
        portal
      );

      await new Promise(resolve => setTimeout(resolve, 100));

      if (shareableRef.current) {
        const dataUrl = await toPng(shareableRef.current, {
          quality: 0.95,
          pixelRatio: 2
        });

        if (navigator.share) {
          const blob = await (await fetch(dataUrl)).blob();
          const file = new File([blob], 'achievement.png', { type: 'image/png' });
          
          await navigator.share({
            files: [file],
            title: 'My Motherhood Achievement',
            text: 'Just unlocked a new milestone in my motherhood journey! 🎉'
          });
        } else {
          const link = document.createElement('a');
          link.download = 'motherhood-achievement.png';
          link.href = dataUrl;
          link.click();
        }

        setToast?.({ message: 'Achievement card ready to share!', type: 'success' });
      }
    } catch (error) {
      console.error('Error sharing:', error);
      setToast?.({ message: 'Failed to create shareable card', type: 'error' });
    }
  };

  const handleMilestoneClick = (milestone: Milestone) => {
    setSelectedMilestone(milestone);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-br from-dark-card/95 via-dark-card/90 to-dark-card/80 rounded-xl p-6 border border-accent-gold/20"
    >
      <div className="flex justify-between items-start mb-6">
        <div>
          <h3 className="text-xl font-bold text-white flex items-center gap-2">
            <TrophyIcon className="h-6 w-6 text-accent-gold" />
            All-Time Achievements
          </h3>
        </div>
        <motion.button 
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => handleShare('stats')}
          className="flex items-center gap-2 px-4 py-2 bg-accent-mint/10 rounded-lg 
                   text-accent-mint hover:bg-accent-mint/20 transition-all duration-300"
        >
          <ShareIcon className="h-5 w-5" />
          <span className="text-sm">Share</span>
        </motion.button>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-8">
        <TotalStatBox
          title="Total Feedings"
          value={totalStats.feedings}
          icon="🍼"
          color="rose"
        />
        <TotalStatBox
          title="Hours of Sleep"
          value={Math.round(totalStats.sleep / 3600)}
          icon="😴"
          color="lavender"
        />
        <TotalStatBox
          title="Diaper Changes"
          value={totalStats.diapers}
          icon="✨"
          color="mint"
        />
        <TotalStatBox
          title="Days of Care"
          value={totalStats.days}
          icon="❤️"
          color="gold"
        />
      </div>

      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h4 className="text-sm font-medium text-accent-gold flex items-center gap-2">
            <SparklesIcon className="h-4 w-4" />
            Unlocked Milestones
          </h4>
          {selectedMilestone && (
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => handleShare('milestone')}
              className="flex items-center gap-2 px-4 py-2 bg-accent-rose/10 rounded-lg 
                       text-accent-rose hover:bg-accent-rose/20 transition-all duration-300"
            >
              <ShareIcon className="h-5 w-5" />
              <span className="text-sm">Share Milestone</span>
            </motion.button>
          )}
        </div>
        
        <div className="grid gap-4">
          {milestones.map((milestone, index) => (
            <MilestoneCard
              key={milestone.id}
              milestone={milestone}
              delay={index * 0.1}
              onClick={() => handleMilestoneClick(milestone)}
              isSelected={selectedMilestone?.id === milestone.id}
            />
          ))}
        </div>

        <div className="mt-6 space-y-4">
          <h4 className="text-sm font-medium text-gray-400">Upcoming Milestones</h4>
          <div className="space-y-3">
            {Object.entries(MILESTONES).map(([type, levels]) => {
              const currentValue = 
                type === 'feeding' ? totalStats.feedings :
                type === 'sleep' ? Math.round(totalStats.sleep / 3600) :
                type === 'diaper' ? totalStats.diapers :
                totalStats.days;
              
              const nextMilestone = levels.find(m => m.threshold > currentValue);
              if (nextMilestone) {
                return (
                  <MilestoneProgress
                    key={type}
                    type={type as MilestoneType}
                    currentValue={currentValue}
                    nextMilestone={nextMilestone}
                  />
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const TotalStatBox = ({ 
  title, 
  value, 
  icon, 
  color 
}: { 
  title: string;
  value: number;
  icon: string;
  color: string;
}) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className={`bg-dark-bg/50 rounded-lg p-4 border border-accent-${color}/20`}
  >
    <div className="text-2xl mb-2">{icon}</div>
    <div className={`text-2xl font-bold text-accent-${color}`}>
      {value.toLocaleString()}
    </div>
    <div className="text-xs text-gray-400">{title}</div>
  </motion.div>
);

interface MilestoneCardProps {
  milestone: Milestone;
  delay: number;
  onClick: () => void;
  isSelected: boolean;
}

const MilestoneCard = ({ 
  milestone, 
  delay, 
  onClick, 
  isSelected 
}: MilestoneCardProps) => (
  <motion.div
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ delay }}
    className={`bg-dark-bg/30 rounded-lg p-4 border border-accent-${milestone.category}/20 ${isSelected ? 'border-accent-gold/20' : ''}`}
    onClick={onClick}
  >
    <div className="flex items-center gap-3">
      <div className="text-2xl">{milestone.icon}</div>
      <div>
        <h5 className={`font-medium text-accent-${milestone.category}`}>
          {milestone.title}
        </h5>
        <p className="text-sm text-gray-400">{milestone.description}</p>
        <p className="text-xs text-gray-500 mt-1">
          Achieved {new Date(milestone.achievedAt).toLocaleDateString()}
        </p>
      </div>
    </div>
  </motion.div>
); 