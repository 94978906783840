import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { PlayIcon, PauseIcon, StopIcon } from '@heroicons/react/24/solid';
import { haptics } from '../../utils/haptics';
import { useTimer } from '../../contexts/TimerContext';

interface Props {
  type: 'feeding' | 'sleep';
  onComplete?: (duration: number) => void;
}

export function Timer({ type, onComplete }: Props) {
  const [duration, setDuration] = useState(0);
  const timerRef = useRef<NodeJS.Timeout>();
  const { isRunning, elapsed, startTimer, pauseTimer, stopTimer } = useTimer();

  // Update duration from elapsed time
  useEffect(() => {
    setDuration(Math.floor(elapsed / 1000));
  }, [elapsed]);

  const handleStart = () => {
    if (!isRunning) {
      startTimer(type);
      haptics.light();
    }
  };

  const handlePause = () => {
    pauseTimer();
    haptics.light();
  };

  const handleStop = () => {
    if (onComplete) {
      onComplete(duration);
    }
    stopTimer();
    setDuration(0);
    haptics.light();
  };

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const pad = (num: number) => num.toString().padStart(2, '0');

    if (hours > 0) {
      return `${hours}:${pad(minutes)}:${pad(remainingSeconds)}`;
    }
    return `${pad(minutes)}:${pad(remainingSeconds)}`;
  };

  return (
    <div className="bg-dark-card rounded-lg p-4 border border-gray-800">
      <div className="flex items-center justify-between">
        <div className="text-2xl font-bold text-white font-mono">
          {formatTime(duration)}
        </div>
        <div className="flex items-center space-x-2">
          {!isRunning ? (
            <motion.button
              whileTap={{ scale: 0.95 }}
              onClick={handleStart}
              className="p-2 bg-accent-mint/20 text-accent-mint rounded-lg hover:bg-accent-mint/30 transition-colors"
            >
              <PlayIcon className="h-6 w-6" />
            </motion.button>
          ) : (
            <motion.button
              whileTap={{ scale: 0.95 }}
              onClick={handlePause}
              className="p-2 bg-accent-lavender/20 text-accent-lavender rounded-lg hover:bg-accent-lavender/30 transition-colors"
            >
              <PauseIcon className="h-6 w-6" />
            </motion.button>
          )}
          <motion.button
            whileTap={{ scale: 0.95 }}
            onClick={handleStop}
            className="p-2 bg-accent-rose/20 text-accent-rose rounded-lg hover:bg-accent-rose/30 transition-colors"
          >
            <StopIcon className="h-6 w-6" />
          </motion.button>
        </div>
      </div>
    </div>
  );
}
