export const haptics = {
  success: () => {
    if ('vibrate' in navigator) {
      navigator.vibrate(50);
    }
  },
  error: () => {
    if ('vibrate' in navigator) {
      navigator.vibrate([50, 100, 50]);
    }
  },
  light: () => {
    if ('vibrate' in navigator) {
      navigator.vibrate(20);
    }
  }
};