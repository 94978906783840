import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  sendPasswordResetEmail,
  updateProfile,
  updatePassword as updateFirebasePassword,
  updateEmail as updateFirebaseEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth';
import { doc, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import { auth, db } from './firebase';
import { User, BabyAge } from '../types/user';

class AuthService {
  async signUp(
    email: string, 
    password: string, 
    name: string, 
    babyName?: string,
    babyAge?: BabyAge
  ): Promise<User> {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      await updateProfile(userCredential.user, {
        displayName: name
      });

      const userData: User = {
        id: userCredential.user.uid,
        email,
        name,
        role: 'owner',
        createdAt: Date.now(),
        lastLogin: Date.now()
      };

      if (babyName) {
        userData.babyName = babyName;
      }

      if (babyAge && (babyAge.years || babyAge.months || babyAge.days)) {
        userData.babyAge = {
          ...(babyAge.years !== undefined && { years: babyAge.years }),
          ...(babyAge.months !== undefined && { months: babyAge.months }),
          ...(babyAge.days !== undefined && { days: babyAge.days })
        };
      }

      const userDocRef = doc(db, 'users', userCredential.user.uid);
      await setDoc(userDocRef, userData);

      await new Promise(resolve => setTimeout(resolve, 1000));

      return userData;
    } catch (error: any) {
      console.error('Signup error:', error);
      throw error;
    }
  }

  async login(email: string, password: string): Promise<void> {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      const userDocRef = doc(db, 'users', userCredential.user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        await updateDoc(userDocRef, {
          lastLogin: Date.now()
        });
      } else {
        await setDoc(userDocRef, {
          email: userCredential.user.email,
          name: userCredential.user.displayName || '',
          role: 'owner',
          createdAt: Date.now(),
          lastLogin: Date.now()
        });
      }
    } catch (error: any) {
      console.error('Login error:', error);
      throw error;
    }
  }

  async signOut(): Promise<void> {
    try {
      await firebaseSignOut(auth);
    } catch (error: any) {
      console.error('Signout error:', error);
      throw error;
    }
  }

  async resetPassword(email: string): Promise<void> {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error: any) {
      console.error('Reset password error:', error);
      throw error;
    }
  }

  async getUserData(userId: string): Promise<User> {
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) {
        throw new Error('User data not found');
      }

      return {
        id: userDoc.id,
        ...userDoc.data()
      } as User;
    } catch (error) {
      console.error('Error getting user data:', error);
      throw error;
    }
  }

  async updateUserProfile(updates: {
    name?: string;
    babyName?: string;
    babyAge?: BabyAge;
  }): Promise<void> {
    if (!auth.currentUser) {
      throw new Error('No authenticated user');
    }

    try {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      
      if (updates.name) {
        await updateProfile(auth.currentUser, {
          displayName: updates.name
        });
      }

      const cleanUpdates: any = { ...updates };

      if (updates.babyAge) {
        cleanUpdates.babyAge = {
          ...(updates.babyAge.years !== undefined && { years: updates.babyAge.years }),
          ...(updates.babyAge.months !== undefined && { months: updates.babyAge.months }),
          ...(updates.babyAge.days !== undefined && { days: updates.babyAge.days })
        };

        if (Object.keys(cleanUpdates.babyAge).length === 0) {
          delete cleanUpdates.babyAge;
        }
      }

      Object.keys(cleanUpdates).forEach(key => {
        if (cleanUpdates[key] === undefined) {
          delete cleanUpdates[key];
        }
      });

      cleanUpdates.updatedAt = Date.now();

      await updateDoc(userDocRef, cleanUpdates);
    } catch (error: any) {
      console.error('Update profile error:', error);
      throw error;
    }
  }

  async updatePassword(newPassword: string): Promise<void> {
    if (!auth.currentUser) {
      throw new Error('No authenticated user');
    }

    try {
      await updateFirebasePassword(auth.currentUser, newPassword);
    } catch (error: any) {
      console.error('Update password error:', error);
      throw error;
    }
  }

  async updateEmail(newEmail: string): Promise<void> {
    if (!auth.currentUser) {
      throw new Error('No authenticated user');
    }

    try {
      await updateFirebaseEmail(auth.currentUser, newEmail);
    } catch (error: any) {
      console.error('Update email error:', error);
      throw error;
    }
  }

  async reauthenticate(password: string): Promise<void> {
    if (!auth.currentUser?.email) {
      throw new Error('No authenticated user');
    }

    try {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        password
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
    } catch (error: any) {
      console.error('Reauthentication error:', error);
      throw error;
    }
  }
}

setPersistence(auth, browserLocalPersistence)
  .catch((error) => {
    console.error('Error setting persistence:', error);
  });

export const authService = new AuthService();