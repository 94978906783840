import React from 'react';
import { motion } from 'framer-motion';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Article } from '../../types/learning';

interface Props {
  articles: Article[];
}

export default function ArticleLinks({ articles }: Props) {
  const formatDate = (timestamp: number) => {
    return new Date(timestamp).toLocaleDateString();
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {articles.map((article, index) => (
        <motion.div
          key={article.id}
          className="block bg-dark-card rounded-lg overflow-hidden border border-gray-800 hover:border-accent-lavender/30 transition-colors"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
        >
          {/* Article Image - Smaller height */}
          <div className="w-full h-32">
            <img 
              src={article.imageUrl}
              alt={article.title}
              className="w-full h-full object-cover"
            />
          </div>

          {/* Article Content - More compact */}
          <div className="p-3 space-y-2">
            {/* Category Tag */}
            <span className="inline-block text-xs px-2 py-0.5 rounded-full bg-accent-mint/20 text-accent-mint">
              {article.category}
            </span>

            {/* Title and Description */}
            <div>
              <h3 className="text-sm font-medium text-white line-clamp-2">
                {article.title}
              </h3>
              <p className="text-xs text-gray-400 mt-1 line-clamp-2">
                {article.blurb}
              </p>
            </div>

            {/* Action */}
            <div className="flex items-center justify-between pt-1">
              <span className="text-xs text-gray-500">{formatDate(article.date)}</span>
              <a
                href={article.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-1 text-xs text-accent-mint hover:text-accent-mint/80 transition-colors"
              >
                <span>Read</span>
                <ArrowTopRightOnSquareIcon className="h-3 w-3" />
              </a>
            </div>
          </div>
        </motion.div>
      ))}

      {articles.length === 0 && (
        <div className="text-center py-8 text-gray-400 col-span-full">
          No articles available
        </div>
      )}
    </div>
  );
} 