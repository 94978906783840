import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { storage } from '../services/storage';
import { learningService } from '../services/learning';
import { 
  HeartIcon, 
  ClockIcon, 
  BeakerIcon,
  CalendarIcon 
} from '@heroicons/react/24/outline';
import { TrackingEntry } from '../types/tracking';
import { HealthEntry } from '../types/health';
import { LearningVideo } from '../types/learning';
import { QuickTimer } from './dashboard/QuickTimer';
import { DailySummary } from './dashboard/DailySummary';
import { Toast } from './common/Toast';
import VideoPlayer from './learning/VideoPlayer';

function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
  const [recentFeedings, setRecentFeedings] = useState<TrackingEntry[]>([]);
  const [recentSleep, setRecentSleep] = useState<TrackingEntry[]>([]);
  const [upcomingVaccination, setUpcomingVaccination] = useState<HealthEntry | null>(null);
  const [upcomingAppointment, setUpcomingAppointment] = useState<HealthEntry | null>(null);
  const [dailyVideo, setDailyVideo] = useState<LearningVideo | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadContent = async () => {
      try {
        setIsLoading(true);
        const [
          feedings,
          sleep,
          vaccinations,
          appointments,
          video
        ] = await Promise.all([
          storage.getEntries('feeding'),
          storage.getEntries('sleep'),
          storage.getHealthEntries('vaccinations'),
          storage.getHealthEntries('appointments'),
          learningService.getLatestVideo()
        ]);

        setRecentFeedings(feedings.slice(0, 5));
        setRecentSleep(sleep.slice(0, 5));
        setDailyVideo(video);

        // Find upcoming vaccination
        const upcomingVacc = vaccinations
          .filter(v => v.isScheduled && v.date && v.date > Date.now())
          .sort((a, b) => (a.date || 0) - (b.date || 0))[0];
        setUpcomingVaccination(upcomingVacc || null);

        // Find upcoming appointment
        const upcomingAppt = appointments
          .filter(a => a.date && a.date > Date.now())
          .sort((a, b) => (a.date || 0) - (b.date || 0))[0];
        setUpcomingAppointment(upcomingAppt || null);

      } catch (error) {
        console.error('Error loading dashboard data:', error);
        setError('Failed to load dashboard data');
      } finally {
        setIsLoading(false);
      }
    };

    loadContent();
  }, []);

  // Add navigation handlers
  const handleFeedingClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate('/app/baby', { state: { initialTab: 'feeding' } });
  };

  const handleSleepClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate('/app/baby', { state: { initialTab: 'sleep' } });
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="text-accent-lavender animate-pulse">Loading your dashboard...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-accent-rose">{error}</div>
      </div>
    );
  }

  // Render sections conditionally
  return (
    <div className="space-y-4">
      {/* Quick Stats */}
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
        {recentFeedings[0] && (
          <div 
            onClick={handleFeedingClick}
            className="bg-dark-card rounded-xl p-3 sm:p-4 border border-gray-800 
                     cursor-pointer active:bg-gray-800 transition-colors"
          >
            <div className="flex items-center space-x-3">
              <div className="p-2 bg-accent-peach/10 rounded-lg">
                <HeartIcon className="h-5 w-5 text-accent-peach" />
              </div>
              <div>
                <p className="text-xs sm:text-sm text-gray-400">Last Feeding</p>
                <p className="font-medium text-white text-sm sm:text-base">
                  {new Date(recentFeedings[0].timestamp).toLocaleTimeString([], { 
                    hour: '2-digit', 
                    minute: '2-digit',
                    hour12: true 
                  })}
                </p>
                <p className="text-xs text-gray-500">
                  {new Date(recentFeedings[0].timestamp).toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>
        )}

        {recentSleep[0] && (
          <div 
            onClick={handleSleepClick}
            className="bg-dark-card rounded-xl p-3 sm:p-4 border border-gray-800 
                     cursor-pointer active:bg-gray-800 transition-colors"
          >
            <div className="flex items-center space-x-3">
              <div className="p-2 bg-accent-mint/10 rounded-lg">
                <ClockIcon className="h-5 w-5 text-accent-mint" />
              </div>
              <div>
                <p className="text-xs sm:text-sm text-gray-400">Last Sleep</p>
                <p className="font-medium text-white text-sm sm:text-base">
                  {recentSleep[0].duration ? `${Math.round(recentSleep[0].duration / 60)}min` : '0min'}
                </p>
                <p className="text-xs text-gray-500">
                  {new Date(recentSleep[0].timestamp).toLocaleTimeString([], { 
                    hour: '2-digit', 
                    minute: '2-digit',
                    hour12: true 
                  })}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Quick Timer */}
      <QuickTimer setToast={setToast} />

      {/* Daily Summary */}
      <DailySummary />

      {/* Daily Video Section */}
      {dailyVideo && (
        <div className="space-y-2">
          <h2 className="text-lg font-medium text-white flex items-center space-x-2">
            <span>Today's Learning</span>
            <span className="text-xs px-2 py-0.5 rounded-full bg-accent-mint/20 text-accent-mint">
              New
            </span>
          </h2>
          <VideoPlayer video={dailyVideo} />
        </div>
      )}

      {/* Upcoming Health Events */}
      {(upcomingVaccination || upcomingAppointment) && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-dark-card rounded-xl p-4 border border-gray-800"
        >
          <h3 className="text-lg font-medium text-white mb-3">Upcoming Health Events</h3>
          <div className="space-y-3">
            {upcomingVaccination && (
              <div 
                onClick={() => navigate('/app/health', { state: { initialTab: 'vaccinations' } })}
                className="flex items-center space-x-3 p-3 bg-accent-peach/10 rounded-lg cursor-pointer hover:bg-accent-peach/20"
              >
                <div className="p-2 bg-accent-peach/20 rounded-lg">
                  <BeakerIcon className="h-5 w-5 text-accent-peach" />
                </div>
                <div>
                  <p className="text-sm text-gray-400">Upcoming Vaccination</p>
                  <p className="font-medium text-white">{upcomingVaccination.title}</p>
                  <p className="text-xs text-gray-500">
                    {new Date(upcomingVaccination.date || 0).toLocaleDateString()}
                  </p>
                </div>
              </div>
            )}

            {upcomingAppointment && (
              <div 
                onClick={() => navigate('/app/health', { state: { initialTab: 'appointments' } })}
                className="flex items-center space-x-3 p-3 bg-accent-mint/10 rounded-lg cursor-pointer hover:bg-accent-mint/20"
              >
                <div className="p-2 bg-accent-mint/20 rounded-lg">
                  <CalendarIcon className="h-5 w-5 text-accent-mint" />
                </div>
                <div>
                  <p className="text-sm text-gray-400">Upcoming Appointment</p>
                  <p className="font-medium text-white">{upcomingAppointment.title}</p>
                  <p className="text-xs text-gray-500">
                    {new Date(upcomingAppointment.date || 0).toLocaleDateString()} at{' '}
                    {upcomingAppointment.time}
                  </p>
                </div>
              </div>
            )}
          </div>
        </motion.div>
      )}

      <Toast 
        message={toast?.message || ''} 
        type={toast?.type || 'success'} 
        isVisible={!!toast} 
      />
    </div>
  );
}

export default Dashboard;
