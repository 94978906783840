import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

interface Props {
  message: string;
  type: 'success' | 'error';
  isVisible: boolean;
  duration?: number;
}

export function Toast({ message, type, isVisible, duration = 3000 }: Props) {
  const [show, setShow] = React.useState(isVisible);

  useEffect(() => {
    setShow(isVisible);
    if (isVisible) {
      const timer = setTimeout(() => {
        setShow(false);
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [isVisible, duration]);

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50"
        >
          <div
            className={`flex items-center space-x-2 px-4 py-2 rounded-lg shadow-lg 
              ${type === 'success' 
                ? 'bg-accent-mint/20 text-accent-mint border border-accent-mint/30' 
                : 'bg-accent-rose/20 text-accent-rose border border-accent-rose/30'
              }`}
          >
            {type === 'success' ? (
              <CheckCircleIcon className="h-5 w-5" />
            ) : (
              <XCircleIcon className="h-5 w-5" />
            )}
            <p className="text-sm">{message}</p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}