import React from 'react';
import { motion } from 'framer-motion';
import { VictoryEntry, Milestone } from '../../types/victory';
import { StarIcon, HeartIcon, MoonIcon, SparklesIcon } from '@heroicons/react/24/outline';

interface Props {
  victory: VictoryEntry;
  milestone?: Milestone;
  onShare?: () => void;
}

export const ShareableVictoryCard = React.forwardRef<HTMLDivElement, Props>(({ victory, milestone }, ref) => {
  return (
    <div 
      ref={ref}
      className="w-[1080px] h-[1080px] relative bg-gradient-to-br from-dark-bg via-dark-bg/95 to-dark-bg/90 p-16"
    >
      {/* Beautiful decorative elements */}
      <div className="absolute top-0 right-0 w-96 h-96 bg-accent-gold/10 rounded-full blur-3xl" />
      <div className="absolute bottom-0 left-0 w-80 h-80 bg-accent-rose/10 rounded-full blur-2xl" />
      <div className="absolute top-1/2 left-1/2 w-72 h-72 bg-accent-mint/10 rounded-full blur-3xl transform -translate-x-1/2 -translate-y-1/2" />

      {/* Logo and Title */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-white mb-4">NurturingMom</h1>
        <p className="text-xl text-accent-gold">Celebrating Motherhood</p>
      </div>

      {milestone ? (
        // Milestone Achievement Layout
        <div className="space-y-8">
          <div className="text-center">
            <span className="text-6xl mb-4 block">{milestone.icon}</span>
            <h2 className="text-3xl font-bold text-white mb-2">{milestone.title}</h2>
            <p className="text-xl text-gray-400">{milestone.description}</p>
          </div>
          
          <div className="bg-dark-card/30 rounded-2xl p-8 backdrop-blur-sm border border-accent-gold/20">
            <p className="text-xl text-center text-gray-300">
              Achievement unlocked on {new Date(milestone.achievedAt).toLocaleDateString()}
            </p>
          </div>
        </div>
      ) : (
        // Daily Victory Layout
        <div className="space-y-8">
          <div className="grid grid-cols-3 gap-8">
            <StatBox
              icon="🍼"
              value={victory.feedingCount || 0}
              label="Feedings"
              color="rose"
            />
            <StatBox
              icon="😴"
              value={Math.round((victory.sleepDuration || 0) / 3600)}
              label="Hours Sleep"
              color="lavender"
            />
            <StatBox
              icon="✨"
              value={victory.diaperCount || 0}
              label="Changes"
              color="mint"
            />
          </div>

          {victory.customVictories && victory.customVictories.length > 0 && (
            <div className="bg-dark-card/30 rounded-2xl p-8 backdrop-blur-sm border border-gray-800/30">
              <h3 className="text-2xl font-medium text-accent-gold mb-6">Today's Victories</h3>
              <div className="grid grid-cols-2 gap-6">
                {victory.customVictories.map((achievement, index) => (
                  <div key={index} className="flex items-center gap-4">
                    <div className="w-3 h-3 rounded-full bg-accent-gold flex-shrink-0" />
                    <p className="text-lg text-gray-300">{achievement}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {/* Footer */}
      <div className="absolute bottom-16 left-16 right-16 text-center">
        <p className="text-gray-400">
          {new Date(victory.timestamp).toLocaleDateString()} • Track your journey at nurturingmom.app
        </p>
      </div>
    </div>
  );
});

const StatBox = ({ 
  icon, 
  value, 
  label, 
  color 
}: { 
  icon: string;
  value: number;
  label: string;
  color: string;
}) => (
  <div className={`bg-dark-bg/50 rounded-2xl p-8 border border-accent-${color}/20`}>
    <div className="text-4xl mb-4">{icon}</div>
    <p className={`text-4xl font-bold text-accent-${color} mb-2`}>{value}</p>
    <p className="text-lg text-gray-400">{label}</p>
  </div>
); 