import React from 'react';
import { WellnessEntry } from '../../types/wellness';
import { motion } from 'framer-motion';

interface Props {
  entries: WellnessEntry[];
  type: WellnessEntry['type'];
}

export function WellnessCelebrations({ entries, type }: Props) {
  const getStreak = () => {
    let streak = 0;
    const today = new Date().setHours(0, 0, 0, 0);
    
    for (let i = 0; i < entries.length; i++) {
      const entryDate = new Date(entries[i].timestamp).setHours(0, 0, 0, 0);
      if (today - entryDate <= streak * 24 * 60 * 60 * 1000) {
        streak++;
      } else break;
    }
    return streak;
  };

  const getCelebrationMessage = () => {
    const streak = getStreak();
    if (streak >= 7) return "🎉 Amazing! A week of consistent check-ins!";
    if (streak >= 3) return "✨ You're on a roll! 3+ days streak!";
    if (streak === 2) return "💫 Second day in a row - keep going!";
    return "🌸 Every check-in is a step toward self-care";
  };

  const getStreakClass = () => {
    const streak = getStreak();
    if (streak >= 7) return 'animate-bounce';
    if (streak >= 3) return 'animate-pulse';
    return '';
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`bg-gradient-to-br from-accent-lavender/10 to-accent-rose/10 
                  rounded-xl p-6 border border-accent-lavender/30 backdrop-blur-sm
                  ${getStreakClass()}`}
    >
      <div className="flex items-center gap-4">
        <div className="p-3 bg-accent-lavender/10 rounded-full">
          <span className="text-2xl">{getStreak() >= 7 ? '🌟' : getStreak() >= 3 ? '✨' : '💫'}</span>
        </div>
        <div>
          <p className="text-lg text-accent-lavender font-medium">
            {getCelebrationMessage()}
          </p>
          {getStreak() > 0 && (
            <p className="text-sm text-accent-lavender/70 mt-1">
              {getStreak()} day{getStreak() > 1 ? 's' : ''} of self-care 🌸
            </p>
          )}
        </div>
      </div>

      {/* Progress indicator */}
      {getStreak() > 0 && (
        <div className="mt-4">
          <div className="h-2 bg-dark-bg rounded-full overflow-hidden">
            <motion.div 
              initial={{ width: 0 }}
              animate={{ width: `${Math.min((getStreak() / 7) * 100, 100)}%` }}
              className="h-full bg-gradient-to-r from-accent-lavender/60 to-accent-lavender rounded-full"
            />
          </div>
          <p className="text-xs text-gray-400 mt-2 text-center">
            {getStreak() >= 7 ? 
              "You've achieved a full week! 🎉" :
              `${7 - getStreak()} more days until your first week! 💪`}
          </p>
        </div>
      )}
    </motion.div>
  );
}
