import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { HealthEntry, HealthType } from '../../types/health';
import { storage } from '../../services/storage';
import { VaccineSelector } from './VaccineSelector';
import { auth } from '../../services/firebase';

interface Props {
  isOpen: boolean;
  onClose: (success?: boolean) => void;
  type: HealthType;
  entry?: HealthEntry | null;
  setToast: (toast: { message: string; type: 'success' | 'error' } | null) => void;
  loadEntries: () => Promise<void>;
}

interface CalendarProvider {
  name: string;
  icon: string;
  getUrl: (event: CalendarEvent) => string;
}

interface CalendarEvent {
  title: string;
  description: string;
  location: string;
  startTime: string;
  endTime: string;
}

const calendarProviders: CalendarProvider[] = [
  {
    name: 'Google',
    icon: '📅',
    getUrl: (event) => {
      const start = event.startTime.replace(/[-:]/g, '').replace('.000', '');
      const end = event.endTime.replace(/[-:]/g, '').replace('.000', '');
      return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.title)}&details=${encodeURIComponent(event.description)}&location=${encodeURIComponent(event.location)}&dates=${start}/${end}`;
    }
  },
  {
    name: 'Outlook',
    icon: '📆',
    getUrl: (event) => {
      return `https://outlook.live.com/calendar/0/deeplink/compose?subject=${encodeURIComponent(event.title)}&body=${encodeURIComponent(event.description)}&location=${encodeURIComponent(event.location)}&startdt=${event.startTime}&enddt=${event.endTime}`;
    }
  },
  {
    name: 'Apple',
    icon: '🗓️',
    getUrl: (event) => {
      const formatDate = (date: string) => date.replace(/[-:]/g, '').replace('.000', '');
      return `data:text/calendar;charset=utf8,BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nURL:about:blank\nDTSTART:${formatDate(event.startTime)}\nDTEND:${formatDate(event.endTime)}\nSUMMARY:${event.title}\nDESCRIPTION:${event.description}\nLOCATION:${event.location}\nEND:VEVENT\nEND:VCALENDAR`;
    }
  }
];

export function HealthForm({ isOpen, onClose, type, entry, setToast, loadEntries }: Props) {
  const [vaccine, setVaccine] = useState(entry?.title || '');
  const [date, setDate] = useState(
    entry?.date ? new Date(entry.date).toISOString().split('T')[0] : ''
  );
  const [doseNumber, setDoseNumber] = useState(entry?.doseNumber || '');
  const [notes, setNotes] = useState(entry?.notes || '');
  const [isScheduled, setIsScheduled] = useState(entry?.isScheduled || false);
  const [dateError, setDateError] = useState('');
  const [doseError, setDoseError] = useState('');
  const [location, setLocation] = useState(entry?.location || '');
  const [time, setTime] = useState(entry?.time || '09:00');
  const [title, setTitle] = useState(entry?.title || '');
  const [severity, setSeverity] = useState(entry?.severity || 'low');
  const [symptoms, setSymptoms] = useState(entry?.symptoms || '');

  useEffect(() => {
    if (entry) {
      setTitle(entry.title || '');
      setDate(entry.date ? new Date(entry.date).toISOString().split('T')[0] : '');
      setTime(entry.time || '09:00');
      setLocation(entry.location || '');
      setNotes(entry.notes || '');
      setSeverity(entry.severity || 'low');
      setSymptoms(entry.symptoms || '');
      setVaccine(entry.title || '');
      setDoseNumber(entry.doseNumber || '');
      setIsScheduled(entry.isScheduled || false);
    } else {
      setTitle('');
      setDate('');
      setTime('09:00');
      setLocation('');
      setNotes('');
      setSeverity('low');
      setSymptoms('');
      setVaccine('');
      setDoseNumber('');
      setIsScheduled(false);
    }
  }, [entry]);

  const validateDoseNumber = (value: string) => {
    const validFormats = /^([1-9][0-9]*(st|nd|rd|th))\s*(dose)?$/i;
    if (!validFormats.test(value)) {
      setDoseError('Please use format: 1st dose, 2nd dose, etc.');
      return false;
    }
    setDoseError('');
    return true;
  };

  const validateVaccinationDate = (dateStr: string) => {
    const selectedDate = new Date(dateStr);
    const today = new Date();
    
    if (!isScheduled && selectedDate > today) {
      setDateError('Past date required for completed vaccinations');
      return false;
    }
    
    setDateError('');
    return true;
  };

  const handleSubmit = async () => {
    if (type === 'vaccinations') {
      if (!vaccine || !date || !doseNumber) return;
      if (!validateDoseNumber(doseNumber)) return;
      if (!validateVaccinationDate(date)) return;

      const newEntry: HealthEntry = {
        id: entry?.id || Date.now().toString(),
        userId: auth.currentUser?.uid || '',
        type: 'vaccinations',
        title: vaccine,
        description: '',
        date: new Date(date).getTime(),
        time: time,
        doseNumber,
        notes,
        location,
        isScheduled,
        timestamp: entry?.timestamp || Date.now(),
      };

      try {
        if (entry?.id) {
          await storage.updateHealthEntry(newEntry);
        } else {
          await storage.addHealthEntry(newEntry);
        }

        if (isScheduled) {
          const startDate = new Date(`${date}T${time}`);
          const endDate = new Date(startDate);
          endDate.setHours(endDate.getHours() + 1);

          const event: CalendarEvent = {
            title: `Vaccination: ${vaccine} (${doseNumber})`,
            description: `Vaccination appointment for ${vaccine}\n${notes ? `Notes: ${notes}` : ''}`,
            location: location || '',
            startTime: startDate.toISOString(),
            endTime: endDate.toISOString()
          };

          calendarProviders.forEach(provider => {
            const url = provider.getUrl(event);
            window.open(url, '_blank');
          });
        }

        onClose(true);
      } catch (error) {
        console.error('Error saving vaccination:', error);
        setToast?.({ message: 'Failed to save vaccination', type: 'error' });
      }
    } 
    
    if (type === 'concerns') {
      if (!title) return;

      const newEntry: HealthEntry = {
        id: entry?.id || Date.now().toString(),
        userId: auth.currentUser?.uid || '',
        type: 'concerns',
        title,
        description: symptoms || '',
        severity,
        symptoms,
        notes,
        resolved: false,
        timestamp: Date.now(),
      };

      if (entry?.id) {
        newEntry.timestamp = entry.timestamp;
        storage.updateHealthEntry(newEntry);
      } else {
        await storage.addHealthEntry(newEntry);
      }
      onClose(true);
    }

    if (type === 'appointments') {
      if (!title || !date || !time) {
        console.log('Missing required fields for appointment');
        return;
      }

      const newEntry: HealthEntry = {
        id: entry?.id || Date.now().toString(),
        userId: auth.currentUser?.uid || '',
        type: 'appointments',
        title,
        description: notes || '',
        date: new Date(date).getTime(),
        time,
        location,
        notes,
        timestamp: entry?.id ? entry.timestamp : Date.now(),
      };

      try {
        if (entry?.id) {
          await storage.updateHealthEntry(newEntry);
        } else {
          await storage.addHealthEntry(newEntry);
        }
        onClose(true);
      } catch (error) {
        console.error('Error saving appointment:', error);
      }
    }
  };

  const handleAddToCalendar = (e: React.MouseEvent, provider: CalendarProvider) => {
    e.preventDefault();
    e.stopPropagation();

    if (type === 'vaccinations' && (!vaccine || !date || !time)) {
      setToast?.({ message: 'Please fill in all required fields', type: 'error' });
      return;
    }

    if (type === 'appointments' && (!title || !date || !time)) {
      setToast?.({ message: 'Please fill in all required fields', type: 'error' });
      return;
    }
    
    try {
      const startDate = new Date(`${date}T${time}`);
      const endDate = new Date(startDate);
      endDate.setHours(endDate.getHours() + 1);

      const event: CalendarEvent = {
        title: type === 'vaccinations' 
          ? `Vaccination: ${vaccine} (${doseNumber})`
          : `Medical Appointment: ${title}`,
        description: type === 'vaccinations'
          ? `Vaccination appointment for ${vaccine}\n${notes ? `Notes: ${notes}` : ''}`
          : (notes ? `Notes: ${notes}` : 'Medical appointment'),
        location: location || '',
        startTime: startDate.toISOString(),
        endTime: endDate.toISOString()
      };

      const url = provider.getUrl(event);
      window.open(url, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Error creating calendar event:', error);
      setToast?.({ message: 'Failed to create calendar event', type: 'error' });
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => onClose()} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="bg-dark-card rounded-lg p-6 max-w-sm w-full max-h-[90vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <Dialog.Title className="text-lg font-medium text-accent-peach">
              {entry ? 'Edit' : 'Add'} {type.charAt(0).toUpperCase() + type.slice(1)}
            </Dialog.Title>
            <button onClick={() => onClose()} className="text-gray-400 hover:text-white">
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          <div className="space-y-4">
            {type === 'vaccinations' && (
              <div className="space-y-4 bg-dark-bg/50 p-4 rounded-lg border border-gray-800">
                <div>
                  <label className="block text-sm text-gray-400 mb-1">Vaccine Name</label>
                  <VaccineSelector value={vaccine} onChange={setVaccine} />
                </div>

                <div className="flex items-center space-x-2 mb-4">
                  <input
                    type="checkbox"
                    checked={isScheduled}
                    onChange={(e) => {
                      setIsScheduled(e.target.checked);
                      setDateError('');
                    }}
                    className="rounded border-gray-800"
                  />
                  <label className="text-sm text-gray-400">Schedule for future date</label>
                </div>

                <div>
                  <label className="block text-sm text-gray-400 mb-1">
                    {isScheduled ? 'Scheduled Date' : 'Vaccination Date'}
                  </label>
                  <input
                    type="date"
                    value={date}
                    onChange={(e) => {
                      setDate(e.target.value);
                      validateVaccinationDate(e.target.value);
                    }}
                    className={`w-full bg-dark-bg text-white rounded-lg p-2 border ${
                      dateError ? 'border-red-500' : 'border-gray-800'
                    }`}
                    required
                    min={isScheduled ? new Date().toISOString().split('T')[0] : undefined}
                    max={!isScheduled ? new Date().toISOString().split('T')[0] : undefined}
                  />
                  {dateError && <p className="text-red-500 text-sm mt-1">{dateError}</p>}
                </div>

                <div>
                  <label className="block text-sm text-gray-400 mb-1">Dose Number</label>
                  <input
                    type="text"
                    value={doseNumber}
                    onChange={(e) => {
                      setDoseNumber(e.target.value);
                      validateDoseNumber(e.target.value);
                    }}
                    className={`w-full bg-dark-bg text-white rounded-lg p-2 border ${
                      doseError ? 'border-red-500' : 'border-gray-800'
                    }`}
                    placeholder="e.g., 1st dose, 2nd dose"
                  />
                  {doseError && <p className="text-red-500 text-sm mt-1">{doseError}</p>}
                </div>

                <div>
                  <label className="block text-sm text-gray-400 mb-1">Location (optional)</label>
                  <input
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    className="w-full bg-dark-bg text-white rounded-lg p-2 border border-gray-800"
                    placeholder="Enter clinic or hospital name"
                  />
                </div>

                <div>
                  <label className="block text-sm text-gray-400 mb-1">Notes (optional)</label>
                  <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    className="w-full bg-dark-bg text-white rounded-lg p-2 border border-gray-800 h-20"
                    placeholder="Add any additional notes about the vaccination"
                  />
                </div>
              </div>
            )}

            {isScheduled && (
              <div className="space-y-2">
                <label className="block text-sm text-gray-400">Add to Calendar</label>
                <div className="flex space-x-2">
                  {calendarProviders.map((provider) => (
                    <button
                      key={provider.name}
                      type="button"
                      onClick={(e) => handleAddToCalendar(e, provider)}
                      className="flex-1 bg-accent-mint/20 text-accent-mint p-2 rounded-lg border border-accent-mint/30 hover:bg-accent-mint/30 flex items-center justify-center space-x-2"
                    >
                      <span>{provider.icon}</span>
                      <span>{provider.name}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}

            {isScheduled && (
              <div>
                <label className="block text-sm text-gray-400 mb-1">Appointment Time</label>
                <input
                  type="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  className="w-full bg-dark-bg text-white rounded-lg p-2 border border-gray-800"
                  required
                />
              </div>
            )}

            {type === 'concerns' && (
              <div className="space-y-4 bg-dark-bg/50 p-4 rounded-lg border border-gray-800">
                <div>
                  <label className="block text-sm text-gray-400 mb-1">Concern Title</label>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full bg-dark-bg text-white rounded-lg p-2 border border-gray-800"
                    placeholder="e.g., Persistent Cough, Fever"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm text-gray-400 mb-1">Severity</label>
                  <select
                    value={severity}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSeverity(e.target.value as 'low' | 'medium' | 'high')}
                    className="w-full bg-dark-bg text-white rounded-lg p-2 border border-gray-800"
                  >
                    <option value="low">Low - Monitor</option>
                    <option value="medium">Medium - Needs Attention</option>
                    <option value="high">High - Urgent</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm text-gray-400 mb-1">Symptoms</label>
                  <textarea
                    value={symptoms}
                    onChange={(e) => setSymptoms(e.target.value)}
                    className="w-full bg-dark-bg text-white rounded-lg p-2 border border-gray-800 h-32"
                    placeholder="Describe the symptoms and health concern in detail"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm text-gray-400 mb-1">Additional Notes</label>
                  <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    className="w-full bg-dark-bg text-white rounded-lg p-2 border border-gray-800 h-20"
                    placeholder="Any additional notes or observations"
                  />
                </div>
              </div>
            )}

            {type === 'appointments' && (
              <div className="space-y-4 bg-dark-bg/50 p-4 rounded-lg border border-gray-800">
                <div>
                  <label className="block text-sm text-gray-400 mb-1">Appointment Title</label>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full bg-dark-bg text-white rounded-lg p-2 border border-gray-800"
                    placeholder="e.g., Pediatrician Check-up, Growth Assessment"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm text-gray-400 mb-1">Date</label>
                  <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className="w-full bg-dark-bg text-white rounded-lg p-2 border border-gray-800"
                    required
                    min={new Date().toISOString().split('T')[0]}
                  />
                </div>

                <div>
                  <label className="block text-sm text-gray-400 mb-1">Time</label>
                  <input
                    type="time"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    className="w-full bg-dark-bg text-white rounded-lg p-2 border border-gray-800"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm text-gray-400 mb-1">Location</label>
                  <input
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    className="w-full bg-dark-bg text-white rounded-lg p-2 border border-gray-800"
                    placeholder="Enter appointment location"
                  />
                </div>

                <div>
                  <label className="block text-sm text-gray-400 mb-1">Notes (optional)</label>
                  <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    className="w-full bg-dark-bg text-white rounded-lg p-2 border border-gray-800 h-20"
                    placeholder="Add any additional notes about the appointment"
                  />
                </div>
              </div>
            )}

            {type === 'appointments' && (
              <div className="space-y-2">
                <label className="block text-sm text-gray-400">Add to Calendar</label>
                <div className="flex space-x-2">
                  {calendarProviders.map((provider) => (
                    <button
                      key={provider.name}
                      type="button"
                      onClick={(e) => handleAddToCalendar(e, provider)}
                      className="flex-1 bg-accent-mint/20 text-accent-mint p-2 rounded-lg border border-accent-mint/30 hover:bg-accent-mint/30 flex items-center justify-center space-x-2"
                    >
                      <span>{provider.icon}</span>
                      <span>{provider.name}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}

            <div className="flex justify-end space-x-3">
              <button
                onClick={() => onClose()}
                className="px-4 py-2 text-sm text-gray-400 hover:text-white"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="px-4 py-2 text-sm bg-accent-mint/20 text-accent-mint rounded-lg hover:bg-accent-mint/30"
              >
                {entry ? 'Update' : 'Save'}
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
