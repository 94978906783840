import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { storage } from '../../services/storage';
import { VictoryEntry, WeeklyStats, Milestone, MilestoneType, MILESTONES, getMilestoneDescription } from '../../types/victory';
import { TrackingEntry } from '../../types/tracking';
import { Toast } from '../common/Toast';
import { StarIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { VictoryCard } from './VictoryCard';
import { WeeklyVictories } from './WeeklyVictories';
import { AllTimeAchievements } from './AllTimeAchievements';

// Add this interface for tracking milestones and progress
interface ProgressStats {
  totalFeedings: number;
  totalSleep: number;
  totalDiapers: number;
  previousWeekFeedings: number;
  previousWeekSleep: number;
  previousWeekDiapers: number;
  nightFeedings: number;
  longestSleep: number;
  consecutiveDays: number;
}

function Victories() {
  const [groupedVictories, setGroupedVictories] = useState<{
    today: VictoryEntry[];
    thisWeek: VictoryEntry[];
    earlier: VictoryEntry[];
  }>({
    today: [],
    thisWeek: [],
    earlier: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
  const [weeklyStats, setWeeklyStats] = useState<WeeklyStats | null>(null);
  const [totalStats, setTotalStats] = useState<{
    feedings: number;
    sleep: number;
    diapers: number;
    days: number;
  }>({
    feedings: 0,
    sleep: 0,
    diapers: 0,
    days: 0
  });

  useEffect(() => {
    loadVictories();
    loadAllStats();
  }, []);

  const loadVictories = async () => {
    try {
      setIsLoading(true);
      
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      // First, clean up any existing duplicates
      const existingVictories = await storage.getVictories();
      
      // Group by date to find duplicates
      const victoryByDate = existingVictories.reduce((acc, victory) => {
        const date = new Date(victory.timestamp).setHours(0, 0, 0, 0);
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(victory);
        return acc;
      }, {} as Record<number, VictoryEntry[]>);

      // Delete duplicates and keep only the most recent for each date
      for (const [date, victories] of Object.entries(victoryByDate)) {
        if (victories.length > 1) {
          // Sort by timestamp descending (most recent first)
          victories.sort((a, b) => b.timestamp - a.timestamp);
          
          // Keep the first one, delete the rest
          const [keep, ...duplicates] = victories;
          
          // Delete all duplicates
          for (const dupe of duplicates) {
            await storage.deleteVictory(dupe.id);
          }
        }
      }

      // Now get fresh data and create today's entry if needed
      const freshVictories = await storage.getVictories();
      const hasToday = freshVictories.some(v => 
        new Date(v.timestamp).setHours(0,0,0,0) === today.getTime()
      );

      // Only create new victory if we don't have one for today
      if (!hasToday) {
        const [feedings, sleepEntries, diaperChanges] = await Promise.all([
          storage.getEntries('feeding'),
          storage.getEntries('sleep'),
          storage.getEntries('diaper')
        ]);

        const todayEntries = {
          feedings: feedings.filter(entry => 
            new Date(entry.timestamp).setHours(0, 0, 0, 0) === today.getTime()
          ),
          sleep: sleepEntries.filter(entry => 
            new Date(entry.timestamp).setHours(0, 0, 0, 0) === today.getTime()
          ),
          diapers: diaperChanges.filter(entry => 
            new Date(entry.timestamp).setHours(0, 0, 0, 0) === today.getTime()
          )
        };

        if (todayEntries.feedings.length > 0 || todayEntries.sleep.length > 0 || todayEntries.diapers.length > 0) {
          const totalSleepDuration = todayEntries.sleep.reduce((acc, entry) => acc + (entry.duration || 0), 0);
          
          const victory: Omit<VictoryEntry, 'id'> = {
            userId: todayEntries.feedings[0]?.userId || todayEntries.sleep[0]?.userId || todayEntries.diapers[0]?.userId || '',
            timestamp: Date.now(),
            feedingCount: todayEntries.feedings.length,
            sleepDuration: totalSleepDuration,
            diaperCount: todayEntries.diapers.length,
            customVictories: await generateCustomVictories(todayEntries.feedings, todayEntries.sleep, todayEntries.diapers),
            shared: false
          };

          await storage.addVictory(victory);
        }
      }

      // Final fetch to get the clean data
      const finalVictories = await storage.getVictories();
      
      // Group victories by time period, ensuring no duplicates
      const grouped = finalVictories.reduce((acc, victory) => {
        const victoryDate = new Date(victory.timestamp);
        const daysDiff = Math.floor((today.getTime() - victoryDate.getTime()) / (1000 * 60 * 60 * 24));

        if (daysDiff === 0) {
          acc.today = [victory]; // Only one for today
        } else if (daysDiff <= 7) {
          acc.thisWeek.push(victory);
        } else {
          acc.earlier.push(victory);
        }
        return acc;
      }, { today: [], thisWeek: [], earlier: [] } as typeof groupedVictories);

      setGroupedVictories(grouped);
      
    } catch (error) {
      console.error('Error loading victories:', error);
      setToast({ message: 'Failed to load victories', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const loadAllStats = async () => {
    try {
      const [feedings, sleep, diapers] = await Promise.all([
        storage.getEntries('feeding'),
        storage.getEntries('sleep'),
        storage.getEntries('diaper')
      ]);

      // Calculate total stats
      const total = {
        feedings: feedings.length,
        sleep: sleep.reduce((acc, entry) => acc + (entry.duration || 0), 0),
        diapers: diapers.length,
        days: calculateUniqueDays([...feedings, ...sleep, ...diapers])
      };

      // Calculate weekly stats
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      const twoWeeksAgo = new Date();
      twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

      const thisWeek = {
        feedings: feedings.filter(e => new Date(e.timestamp) >= oneWeekAgo).length,
        sleep: sleep.filter(e => new Date(e.timestamp) >= oneWeekAgo)
          .reduce((acc, entry) => acc + (entry.duration || 0), 0),
        diapers: diapers.filter(e => new Date(e.timestamp) >= oneWeekAgo).length
      };

      const lastWeek = {
        feedings: feedings.filter(e => 
          new Date(e.timestamp) >= twoWeeksAgo && new Date(e.timestamp) < oneWeekAgo
        ).length,
        sleep: sleep.filter(e => 
          new Date(e.timestamp) >= twoWeeksAgo && new Date(e.timestamp) < oneWeekAgo
        ).reduce((acc, entry) => acc + (entry.duration || 0), 0),
        diapers: diapers.filter(e => 
          new Date(e.timestamp) >= twoWeeksAgo && new Date(e.timestamp) < oneWeekAgo
        ).length
      };

      setTotalStats(total);
      setWeeklyStats({
        totalFeedings: thisWeek.feedings,
        totalSleep: thisWeek.sleep,
        totalDiapers: thisWeek.diapers,
        improvementFromLastWeek: {
          feedings: thisWeek.feedings - lastWeek.feedings,
          sleep: thisWeek.sleep - lastWeek.sleep,
          diapers: thisWeek.diapers - lastWeek.diapers
        }
      });
    } catch (error) {
      console.error('Error loading stats:', error);
    }
  };

  const calculateUniqueDays = (entries: TrackingEntry[]): number => {
    const uniqueDays = new Set(
      entries.map(entry => 
        new Date(entry.timestamp).setHours(0, 0, 0, 0)
      )
    );
    return uniqueDays.size;
  };

  const generateCustomVictories = async (
    feedings: TrackingEntry[], 
    sleep: TrackingEntry[], 
    diapers: TrackingEntry[]
  ): Promise<string[]> => {
    const victories: string[] = [];
    
    // Get historical data for comparisons
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    
    const previousWeekData = await Promise.all([
      storage.getEntries('feeding'),
      storage.getEntries('sleep'),
      storage.getEntries('diaper')
    ]);

    const stats: ProgressStats = {
      totalFeedings: feedings.length,
      totalSleep: sleep.reduce((acc, entry) => acc + (entry.duration || 0), 0),
      totalDiapers: diapers.length,
      previousWeekFeedings: previousWeekData[0].filter(e => 
        new Date(e.timestamp) >= oneWeekAgo
      ).length,
      previousWeekSleep: previousWeekData[1].filter(e => 
        new Date(e.timestamp) >= oneWeekAgo
      ).reduce((acc, entry) => acc + (entry.duration || 0), 0),
      previousWeekDiapers: previousWeekData[2].filter(e => 
        new Date(e.timestamp) >= oneWeekAgo
      ).length,
      nightFeedings: feedings.filter(e => {
        const hour = new Date(e.timestamp).getHours();
        return hour >= 22 || hour <= 5;
      }).length,
      longestSleep: Math.max(...sleep.map(e => e.duration || 0)),
      consecutiveDays: calculateConsecutiveDays(previousWeekData[0])
    };

    // Milestone Celebrations
    if (stats.longestSleep >= 6 * 3600) {
      victories.push(`🌟 Milestone: Baby's first 6-hour sleep! Sweet dreams achieved! 🌙`);
    }

    if (stats.totalFeedings >= 100) {
      victories.push(`💝 Amazing milestone: 100 feedings of love and nurture! You're incredible!`);
    }

    if (stats.consecutiveDays >= 7) {
      victories.push(`🎉 One week of amazing motherhood completed! You're doing great!`);
    }

    // Growth Journey
    const feedingIncrease = stats.totalFeedings - stats.previousWeekFeedings;
    if (feedingIncrease > 0) {
      victories.push(`💪 Growing stronger: Feedings increased by ${feedingIncrease} this week!`);
    }

    const sleepImprovement = (stats.totalSleep - stats.previousWeekSleep) / 3600;
    if (sleepImprovement > 2) {
      victories.push(`🌟 Sleep victory: ${Math.round(sleepImprovement)} more hours of peaceful rest!`);
    }

    // Personalized Achievements
    if (stats.nightFeedings >= 3) {
      victories.push(`👑 Night Owl Queen: Handled ${stats.nightFeedings} night feedings like a pro!`);
    }

    if (stats.totalDiapers >= 8) {
      victories.push(`✨ Super Mom Achievement: ${stats.totalDiapers} diaper changes with endless patience!`);
    }

    // Emotional Moments
    const totalCareHours = Math.round((stats.totalSleep + (stats.totalFeedings * 20 * 60)) / 3600);
    victories.push(`❤️ Today's Love: ${totalCareHours} hours spent nurturing your little one`);

    const peacefulMoments = stats.totalFeedings + Math.floor(stats.totalSleep / 3600);
    victories.push(`💫 Created ${peacefulMoments} precious moments today`);

    // Special Badges
    if (stats.totalFeedings >= 6 && stats.totalDiapers >= 6 && stats.totalSleep >= 4 * 3600) {
      victories.push(`🏆 Ultimate Mom Badge: Mastered feeding, sleep, and care all in one day!`);
    }

    return victories;
  };

  // Helper function to calculate consecutive days of care
  const calculateConsecutiveDays = (entries: TrackingEntry[]): number => {
    const dates = new Set(
      entries.map(e => new Date(e.timestamp).setHours(0, 0, 0, 0))
    );
    
    let consecutiveDays = 0;
    const today = new Date().setHours(0, 0, 0, 0);
    let currentDate = today;

    while (dates.has(currentDate)) {
      consecutiveDays++;
      currentDate = new Date(currentDate).setDate(
        new Date(currentDate).getDate() - 1
      );
    }

    return consecutiveDays;
  };

  const renderVictoryCard = (victory: VictoryEntry) => {
    const date = new Date(victory.timestamp);
    const isToday = date.setHours(0,0,0,0) === new Date().setHours(0,0,0,0);

    return (
      <VictoryCard 
        key={victory.id}
        victory={victory}
        isToday={isToday}
        setToast={setToast}
      />
    );
  };

  const generateMilestones = (): Milestone[] => {
    const milestones: Milestone[] = [];

    // Now totalStats is in scope
    for (const milestone of MILESTONES.feeding) {
      if (totalStats.feedings >= milestone.threshold) {
        milestones.push({
          id: `feeding-${milestone.threshold}`,
          type: 'feeding',
          title: milestone.title,
          description: getMilestoneDescription('feeding', milestone.threshold),
          value: milestone.threshold,
          achievedAt: Date.now(),
          icon: milestone.icon,
          category: milestone.category as 'bronze' | 'silver' | 'gold' | 'platinum'
        });
      }
    }

    // Add sleep milestones
    const totalSleepHours = Math.floor(totalStats.sleep / 3600);
    for (const milestone of MILESTONES.sleep) {
      if (totalSleepHours >= milestone.threshold) {
        milestones.push({
          id: `sleep-${milestone.threshold}`,
          type: 'sleep',
          title: milestone.title,
          description: getMilestoneDescription('sleep', milestone.threshold),
          value: milestone.threshold,
          achievedAt: Date.now(),
          icon: milestone.icon,
          category: milestone.category as 'bronze' | 'silver' | 'gold' | 'platinum'
        });
      }
    }

    // Add diaper milestones
    for (const milestone of MILESTONES.diaper) {
      if (totalStats.diapers >= milestone.threshold) {
        milestones.push({
          id: `diaper-${milestone.threshold}`,
          type: 'diaper',
          title: milestone.title,
          description: getMilestoneDescription('diaper', milestone.threshold),
          value: milestone.threshold,
          achievedAt: Date.now(),
          icon: milestone.icon,
          category: milestone.category as 'bronze' | 'silver' | 'gold' | 'platinum'
        });
      }
    }

    // Add streak milestone
    for (const milestone of MILESTONES.streak) {
      if (totalStats.days >= milestone.threshold) {
        milestones.push({
          id: `streak-${milestone.threshold}`,
          type: 'streak',
          title: milestone.title,
          description: getMilestoneDescription('streak', milestone.threshold),
          value: milestone.threshold,
          achievedAt: Date.now(),
          icon: milestone.icon,
          category: milestone.category as 'bronze' | 'silver' | 'gold' | 'platinum'
        });
      }
    }

    return milestones;
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="text-accent-lavender animate-pulse">Loading your victories...</div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Header with Info Card */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="space-y-4"
      >
        <div className="bg-gradient-to-br from-accent-gold/20 to-accent-rose/20 rounded-xl p-6 border border-accent-gold/20">
          <h2 className="text-2xl font-bold text-white flex items-center gap-2">
            <StarIcon className="h-6 w-6 text-accent-gold" />
            Victory Journal
          </h2>
          <p className="text-gray-400 mt-2">Your beautiful journey of motherhood</p>
        </div>

        {/* Info Card */}
        <motion.div 
          className="bg-dark-card/50 rounded-xl p-4 border border-gray-800"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className="flex items-start gap-3">
            <InformationCircleIcon className="h-5 w-5 text-accent-gold flex-shrink-0 mt-1" />
            <div className="space-y-2">
              <h3 className="text-sm font-medium text-white">How Victories Work</h3>
              <div className="text-sm text-gray-400 space-y-1">
                <p>• <span className="text-accent-gold">Daily Victories:</span> Track your daily achievements in feeding, sleep, and care</p>
                <p>• <span className="text-accent-lavender">Weekly Progress:</span> See how you're improving week over week</p>
                <p>• <span className="text-accent-mint">Milestones:</span> Unlock special achievements as you continue your journey</p>
                <div className="mt-4 p-3 bg-accent-gold/5 rounded-lg border border-accent-gold/10">
                  <p className="text-sm text-gray-300">
                    Remember: Every moment you spend caring for your little one is a victory, whether it's tracked or not. 
                    Your love and dedication make you an amazing mom, and these achievements are just a small way to celebrate 
                    your beautiful journey of motherhood. 💝
                  </p>
                </div>
                <p className="text-xs text-gray-500 mt-2">All victories are automatically calculated from your tracking data - just keep tracking and watch your achievements grow! 🌟</p>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>

      {/* Today's Victories */}
      {groupedVictories.today[0] && (
        <div className="space-y-4">
          {groupedVictories.today.map(victory => renderVictoryCard(victory))}
        </div>
      )}

      {/* Weekly Progress */}
      {weeklyStats && <WeeklyVictories weeklyStats={weeklyStats} />}

      {/* All-Time Achievements */}
      <AllTimeAchievements 
        milestones={generateMilestones()}
        totalStats={totalStats}
      />

      <Toast 
        message={toast?.message || ''} 
        type={toast?.type || 'success'} 
        isVisible={!!toast} 
      />
    </div>
  );
}

const StatBox = ({ icon, value, label, color }: { 
  icon: React.ReactNode; 
  value: number; 
  label: string;
  color: 'rose' | 'lavender' | 'mint';
}) => (
  <motion.div 
    whileHover={{ scale: 1.05 }}
    className={`bg-dark-bg/50 p-4 rounded-lg text-center transition-all duration-300
                hover:bg-accent-${color}/10 group`}
  >
    <div className={`bg-accent-${color}/10 p-2 rounded-full w-12 h-12 mx-auto mb-2 
                     flex items-center justify-center group-hover:scale-110 transition-all duration-300`}>
      <div className={`text-accent-${color}`}>{icon}</div>
    </div>
    <p className={`text-2xl font-bold text-accent-${color}`}>{value}</p>
    <p className="text-xs text-gray-400">{label}</p>
  </motion.div>
);

const formatDate = (date: Date) => {
  return date.toLocaleDateString('en-US', { 
    weekday: 'long',
    month: 'short',
    day: 'numeric'
  });
};

const getRelativeTimeText = (date: Date) => {
  const days = Math.floor((new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
  if (days === 1) return 'Yesterday';
  if (days < 7) return `${days} days ago`;
  return formatDate(date);
};

export default Victories;