import React, { useState, useRef, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon, HeartIcon, BeakerIcon, SparklesIcon, UserIcon, StarIcon, BookOpenIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';

function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowProfileMenu(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const navigation = [
    { 
      name: 'Dashboard', 
      href: '/app',
      icon: HomeIcon,
      activeColor: 'text-accent-peach',
      bgColor: 'bg-accent-peach/10'
    },
    { 
      name: 'Baby', 
      href: '/app/baby',
      icon: HeartIcon,
      activeColor: 'text-accent-rose',
      bgColor: 'bg-accent-rose/10'
    },
    { 
      name: 'Health', 
      href: '/app/health',
      icon: BeakerIcon,
      activeColor: 'text-accent-mint',
      bgColor: 'bg-accent-mint/10'
    },
    { 
      name: 'Wellness', 
      href: '/app/wellness',
      icon: SparklesIcon,
      activeColor: 'text-accent-lavender',
      bgColor: 'bg-accent-lavender/10'
    },
    { 
      name: 'Learning', 
      href: '/app/learning',
      icon: BookOpenIcon,
      activeColor: 'text-accent-mint',
      bgColor: 'bg-accent-mint/10'
    },
    { 
      name: 'Victories', 
      href: '/app/victories',
      icon: StarIcon,
      activeColor: 'text-accent-gold',
      bgColor: 'bg-accent-gold/10'
    }
  ];

  const isActive = (path: string) => {
    if (path === '/app') {
      return location.pathname === '/app';
    }
    return location.pathname.startsWith(path);
  };

  return (
    <div className="min-h-screen flex flex-col bg-dark-bg text-white relative">
      {/* Header - Fixed at top */}
      <motion.header 
        className="fixed top-0 left-0 right-0 bg-dark-card/95 backdrop-blur-xl border-b border-gray-800 z-50"
      >
        <div className="max-w-screen-sm mx-auto px-4 flex items-center justify-between h-16">
          <div className="flex items-center space-x-3">
            <HeartIcon className="h-8 w-8 text-accent-lavender" />
            <motion.h1 
              className="text-xl font-bold bg-gradient-to-r from-accent-lavender via-accent-rose to-accent-mint bg-clip-text text-transparent"
              animate={{ 
                opacity: [0.9, 1, 0.9],
                scale: [1, 1.02, 1]
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              NurturingMom
            </motion.h1>
          </div>

          {/* Profile Menu */}
          <div className="relative" ref={menuRef}>
            <button
              onClick={() => setShowProfileMenu(!showProfileMenu)}
              className="p-2 rounded-lg text-gray-400 hover:text-white transition-colors"
            >
              <UserIcon className="h-6 w-6" />
            </button>

            {/* Dropdown Menu */}
            <AnimatePresence>
              {showProfileMenu && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  className="absolute right-0 mt-2 w-48 bg-dark-card rounded-lg shadow-lg border border-gray-800 overflow-hidden"
                >
                  <Link
                    to="/app/profile"
                    className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-800"
                    onClick={() => setShowProfileMenu(false)}
                  >
                    Edit Profile
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-800"
                  >
                    Sign Out
                  </button>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </motion.header>

      {/* Main Content - Add padding for fixed header and footer */}
      <main className="flex-1 pt-16 pb-20 px-4 max-w-screen-sm mx-auto w-full">
        <Outlet />
      </main>

      {/* Footer Navigation - Fixed at bottom */}
      <motion.nav 
        className="fixed bottom-0 left-0 right-0 bg-dark-card/95 backdrop-blur-xl border-t border-gray-800 z-50"
      >
        <div className="max-w-screen-sm mx-auto px-2">
          <div className="flex justify-between">
            {navigation.map((item) => {
              const active = isActive(item.href);
              return (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`flex flex-col items-center py-3 px-2 min-w-[70px] relative ${
                    active 
                      ? `${item.activeColor}` 
                      : 'text-gray-400 hover:text-white'
                  }`}
                >
                  <motion.div 
                    className={`p-2 rounded-lg transition-all duration-300 ${
                      active ? `${item.bgColor}` : ''
                    }`}
                    animate={active ? {
                      scale: [1, 1.05, 1],
                      opacity: [0.9, 1, 0.9]
                    } : {}}
                    transition={{
                      duration: 4,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                  >
                    <item.icon className="h-6 w-6 transition-all duration-300" />
                  </motion.div>
                  <span className={`text-xs mt-1 font-medium transition-all duration-300 ${
                    active ? 'opacity-100' : 'opacity-70'
                  }`}>
                    {item.name}
                  </span>
                  {active && (
                    <motion.div 
                      className="absolute -top-0.5 left-1/2 transform -translate-x-1/2 w-8 h-0.5 rounded-full bg-current"
                      animate={{
                        opacity: [0.7, 1, 0.7],
                        width: ["24px", "28px", "24px"]
                      }}
                      transition={{
                        duration: 4,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    />
                  )}
                </Link>
              );
            })}
          </div>
        </div>
      </motion.nav>
    </div>
  );
}

export default Layout; 