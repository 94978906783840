import React, { useState, useEffect } from 'react';
import { firebaseStorage } from '../../../services/firebaseStorage';
import { Article, ArticleCategory } from '../../../types/learning';
import { Dialog } from '@headlessui/react';
import { XMarkIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Toast } from '../../common/Toast';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../services/firebase';
import { auth } from '../../../services/firebase';

export function ArticleManagement() {
  const [articles, setArticles] = useState<Article[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
  const [editingArticle, setEditingArticle] = useState<Article | null>(null);

  // Form states
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [category, setCategory] = useState<ArticleCategory>('Sleep');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState('');

  useEffect(() => {
    const user = auth.currentUser;
    if (!user || user.email !== 'taiwojolaade@gmail.com') {
      setToast({ message: 'Admin access required', type: 'error' });
      return;
    }
  }, []);

  useEffect(() => {
    loadArticles();
  }, []);

  const loadArticles = async () => {
    try {
      const data = await firebaseStorage.getArticles();
      setArticles(data);
    } catch (error) {
      console.error('Error loading articles:', error);
      setToast({ message: 'Failed to load articles', type: 'error' });
    }
  };

  const validateImage = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        // Recommended dimensions for article thumbnails
        const RECOMMENDED_WIDTH = 800;  // pixels
        const RECOMMENDED_HEIGHT = 450; // pixels
        const ASPECT_RATIO = 16/9;      // 16:9 aspect ratio
        
        // Allow some flexibility (±20px)
        const isValidWidth = Math.abs(img.width - RECOMMENDED_WIDTH) <= 20;
        const isValidHeight = Math.abs(img.height - RECOMMENDED_HEIGHT) <= 20;
        const isValidRatio = Math.abs((img.width / img.height) - ASPECT_RATIO) < 0.1;

        if (!isValidWidth || !isValidHeight || !isValidRatio) {
          setToast({ 
            message: `Please use an image close to ${RECOMMENDED_WIDTH}x${RECOMMENDED_HEIGHT}px (16:9 ratio). Current dimensions: ${img.width}x${img.height}px`, 
            type: 'error' 
          });
          resolve(false);
        }
        resolve(true);
      };
    });
  };

  const handleImageSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file size (max 2MB)
    const MAX_SIZE = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > MAX_SIZE) {
      setToast({ 
        message: 'Image size should be less than 2MB', 
        type: 'error' 
      });
      return;
    }

    // Validate dimensions
    const isValid = await validateImage(file);
    if (!isValid) return;
    
    setImageFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleEdit = (article: Article) => {
    setEditingArticle(article);
    setTitle(article.title);
    setDescription(article.blurb);
    setLink(article.link);
    setCategory(article.category);
    setImagePreview(article.imageUrl);
    setShowForm(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      let imageUrl = editingArticle?.imageUrl || '';

      // Only upload new image if one was selected
      if (imageFile) {
        const imagePath = `articles/${Date.now()}_${imageFile.name.replace(/[^a-zA-Z0-9.-]/g, '_')}`;
        const imageRef = ref(storage, imagePath);
        await uploadBytes(imageRef, imageFile);
        imageUrl = await getDownloadURL(imageRef);
      }

      if (editingArticle) {
        // Update existing article
        await firebaseStorage.updateArticle({
          ...editingArticle,
          title,
          blurb: description,
          link,
          category,
          imageUrl,
        });
        setToast({ message: 'Article updated successfully!', type: 'success' });
      } else {
        // Create new article
        await firebaseStorage.addArticle({
          title,
          blurb: description,
          link,
          category,
          image: imageFile!,
          imageUrl
        });
        setToast({ message: 'Article added successfully!', type: 'success' });
      }

      resetForm();
      await loadArticles();
    } catch (error) {
      console.error('Error saving article:', error);
      setToast({ message: 'Failed to save article', type: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setLink('');
    setCategory('Sleep');
    setImageFile(null);
    setImagePreview('');
    setShowForm(false);
    setEditingArticle(null);
  };

  const handleDelete = async (article: Article) => {
    if (window.confirm('Are you sure you want to delete this article?')) {
      try {
        await firebaseStorage.deleteArticle(article.id, article.imageUrl);
        setToast({ message: 'Article deleted successfully', type: 'success' });
        await loadArticles();
      } catch (error) {
        console.error('Error deleting article:', error);
        setToast({ message: 'Failed to delete article', type: 'error' });
      }
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-white">Article Management</h2>
        <button
          onClick={() => setShowForm(true)}
          className="bg-accent-lavender/20 text-accent-lavender px-4 py-2 rounded-lg
                   hover:bg-accent-lavender/30 transition-colors"
        >
          Add New Article
        </button>
      </div>

      {/* Article List */}
      <div className="grid gap-4">
        {articles.map(article => (
          <div key={article.id} className="bg-dark-card p-4 rounded-lg border border-gray-800">
            <div className="flex items-start space-x-4">
              <img
                src={article.imageUrl}
                alt={article.title}
                className="w-24 h-24 object-cover rounded-lg"
              />
              <div className="flex-1">
                <h3 className="text-white font-medium">{article.title}</h3>
                <p className="text-sm text-gray-400 mt-1">{article.blurb}</p>
                <div className="mt-2 flex items-center space-x-2">
                  <span className="text-xs px-2 py-1 rounded-full bg-accent-mint/20 text-accent-mint">
                    {article.category}
                  </span>
                  <a
                    href={article.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-xs text-accent-lavender hover:underline"
                  >
                    View Article
                  </a>
                </div>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleEdit(article)}
                  className="p-2 text-gray-400 hover:text-accent-mint rounded-lg hover:bg-accent-mint/10"
                >
                  <PencilIcon className="h-5 w-5" />
                </button>
                <button
                  onClick={() => handleDelete(article)}
                  className="p-2 text-gray-400 hover:text-accent-rose rounded-lg hover:bg-accent-rose/10"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Form Dialog */}
      <Dialog
        open={showForm}
        onClose={() => !isSubmitting && resetForm()}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="bg-dark-card rounded-xl p-6 max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <Dialog.Title className="text-lg font-medium text-white">
                {editingArticle ? 'Edit Article' : 'Add New Article'}
              </Dialog.Title>
              <button
                onClick={() => !isSubmitting && resetForm()}
                className="text-gray-400 hover:text-white"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm text-gray-400 mb-1">Title</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full p-2 bg-dark-bg text-white border border-gray-800 rounded-lg"
                  required
                />
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-1">Description</label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full p-2 bg-dark-bg text-white border border-gray-800 rounded-lg"
                  rows={3}
                  required
                />
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-1">Article Link</label>
                <input
                  type="url"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  className="w-full p-2 bg-dark-bg text-white border border-gray-800 rounded-lg"
                  required
                />
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-1">Category</label>
                <select
                  value={category}
                  onChange={(e) => setCategory(e.target.value as ArticleCategory)}
                  className="w-full p-2 bg-dark-bg text-white border border-gray-800 rounded-lg"
                >
                  <option value="Sleep">Sleep</option>
                  <option value="Nutrition">Nutrition</option>
                  <option value="Development">Development</option>
                  <option value="Health">Health</option>
                  <option value="Parenting">Parenting</option>
                </select>
              </div>

              <div>
                <label className="block text-sm text-gray-400 mb-1">
                  Thumbnail Image (800x450px, 16:9 ratio)
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageSelect}
                  className="w-full text-sm text-gray-400"
                  required={!editingArticle}
                />
                {imagePreview && (
                  <div className="mt-2">
                    <img
                      src={imagePreview}
                      alt="Thumbnail preview"
                      className="w-full h-auto rounded-lg border border-gray-800"
                    />
                    <p className="text-xs text-gray-500 mt-1">
                      Make sure the image looks clear and properly cropped
                    </p>
                  </div>
                )}
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-accent-lavender/20 text-accent-lavender p-3 rounded-lg
                         border border-accent-lavender/30 hover:bg-accent-lavender/30
                         transition-colors disabled:opacity-50"
              >
                {isSubmitting ? 'Saving...' : editingArticle ? 'Update Article' : 'Add Article'}
              </button>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>

      <Toast 
        message={toast?.message || ''} 
        type={toast?.type || 'success'} 
        isVisible={!!toast} 
      />
    </div>
  );
} 