import React, { createContext, useContext, useState, useRef } from 'react';

interface TimerContextType {
  isRunning: boolean;
  elapsed: number;
  activeTimer: { type: string; startTime: number } | null;
  startTimer: (type: string) => void;
  pauseTimer: () => void;
  stopTimer: () => void;
}

const TimerContext = createContext<TimerContextType | undefined>(undefined);

export function TimerProvider({ children }: { children: React.ReactNode }) {
  const [isRunning, setIsRunning] = useState(false);
  const [elapsed, setElapsed] = useState(0);
  const [activeTimer, setActiveTimer] = useState<{ type: string; startTime: number } | null>(null);
  const intervalRef = useRef<NodeJS.Timeout>();
  const startTimeRef = useRef<number>(0);
  const pausedTimeRef = useRef<number>(0);

  const startTimer = (type: string) => {
    const now = Date.now();
    setIsRunning(true);
    startTimeRef.current = now;
    setActiveTimer({ type, startTime: now });

    // Clear any existing interval
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // Start new interval
    intervalRef.current = setInterval(() => {
      const currentElapsed = Date.now() - startTimeRef.current + pausedTimeRef.current;
      setElapsed(currentElapsed);
    }, 1000);
  };

  const pauseTimer = () => {
    setIsRunning(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      pausedTimeRef.current = elapsed;
    }
  };

  const stopTimer = () => {
    setIsRunning(false);
    setElapsed(0);
    setActiveTimer(null);
    pausedTimeRef.current = 0;
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  return (
    <TimerContext.Provider value={{
      isRunning,
      elapsed,
      activeTimer,
      startTimer,
      pauseTimer,
      stopTimer
    }}>
      {children}
    </TimerContext.Provider>
  );
}

export function useTimer() {
  const context = useContext(TimerContext);
  if (context === undefined) {
    throw new Error('useTimer must be used within a TimerProvider');
  }
  return context;
} 