import React, { useState, useEffect } from 'react';
import { CalendarIcon, HeartIcon, BeakerIcon, PlusIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { storage } from '../services/storage';
import { TabTransition } from './wellness/TabTransition';
import { Toast } from './common/Toast';
import { haptics } from '../utils/haptics';
import { HealthForm } from './health/HealthForm';
import { HealthEntriesSection } from './health/HealthEntriesSection';
import { HealthEntry, HealthType } from '../types/health';
import { UpcomingVaccinations } from './health/UpcomingVaccinations';
import { ConfirmDialog } from './common/ConfirmDialog';
import { TabButton } from './common/TabButton/TabButton';

function HealthTracking() {
  const [activeTab, setActiveTab] = useState<HealthType>('concerns');
  const [entries, setEntries] = useState<HealthEntry[]>([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
  const [selectedEntry, setSelectedEntry] = useState<HealthEntry | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [entryToDelete, setEntryToDelete] = useState<HealthEntry | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadEntries = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const freshData = await storage.getHealthEntries(activeTab);
      setEntries(freshData);
    } catch (error) {
      console.error('Error loading entries:', error);
      setToast({ message: 'Failed to load entries', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [activeTab]);

  useEffect(() => {
    loadEntries();
  }, [loadEntries]);

  const handleAddEntry = () => {
    setSelectedEntry(null);
    setIsFormOpen(true);
  };

  const handleFormClose = (success = false) => {
    setIsFormOpen(false);
    if (success) {
      haptics.success();
      setToast({ message: 'Entry saved successfully! 🎉', type: 'success' });
      loadEntries();
      setTimeout(() => setToast(null), 3000);
    }
  };

  const handleDelete = async () => {
    if (!entryToDelete) return;
    
    try {
      await storage.deleteHealthEntry(entryToDelete);
      await loadEntries();
      haptics.success();
      setToast({ message: 'Entry deleted successfully', type: 'success' });
    } catch (error) {
      console.error('Error deleting entry:', error);
      setToast({ message: 'Failed to delete entry', type: 'error' });
    } finally {
      setTimeout(() => setToast(null), 3000);
      setShowDeleteConfirm(false);
      setEntryToDelete(null);
    }
  };

  const handleEntryUpdate = async (updatedEntry: HealthEntry) => {
    try {
      await storage.updateHealthEntry(updatedEntry);
      await loadEntries();
      haptics.success();
      setToast({ message: 'Entry updated successfully! 🎉', type: 'success' });
    } catch (error) {
      console.error('Error updating entry:', error);
      if ((error as Error).message === 'User not authenticated') {
        setToast({ message: 'Please sign in to update entries', type: 'error' });
      } else {
        setToast({ message: 'Failed to update entry', type: 'error' });
      }
    } finally {
      setTimeout(() => setToast(null), 3000);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-pulse text-accent-lavender">Loading...</div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Header */}
      <div className="bg-dark-card rounded-lg p-6 border border-gray-800">
        <h2 className="text-2xl font-bold text-accent-lavender">Baby Health</h2>
        <p className="text-gray-400 mt-2">Monitor your baby's health and medical records</p>
      </div>

      {/* Quick Action Buttons */}
      <div className="grid grid-cols-3 gap-3">
        <TabButton
          icon={<HeartIcon className="h-6 w-6" />}
          label="Health Concerns"
          isActive={activeTab === 'concerns'}
          onClick={() => setActiveTab('concerns')}
          iconColor="text-accent-rose"
        />
        <TabButton
          icon={<CalendarIcon className="h-6 w-6" />}
          label="Appointments"
          isActive={activeTab === 'appointments'}
          onClick={() => setActiveTab('appointments')}
          iconColor="text-accent-mint"
        />
        <TabButton
          icon={<BeakerIcon className="h-6 w-6" />}
          label="Vaccinations"
          isActive={activeTab === 'vaccinations'}
          onClick={() => setActiveTab('vaccinations')}
          iconColor="text-accent-peach"
        />
      </div>

      {/* Content Section */}
      <TabTransition tab={activeTab}>
        <div className="space-y-4">
          {/* Add New Entry Button */}
          <motion.button 
            onClick={handleAddEntry}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="w-full bg-accent-lavender/20 text-accent-lavender p-4 rounded-lg border border-accent-lavender/30 flex items-center justify-center space-x-2 hover:bg-accent-lavender/30 transition-colors"
          >
            <PlusIcon className="h-5 w-5" />
            <span>Add New {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}</span>
          </motion.button>

          {/* Entries Section */}
          <HealthEntriesSection
            entries={entries}
            activeTab={activeTab}
            onEdit={(entry) => {
              setSelectedEntry(entry);
              setIsFormOpen(true);
            }}
            onDelete={(entry) => {
              setEntryToDelete(entry);
              setShowDeleteConfirm(true);
            }}
            onEntriesUpdate={handleEntryUpdate}
            setToast={setToast}
          />

          {activeTab === 'vaccinations' && (
            <UpcomingVaccinations entries={entries.filter(e => e.type === 'vaccinations')} />
          )}
        </div>
      </TabTransition>

      {/* Health Form Dialog */}
      <HealthForm 
        isOpen={isFormOpen}
        onClose={handleFormClose}
        type={activeTab}
        entry={selectedEntry}
        setToast={setToast}
        loadEntries={loadEntries}
      />
      <Toast 
        message={toast?.message || ''} 
        type={toast?.type || 'success'} 
        isVisible={!!toast} 
      />

      {/* Add ConfirmDialog */}
      <ConfirmDialog
        isOpen={showDeleteConfirm}
        onClose={() => {
          setShowDeleteConfirm(false);
          setEntryToDelete(null);
        }}
        onConfirm={handleDelete}
        title="Delete Entry"
        message="Are you sure you want to delete this entry? This action cannot be undone."
      />
    </div>
  );
}

export default HealthTracking;
